export const general = {
    languages: {
        en: 'English',
        he: 'עברית',
        ar: 'العربية',
    },
    mobileLandscapeViewMessage: {
        en: 'Please turn your device vertically.',
        he: 'אנא סובבו את המכשיר אנכית.',
        ar: 'يرجى تدوير جهازك عموديًا.',
    },
    platformLimitationMessage: {
        desktop_only: {
            en: 'This survey is only available on large screens.',
            he: 'השאלון זמין רק עבור מסכים גדולים.',
            ar: 'الرجاء فتح على جهاز الكمبيوتر.',
        },
        mobile_only: {
            en: 'This survey is only available on small screens.',
            he: 'השאלון זמין רק עבור מסכים קטנים.',
            ar: 'من فضلك افتح على هاتفك.',
        },
    },
    previewModeWarning: {
        message: {
            en: "Preview mode. your answers won't be saved",
            he: 'תצוגה מקדימה. תשובותיכם לא ישמרו',
            ar: 'معاينة. لن يتم حفظ إجاباتك.',
        },
        gotIt: { en: 'Got it', he: 'הבנתי', ar: 'فهمتك' },
    },
    notFoundMessage: {
        en: 'Survey not found.',
        he: 'השאלון לא נמצא.',
        ar: 'لم يتم العثور على الاستطلاع.',
    },
    surveyExpired: {
        en: 'This survey has expired.',
        he: 'פג תוקף השאלון.',
        ar: 'انتهت صلاحية هذا الاستطلاع.',
    },
};

export const pageTexts = {
    continueBtn: { en: 'Next', he: 'הבא', ar: 'التالي' },
    confirmBtn: { en: 'Confirm', he: 'אישור', ar: 'تؤكد' },
    numberPage: {
        caption: {
            tip: {
                he: 'הקלידו מספר',
                en: 'Type a number',
                ar: 'اكتب رقمًا',
            },
            minCharactersWarning: {
                he: 'לפחות {c} ספרות',
                en: 'At least {c} digits',
                ar: '{c} أرقام على الأقل',
            },
            slider: {
                mobile: {
                    he: 'לחצו על הסרגל',
                    en: 'Swipe over the bar',
                    ar: 'انقر فوق شريط التمرير',
                },
                desktop: {
                    he: 'לחצו על הסרגל או היעזרו בחצי המקלדת',
                    en: 'Click the bar or use the keyboard arrows',
                    ar: 'انقر فوق شريط التمرير',
                },
            },
        },
    },
    openEndedPage: {
        caption: {
            tip: {
                he: 'הקלידו טקסט',
                en: 'Type text',
                ar: 'أكتب نصا',
            },
            emailTip: {
                he: 'הזינו כתובת אימייל',
                en: 'Enter email address',
                ar: 'أدخل عنوان البريد الالكتروني',
            },
            minCharactersWarning: {
                he: 'לפחות {c} תווים',
                en: 'At least {c} characters',
                ar: '{c} أحرف على الأقل',
            },
        },
    },
};

export const componentsTexts = {
    searchableSelect: {
        select: { en: 'Select', he: 'בחירה', ar: 'تحديد' },
    },
    searchBar: {
        placeholder: { en: 'Search', he: 'חיפוש', ar: 'بحث' },
    },
    media: {
        clickToExpand: {
            en: 'Click to expand',
            he: 'לחצו להגדלה',
            ar: 'اضغط للتكبير',
        },
    },
};

export const accessibilityTexts = {
    accessibility: { en: 'Accessibility options', he: 'הגדרות נגישות', ar: 'خيارات الدخول' },
    textSize: {
        en: 'Text size',
        he: 'גודל טקסט',
        ar: 'حجم الخط',
    },
    increaseTextSize: {
        en: 'Increase text size',
        he: 'הגדלת גודל הטקסט',
        ar: 'زيادة حجم النص',
    },
    decreaseTextSize: {
        en: 'Decrease text size',
        he: 'הקטנת גודל הטקסט',
        ar: 'تقليل حجم النص',
    },
    close: { en: 'Close', he: 'סגירה', ar: 'أغلق' },
    language: { en: 'Language', he: 'שפה', ar: 'لغة' },
    animations: { en: 'Animations', he: 'הנפשה', ar: 'الرسوم المتحركة' },
    on: { en: 'On', he: 'פועל', ar: 'على' },
    off: { en: 'Off', he: 'כבוי', ar: 'قبالة' },
    darkMode: { en: 'Dark mode', he: 'מצב לילה', ar: 'وضع الليل' },
    progressbar: {
        description: { en: 'Survey progress', he: 'מד התקדמות שאלון', ar: 'شريط تقدم المسح' },
    },
};
