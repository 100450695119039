import React, { useEffect, useMemo, useRef } from 'react';
import styles from './PageTitleAnnouncer.module.css';
import { selectPageData, surveyModes } from 'features/survey/surveySlice';
import { getRawText } from 'components/RichTextRenderer';
import { useSelector } from 'react-redux';

/**
 * Invisible page title for accessibility
 */
export default function PageTitleAnnouncer() {
    const mode = useSelector((state) => state.survey.runtimeConfig.mode);
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);
    const data = useSelector((state) => selectPageData(state, currentPage));
    const lang = useSelector((state) => state.user.lang);
    const titleRef = useRef();

    const titleText = useMemo(() => getRawText(data?.title[lang]), [data, lang]);

    useEffect(() => {
        document.title = titleText;
        if (titleRef.current && mode !== surveyModes.EDITING) {
            titleRef.current.focus();
        }
    }, [currentPage, titleText, mode]);

    return (
        <h1 ref={titleRef} className={styles.hiddenTitle} tabIndex={-1}>
            {titleText}
        </h1>
    );
}
