import { useEffect, useState } from 'react';

export const layoutModes = {
    MOBILE: "mobile",
    MOBILE_LANDSCAPE: "mobile_landscape",
    TABLET_PORTRAIT: "tablet_portrait",
    DESKTOP: "desktop",
    DESKTOP_LARGE: "desktop_large",
}

export const useLayout = () => {

    const [width, setWidth] = useState( window.innerWidth );
    const [height, setHeight] = useState( window.innerHeight );
    const [mode, setMode] = useState( layoutModes.DESKTOP );

    useEffect( () => {
        
        const handleWindowResize = () => {
            
            const w = window.innerWidth;
            const h = window.innerHeight;

            setWidth( w );
            setHeight( h );

        }

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);

    }, [])

    useEffect( () => {
        let m = layoutModes.DESKTOP;

        if( width <= 425 ) m = layoutModes.MOBILE;
        else if( width > height && height <= 425 ) m = layoutModes.MOBILE_LANDSCAPE;
        else if( height > width && width > 425 && width <= 1366 ) m = layoutModes.TABLET_PORTRAIT;
        else if( width > height && width > 1024 && width <= 1920 ) m = layoutModes.DESKTOP;
        else if( width > 1920 ) m = layoutModes.DESKTOP_LARGE;

        setMode( m );

    }, [width, height])

    return { width, height, mode }
}