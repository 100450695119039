import { Player } from '@lottiefiles/react-lottie-player';
import { useSelector } from 'react-redux';
import { general } from 'utils/appTexts';
import notFound from 'assets/animations/not-found';
import night from 'assets/animations/night';

export default function NotFound() {
    const lang = useSelector((state) => state.user.lang);
    const fetchMilgoRefErrorMessage = useSelector((state) => state.survey.milgoRefData?.message);

    let msg = general.notFoundMessage[lang];
    let animation = notFound;

    if (fetchMilgoRefErrorMessage === 'answers limit reached' || fetchMilgoRefErrorMessage === 'outdated') {
        msg = general.surveyExpired[lang];
        animation = night;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 100 }}>
            <Player style={{ width: 300 }} src={animation} autoplay={true} loop={true} />
            <h3 style={{ color: 'var(--color-mono-4)' }}>{msg}</h3>
        </div>
    );
}
