export function mergeRefs(
    refs // : Array<React.MutableRefObject<T> | React.LegacyRef<T>>
) {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
}
