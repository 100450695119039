import React from 'react';
import styles from './MobileView.module.css';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { general } from 'utils/appTexts';

export default function MobileLandscapeView() {

    const lang = useSelector( state => state.user.lang );

    return (
        <div className={ styles.landscapeView}>
            <Icon name='turn_device_vertical'/>
            <p style={{marginInlineStart: 8}}> {general.mobileLandscapeViewMessage[ lang ] } </p>
        </div>
    )
}