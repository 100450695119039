import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './MobilePageTopPanel.module.css';
import GradientPanel from 'components/GradientPanel';
import ProgressBar from 'components/ProgressBar';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { easing } from 'utils/animationEasing';
import { pageLayoutModes, pageTypes } from 'features/survey/surveyConfiguration';
import { getNested } from 'utils/miscHelpers';
import Text from 'components/Text';
import { selectPageData } from 'features/survey/surveySlice';
import { getRawText } from 'components/RichTextRenderer';

const contentTransitions = {
    opacity: [1, 0, 0, 1],
    y: [0, -10, 30, 0],
};

const contentTransitionsTimings = {
    duration: 1,
    times: [0, 0.2, 0.4, 1],
    ease: [easing.inOutQuart, 'linear', easing.outQuart],
};

const panelContVarients = {
    collapsed: { y: -72 },
    visible: {
        y: 0,
        transition: { duration: 0.2, delay: 0.1 },
    },
};

export default function MobilePageTopPanel({ id }) {
    const panelRef = useRef();
    const lang = useSelector((state) => state.user.lang);
    const theme = useSelector((state) => state.survey.display.theme);
    const data = useSelector((state) => selectPageData(state, id));
    const hideProgressbar = useSelector((state) => {
        return state.survey.data?.settings.general.hideProgressbar;
    });
    const [currentData, setCurrentData] = useState(data);

    const showSubtitle = useMemo(() => {
        return getNested(currentData, 'settings', 'general', 'showSubtitle');
    }, [currentData]);

    const collapsed = useMemo(() => {
        if (currentData) {
            const mode = pageTypes[currentData.type].mode;
            return mode === pageLayoutModes.FULL_VIEW;
        }
    }, [currentData]);

    useEffect(() => {
        if (data) {
            const changeDataTimeout = setTimeout(() => {
                setCurrentData(data);
            }, 200);

            return () => clearTimeout(changeDataTimeout);
        }
    }, [data]);

    if (!currentData) return null;

    return (
        <motion.header
            ref={panelRef}
            className={styles.panel}
            variants={panelContVarients}
            initial="collapsed"
            animate={collapsed ? 'collapsed' : 'visible'}
            transition={{ duration: 0.5, ease: easing.inOutQuart }}
            layout
        >
            {theme === 'light' && <GradientPanel className={styles.gradienPanel} />}

            {!collapsed && (
                <motion.div
                    key={id}
                    className={styles.container}
                    animate={contentTransitions}
                    transition={contentTransitionsTimings}
                    tabIndex={-1}
                >
                    <legend style={{ display: 'none' }}> {getRawText(currentData.title[lang])}</legend>{' '}
                    {/*for accessibility*/}
                    <Text tagType="p" className={styles.title}>
                        {currentData.title[lang]}
                    </Text>
                    {showSubtitle && currentData.subtitle && (
                        <Text tagType="p" className={styles.subtitle}>
                            {currentData.subtitle[lang]}
                        </Text>
                    )}
                </motion.div>
            )}

            {!hideProgressbar && (
                <ProgressBar
                    className={styles.progressCont}
                    style={{
                        opacity: collapsed ? 0 : 1,
                    }}
                />
            )}
        </motion.header>
    );
}
