import { languageChanged } from 'features/user/userSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStorage } from 'utils/storageManager';
import { displayChanged, surveyModes } from './surveySlice';
import { useLayout, layoutModes } from 'utils/useLayout';
import { getRawText } from 'components/RichTextRenderer';

export default function UISetup() {
    const dispatch = useDispatch();

    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    const readDebug = useSelector((state) => state.survey.runtimeConfig.readDebug);
    const milgoRefData = useSelector((state) => state.survey.milgoRefData);
    const urlParams = useSelector((state) => state.record.urlParams);
    const surveyData = useSelector((state) => state.survey.data);
    const lang = useSelector((state) => state.user.lang);

    const layout = useLayout();

    // Set Language:
    useEffect(() => {
        function setLang(targetLang) {
            dispatch(languageChanged(targetLang));
            document.documentElement.lang = targetLang;
        }

        if (milgoRefData?.language) {
            setLang(milgoRefData.language);
            return;
        }

        if (surveyData) {
            if ((surveyMode === surveyModes.PREVIEW && readDebug) || surveyMode === surveyModes.EDITING) {
                // Survey is in preview mode:
                const previewLang = surveyData.debug.previewLanguage;

                if (previewLang) {
                    setLang(surveyData.debug.previewLanguage);
                    return;
                }
            }

            const surveyLangs = surveyData.settings.general.supportedLanguages;
            const urlLang = urlParams.lang;
            const userLang = LocalStorage.Read(LocalStorage.keys.USER_LANGUAGE);

            // Does the url language included in current survey?
            if (urlLang && surveyLangs.includes(urlLang)) {
                setLang(urlLang);
                return;
            }

            // Url lang is undefined.
            // Has the user choosen a prefered language before?
            if (userLang) {
                // Is the prefered language included in current survey?
                if (surveyLangs.includes(userLang)) {
                    setLang(userLang);
                    return;
                }
            }

            // If none of the above is true, set the first language of the survey as the current one:
            setLang(surveyLangs[0]);
            return;
        }
    }, [surveyMode, milgoRefData?.language, surveyData, readDebug, urlParams, dispatch]);

    // Set document direction:
    useEffect(() => {
        const dir = ['ar', 'he'].includes(lang) ? 'rtl' : 'ltr';
        dispatch(displayChanged({ property: 'direction', value: dir }));
    }, [lang, dispatch]);

    // Set responsive layout:
    useEffect(() => {
        const _layout = { ...layout };
        _layout.platform = [layoutModes.MOBILE, layoutModes.MOBILE_LANDSCAPE, layoutModes.TABLET_PORTRAIT].includes(
            layout.mode
        )
            ? 'mobile'
            : 'desktop';

        dispatch(displayChanged({ property: 'layout', value: _layout }));
    }, [layout, dispatch]);

    // Set theme:
    useEffect(() => {
        const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        dispatch(displayChanged({ property: 'theme', value: theme }));
        document.documentElement.setAttribute('data-theme', theme);
    }, [dispatch]);

    // Set survey color palette:
    useEffect(() => {
        if (surveyData) {
            const value = surveyData.settings.general.color;
            const presetColorsRegex = new RegExp(/^\b[0-9]{3}\b$/);
            const customColorsRegex = new RegExp(/#[0-9a-fA-F]{3,6}/);

            if (presetColorsRegex.test(value)) {
                dispatch(displayChanged({ property: 'palette', value }));
            }

            if (
                Array.isArray(value) &&
                value.length === 2 &&
                customColorsRegex.test(value[0]) &&
                customColorsRegex.test(value[1])
            ) {
                const colors = value;
                const root = document.documentElement;
                root.style.setProperty('--color-primary-a', colors[0]);
                root.style.setProperty('--color-primary-b', colors[1]);
                dispatch(displayChanged({ property: 'palette', value: '' }));
            }
        }
    }, [surveyData, dispatch]);

    // Set html title
    useEffect(() => {
        if (surveyData && surveyData.surveyName) {
            document.title = getRawText(surveyData.surveyName[lang]);
        }
    }, [surveyData, lang]);

    //@TODO:
    // load user prefrences

    // does the survey support the current platform ?  desktop / mobile

    return null;
}
