import React from 'react';
import styles from './Caption.module.css';
import Icon from './Icon';
import { motion } from 'framer-motion';

const captionStates = {
    tip: { icon: 'caption', color: 'var( --color-mono-4 )' },
    click: { icon: 'pointer', color: 'var( --color-mono-4 )' },
    warning: { icon: 'warning', color: 'var( --color-mono-4 )' },
    error: { icon: 'x', color: 'var( --color-mono-5 )' },
    success: { icon: 'check', color: 'var( --color-mono-5 )' },
};

/**
 * Caption component
 * @param {{
 * state {string} // 'tip' | 'warning' | 'error' | 'success' | "click"
 * text {string}
 * className {string}
 * style {object}
 * }} params
 */
export default function Caption({ state = 'tip', text, className, style }) {
    return (
        <motion.div
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 20,
                delay: 1,
            }}
            className={`${styles.captionCont} ${className}`}
            style={style}
        >
            <Icon name={captionStates[state].icon} size={'1rem'} color={captionStates[state].color} />
            <span
                className={styles.caption}
                style={{
                    color: captionStates[state].color,
                }}
            >
                {text}
            </span>
        </motion.div>
    );
}
