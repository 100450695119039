import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useSelector } from 'react-redux';
import FocusTrap from 'focus-trap-react';

const defaultMotionVarients = {
    hidden: {
        opacity: 0,
        y: 100,
        scale: 0.95,
        transition: {
            default: { duration: 0.3, ease: easing.inOutQuart }
        }
    },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
            opacity: { duration: 0.2 },
            default: { duration: 0.6, ease: easing.outQuart }
        }
    }
};

/**
 * Text area component (multiline)
 * @param {{
 * isActive
 * onClose
 * motionVarients // { hidden: {}, visible: {} }
 * }} params
 */
export default function Modal({
    isActive,
    onClose,
    className,
    style,
    motionVarients = defaultMotionVarients,
    children
}) {
    const direction = useSelector(state => state.survey.display.direction);
    const platform = useSelector(state => state.survey.display.layout.platform);
    const theme = useSelector(state => state.survey.display.theme);

    const modalRef = useRef();

    const handleClick = useCallback(
        event => {
            // Only if respond if clicked directrly:
            if (event.target === modalRef.current) {
                if (onClose) onClose();
            }
        },
        [onClose, modalRef]
    );

    return ReactDOM.createPortal(
        <AnimatePresence initial={false}>
            {isActive && (
                <FocusTrap>
                    <motion.div
                        ref={modalRef}
                        role="dialog"
                        aria-modal="true"
                        className={`${styles.basicModal} ${className}`}
                        style={style}
                        dir={direction}
                        data-platform={platform}
                        data-theme={theme}
                        variants={motionVarients}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        onClick={handleClick}
                    >
                        {children}
                    </motion.div>
                </FocusTrap>
            )}
        </AnimatePresence>,
        document.getElementById('root')
    );
}
