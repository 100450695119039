import useAnalytics, { analyticEvents } from 'features/record/useAnalytics';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPageData } from './surveySlice';

export default function AppActivity() {
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);
    const pageData = useSelector((state) => selectPageData(state, currentPage));

    const sendAnalyticsEvent = useAnalytics();

    useEffect(() => {
        function handleBlur(e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('APP BLUR');
            }
            sendAnalyticsEvent({ eventKey: analyticEvents.APP_LOST_FOCUS, params: { pageKey: pageData?.key } });
        }

        function handleFocus(e) {
            if (process.env.NODE_ENV === 'development') {
                console.log('APP FOCUSED');
            }
        }

        window.addEventListener('blur', handleBlur);
        window.addEventListener('focus', handleFocus);

        return () => {
            window.removeEventListener('blur', handleBlur);
            window.removeEventListener('focus', handleFocus);
        };
    }, [sendAnalyticsEvent, pageData]);

    return null;
}
