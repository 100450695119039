import React from 'react';
import styles from './GradientPanel.module.css';
import { useSelector } from 'react-redux';
/**
 * 
 * @param {{
 * style // inline style
 * className // class name
 * customPalette // igonre survey settings and apply a unique palette
 * }} params 
 * @returns div container with animated gradient background
 */
export default function GradientPanel({style, className, customPalette, children}) {

    const palette = useSelector( state => state.survey.display.palette );

    return (
        <div 
            style={ style } 
            className={ `${className} ${styles.panel}` }
            data-palette={ customPalette || palette }
        >
            {children}
        </div>
    )
}