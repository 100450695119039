import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEscapeKey } from 'utils/keyboardHooks';
import styles from './AccessibiltyOptions.module.css';
import IconButton from './IconButton';
import Modal from './Modal';
import { accessibilityTexts } from 'utils/appTexts';
import { useDispatch, useSelector } from 'react-redux';
import Icon from './Icon';
import { displayChanged } from 'features/survey/surveySlice';
import useAnalytics, { analyticEvents } from 'features/record/useAnalytics';
import { debounce } from 'debounce';
const texts = accessibilityTexts;

export default function AccessibiltyOptions() {
    const [isPanelActive, setPanelActive] = useState(false);
    const lang = useSelector((state) => state.user.lang);

    useEscapeKey(() => setPanelActive(false));

    return (
        <>
            <IconButton
                name="accessibility"
                theme="strong"
                className={styles.activationBtn}
                onClick={() => setPanelActive(true)}
                aria-haspopup={'dialog'}
            />
            <Modal
                isActive={isPanelActive}
                style={{
                    backgroundColor: 'rgba(0,0,0,.5)',
                }}
                motionVarients={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                onClose={() => setPanelActive(false)}
            >
                <div className={styles.panel}>
                    <div className={styles.innerCont}>
                        <IconButton name="x" onClick={() => setPanelActive(false)} ariaLabel={texts.close[lang]} />
                        <TextSize lang={lang} />
                        <DarkMode lang={lang} />
                        {/* <Animations lang={lang} /> defined by system */}
                    </div>
                </div>
            </Modal>
        </>
    );
}

const sizeStep = 0.1;
const minTextSize = 0.5;
const maxTextSize = 2;

const TextSize = ({ lang }) => {
    const sendAnalytics = useAnalytics();

    const [size, setSize] = useState(() => {
        const current = parseFloat(document.documentElement.style.fontSize) / 100;
        if (!isNaN(current)) return current;
        else return 1;
    });

    const formattedSize = useMemo(() => {
        return (size * 100).toFixed(0) + '%';
    }, [size]);

    const debouncedAnalytics = useMemo(
        () =>
            debounce((s) => {
                sendAnalytics({
                    eventKey: analyticEvents.USED_ACCESSIBILITY,
                    params: {
                        textSize: s,
                    },
                });
            }, 3000),
        [sendAnalytics]
    );

    const handleSizeBtn = useCallback(
        (d) => {
            const s = Math.min(2, Math.max(0.5, size + d));
            setSize(s);
            debouncedAnalytics(s);
        },
        [size, debouncedAnalytics]
    );

    useEffect(() => {
        document.documentElement.style.fontSize = formattedSize;
    }, [formattedSize]);

    return (
        <div className={styles.optionItemCont}>
            <label className={styles.optionLabel} htmlFor={'text-size-input'}>
                {texts.textSize[lang]}
            </label>
            <div
                id={'text-size-input'}
                role="spinbutton"
                aria-valuemin={0}
                aria-valuemax={100}
                aria-valuenow={size * 100}
                className={styles.inputBox}
            >
                <IconButton
                    name="decrease_text_size"
                    theme="mild"
                    ariaLabel={texts.decreaseTextSize[lang]}
                    onClick={(e) => {
                        handleSizeBtn(-sizeStep);
                        //Stay focused after click for repeated use.
                        // setTimeout(() => e.target.focus(), 10);
                    }}
                    disabled={size <= minTextSize}
                />
                <h3 className={styles.formattedSizeLabel}>{formattedSize}</h3>
                <IconButton
                    name="increase_text_size"
                    theme="mild"
                    ariaLabel={texts.increaseTextSize[lang]}
                    onClick={(e) => {
                        handleSizeBtn(sizeStep);
                        //Stay focused after click for repeated use.
                        // setTimeout(() => e.target.focus(), 10);
                    }}
                    disabled={size >= maxTextSize}
                />
            </div>
        </div>
    );
};

const DarkMode = ({ lang }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.survey.display.theme);
    const sendAnalytics = useAnalytics();

    const handleDarkMode = useCallback(() => {
        const toggledTheme = theme === 'dark' ? 'light' : 'dark';
        dispatch(displayChanged({ property: 'theme', value: toggledTheme }));
        document.documentElement.setAttribute('data-theme', toggledTheme);

        sendAnalytics({ eventKey: analyticEvents.USED_ACCESSIBILITY, params: { colorMode: toggledTheme } });
    }, [theme, sendAnalytics, dispatch]);

    return (
        <div className={styles.optionItemCont}>
            <label className={styles.optionLabel} htmlFor={'dark-mode'}>
                {texts.darkMode[lang]}
            </label>
            <SwitchOption
                id={'dark-mode'}
                ariaLabel={texts.darkMode[lang]}
                icon="dark_mode"
                lang={lang}
                isChecked={theme === 'dark'}
                onClick={handleDarkMode}
            />
        </div>
    );
};

const Animations = ({ lang }) => {
    const [animationsEnabled, setAnimations] = useState(true);
    return (
        <div className={styles.optionItemCont}>
            todo
            <label className={styles.optionLabel} htmlFor={'animations'}>
                {texts.animations[lang]}
            </label>
            <SwitchOption
                id={'animations'}
                ariaLabel={texts.animations[lang]}
                icon="animation"
                lang={lang}
                isChecked={animationsEnabled}
                onClick={() => setAnimations(!animationsEnabled)}
            />
        </div>
    );
};

const SwitchOption = ({ id, ariaLabel, icon, isChecked, onClick, lang }) => {
    return (
        <button
            id={id}
            aria-label={ariaLabel}
            role="switch"
            aria-checked={isChecked}
            className={styles.switchOption}
            onClick={onClick}
        >
            <Icon name={icon} color={`var(--color-mono-${isChecked ? '5' : '3'})`} />
            <span aria-hidden="true" className={styles.switchLabel} data-state={isChecked ? 'on' : 'off'}>
                {isChecked ? texts.on[lang] : texts.off[lang]}
            </span>
        </button>
    );
};
