import React, { useCallback, useMemo } from 'react';
import styles from './ScalePageModule.module.css';
import ScaleValueButton from 'components/ScaleValueButton';
import { useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import Text from 'components/Text';
import { useNumberKeys, useRoveFocus } from 'utils/keyboardHooks';
import { getRawText } from 'components/RichTextRenderer';

const textsTransition = {
    hidden: { opacity: 0, scale: 0.9, y: 24 },
    show: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: 1,
            ease: easing.outQuart,
        },
    },
};

export default function ScaleItem({
    index,
    previewItem,
    data,
    itemsOrder,
    onSelect,
    direction: motionDirection,
    disabled,
    secondaryButton,
}) {
    const lang = useSelector((state) => state.user.lang);
    const answers = useSelector((state) => state.record.userAnswers);

    const itemId = useMemo(() => itemsOrder[index], [itemsOrder, index]);
    const itemData = useMemo(() => {
        return data.itemsData[previewItem ? previewItem : itemId];
    }, [data, itemId, previewItem]);
    const scaleData = useMemo(() => data.scaleData, [data]);

    const btnRefs = useMemo(() => scaleData.map(() => React.createRef()), [scaleData]);
    useRoveFocus(btnRefs, 'horizontal');
    useNumberKeys((n) => {
        if (n <= btnRefs.length) {
            btnRefs[n - 1].current?.focus();
            setTimeout(() => {
                handleSelection(scaleData[n - 1]?.key, n - 1);
            }, 100);
        }
    });

    const selected = useMemo(() => {
        if (!data || !itemData) return false;
        return getNested(answers, data.key, 'value', itemData.key, 'key');
    }, [data, itemData, answers]);

    const handleSelection = useCallback(
        (valueKey, valueIndex) => {
            onSelect({
                itemKey: itemData.key,
                valueKey,
                valueIndex,
            });
        },
        [onSelect, itemData]
    );

    if (!itemData) {
        if (itemsOrder && itemsOrder.length > 0) {
            console.warn('trying to render a scale item out of range. Index:', index);
        }
        return null;
    }

    return (
        <>
            <motion.div initial="hidden" animate="show" variants={textsTransition}>
                <Text tagType="legend" className={styles.itemText}>
                    {itemData.text[lang]}
                </Text>
            </motion.div>
            <div
                className={styles.valueButtonsCont}
                role="radio"
                aria-checked={selected}
                aria-label={getRawText(itemData.text[lang])}
            >
                {scaleData.map((scaleValue, i) => (
                    <motion.div
                        key={scaleValue.key}
                        className={styles.valueButtonWrapper}
                        initial={{ y: motionDirection * 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            duration: 1,
                            ease: easing.outQuart,
                            delay: Math.pow(i - (scaleData.length - 1) * 0.5, 2) * 0.03,
                        }}
                    >
                        <ScaleValueButton
                            id={scaleValue.key}
                            ref={btnRefs[i]}
                            text={scaleValue.text[lang]}
                            isSelected={selected === scaleValue.key}
                            onSelect={() => handleSelection(scaleValue.key, i)}
                            disabled={disabled}
                        />
                    </motion.div>
                ))}
            </div>
            {secondaryButton && secondaryButton.isVisible && (
                <motion.div
                    className={styles.secondaryButtonsCont}
                    initial={{ y: motionDirection * 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, ease: easing.outQuart, delay: scaleData.length * 0.03 + 0.05 }}
                >
                    <ScaleValueButton
                        text={secondaryButton.text[lang]}
                        className={styles.secondaryButton}
                        isSelected={selected === secondaryButton.key}
                        onSelect={() => handleSelection(secondaryButton.key, -1)}
                        disabled={disabled}
                    />
                </motion.div>
            )}
        </>
    );
}
