// @TEMP:
// import dummySurvey from '../dummy_data/dummy_survey.json';

/////////////////////////////////
//// SURVEY//////////////////////
/////////////////////////////////

/* FETCH SURVEY JSON:
By source name:
https://answers.kayma.com/get_survey_json?sourceName=source_name
By project id:
https://answers.kayma.com/get_survey_json?projectId=5ff6240cf0263170c1d00134
By survey id:
https://answers.kayma.com/get_survey_json?surveyId=5fbd02b1f5ab4a001e173fbe
*/

export const FetchSurvey = async ( surveyId ) => {

    const surveyUrl = `https://surveys.hrzn.app/${surveyId}/survey.json`;
    const alternativeSurveyUrl = `https://survey-data.kayma.com/${surveyId}/survey.json`; // Legacy CDN, remove when all surveys are ready on the new one.

    const response = { error: null, data: null };
  
    await fetch( surveyUrl, {cache: 'no-store'} )
    .then( response => response.json() )
    .then( json => {
      response.data = json;
    })
    .catch( err => {
      response.error = err;
    })

    // Try alternative address:
    if( !response.data || response.error ) {
      await fetch( alternativeSurveyUrl, {cache: 'no-store'} )
      .then( response => response.json() )
      .then( json => {
        response.data = json;
      })
      .catch( err => {
        response.error = err;
      })

      return response;

    } else {

      return response;

    }
  

}

export const FetchSurveyPreview = async ( surveyId ) => {
  
  const surveyUrl = `https://answers.kayma.com/get_survey_json?surveyId=${surveyId}`;

  const response = { error: null, data: null };

  await fetch( surveyUrl, {cache: 'no-store'} )
  .then( response => response.json() )
  .then( json => {
    response.data = json;
  })
  .catch( err => {
    response.error = err;
  })

  return response;
}

export const ReportError = async (msg, level) => {
  // TODO: change address according to env..
  const url = `https://answers.kayma.com/report_error`;

  const response = { error: null, data: null };

  await fetch(url, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ source: "Survey Client", msg, level })
  })
  .then(response => response.json())
  .then(json => {
    response.data = json;
  })
  .catch(err => {
    response.error = err;
  })
}

/**
 * We use OpenRecord when the user starts a new survey and we want to start recording his answers.
 * @param {string} clientKey 
 * @param {string} sourceName 
 * @returns respons { error, recordId }
 */
export const OpenRecord = async (clientKey, sourceName) => {

  // TODO: change address according to env..
  const url = `https://incoming.hrzn.app/open`;

  const response = { error: null, recordId: null };

  await fetch(url, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ clientKey, sourceName })
  })
  .then(response => response.json())
  .then(json => {
    response.error = json.err;   
    response.recordId = json.recordId 
  })
  .catch(err => {
    response.error = err;
  })

  return response
}

/**
 * We use AddToRecord when the user submits a new answer or when we want to save any other data remotely.
 * @param {string} clientKey 
 * @param {string} recordId 
 * @param {object} data 
 * @returns response { error }
 */
export const AddToRecord = async (clientKey, recordId, data) => {
  // TODO: change address according to env..
  const url = `https://incoming.hrzn.app/add`;

  const response = { error: null };

  await fetch(url, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ clientKey, recordId, data })
  })
  .then(response => response.json())
  .then(json => {
    response.error = json.err;   
  })
  .catch(err => {
    response.error = err;
  })

  // Test latency:
  // await new Promise( r => setTimeout( r, 10000 ) );

  return response
}

/**
 * We use CloseRecord when the user completes a survey and we no longer need to add information to the record.
 * @param {string} clientKey 
 * @param {string} recordId 
 * @returns response { error }
 */
export const CloseRecord = async (clientKey, recordId) => {
  // TODO: change address according to env..
  const url = `https://incoming.hrzn.app/close`;

  const response = { error: null };

  await fetch(url, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ clientKey, recordId })
  })
  .then(response => response.json())
  .then(json => {
    response.error = json.err;   
  })
  .catch(err => {
    response.error = err;
  })

  return response
}

/**
 * We use GetRecord when we want to read user answers' from a previous session that hasn't closed yet, or when we want to debug the submission of data via AddToRecord
 * @param {string} clientKey 
 * @param {string} recordId 
 * @returns response { error, record };
 */
export const GetRecord = async (clientKey, recordId) => {
  // TODO: change address according to env..
  const url = `https://incoming.hrzn.app/record?recordId=${recordId}&clientKey=${clientKey}`;

  const response = { error: null, record: null };

  await fetch(url)
  .then(response => response.json())
  .then(json => {
    response.error = json.err; 
    response.record = json.record;   
  })
  .catch(err => {
    response.error = err;
  })

  return response
}
