import React, { useMemo, useState } from 'react';
import styles from './shared/Button.module.css';
import Button, { themes } from './shared/Button';
import Icon from './Icon';

/**
 *
 * @param {{
 * label
 * ariaLabel
 * iconBefore
 * iconAfter
 * theme
 * className
 * style
 * disabled {boolean}
 * showActivity
 * onClick
 * onPointerDown
 * onPointerUp
 * tabIndex
 * }} params
 */
const TextButton = React.forwardRef(
    (
        {
            label,
            ariaLabel,
            iconBefore,
            iconAfter,
            theme = 'mild',
            className,
            style,
            disabled,
            showActivity,
            onClick,
            onPointerDown,
            onPointerUp,
            tabIndex,
        },
        ref
    ) => {
        const [buttonState, setButtonState] = useState('normal'); // 'normal' | 'hovered' | 'focused' | 'active'

        const overlayColor = useMemo(() => {
            return themes[theme][buttonState].overlay;
        }, [theme, buttonState]);

        return (
            <Button
                ref={ref}
                name={label}
                ariaLabel={ariaLabel || label}
                className={`${className} ${styles.textButton}`}
                theme={theme}
                style={style}
                disabled={disabled}
                tabIndex={tabIndex}
                onClick={onClick}
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                onStateChange={(s) => setButtonState(s)}
            >
                {iconBefore && (
                    <Icon
                        name={iconBefore}
                        className={styles.iconBefore}
                        size={'1.25rem'}
                        style={{
                            marginInlineStart: -6,
                            marginInlineEnd: 6,
                        }}
                        color={overlayColor}
                    />
                )}

                {label}

                {iconAfter && (
                    <Icon
                        name={iconAfter}
                        className={styles.iconAfter}
                        size={'1.25rem'}
                        style={{
                            marginInlineStart: 6,
                            marginInlineEnd: -6,
                        }}
                        color={overlayColor}
                    />
                )}
            </Button>
        );
    }
);

export default TextButton;
