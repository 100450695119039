import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'utils/mergeRefs';
import styles from './Button.module.css';

export const themes = {
    plain: {
        normal: {
            background: 'transparent',
            border: 'transparent',
            overlay: 'var( --color-mono-5 )',
        },
        hovered: {
            background: 'var( --color-mono-3 )',
            border: 'transparent',
            overlay: 'var( --color-mono-5 )',
        },
        focused: {
            background: 'var( --color-mono-2 )',
            border: 'var( --color-mono-4 )',
            overlay: 'var( --color-mono-5 )',
        },
        active: {
            background: 'var( --color-mono-4 )',
            border: 'var( --color-mono-4 )',
            overlay: 'var( --color-mono-0 )',
        },
        disabled: {
            background: 'transparent',
            border: 'transparent',
            overlay: 'var( --color-mono-3 )',
        },
    },
    mild: {
        normal: {
            background: 'var( --color-mono-3 )',
            border: 'transparent',
            overlay: 'var( --color-mono-5 )',
        },
        hovered: {
            background: 'var( --color-mono-4 )',
            border: 'transparent',
            overlay: 'var( --color-mono-5 )',
        },
        focused: {
            background: 'var( --color-mono-3 )',
            border: 'var( --color-mono-4 )',
            overlay: 'var( --color-mono-5 )',
        },
        active: {
            background: 'var( --color-mono-5 )',
            border: 'var( --color-mono-5 )',
            overlay: 'var( --color-mono-0 )',
        },
        disabled: {
            background: 'var( --color-mono-2 )',
            border: 'transparent',
            overlay: 'var( --color-mono-4 )',
        },
    },
    strong: {
        normal: {
            background: 'var( --color-mono-5 )',
            border: 'transparent',
            overlay: 'var( --color-mono-0 )',
        },
        hovered: {
            background: 'var( --color-mono-4 )',
            border: 'transparent',
            overlay: 'var( --color-mono-0 )',
        },
        focused: {
            background: 'var( --color-mono-4 )',
            border: 'var( --color-mono-5 )',
            overlay: 'var( --color-mono-0 )',
        },
        active: {
            background: 'var( --color-mono-3 )',
            border: 'var( --color-mono-3 )',
            overlay: 'var( --color-mono-5 )',
        },
        disabled: {
            background: 'var( --color-mono-4 )',
            border: 'transparent',
            overlay: 'var( --color-mono-3 )',
        },
    },
};

/**
 * @param {{
 * name {string} // used as alt if ariaLabel is not provided
 * ariaLabel {string }
 * role
 * theme {string|object} // 'plain' | 'mild' | 'strong' background brightness; OR pass an object: { normal: { background,border,overlay }, hovered: {}, focused:{}, ...}
 * className
 * style
 * tabIndex
 * disabled
 * propagate // Allow propogation of input events. Set to false by default.
 * onClick
 * onStateChange, // 'normal' | 'hovered' | 'focused' | 'active'
 * onPointerEnter
 * onPointerLeave
 * onPointerDown
 * onPointerUp
 * onKeyPress
 * onBlur
 * onFocus
 * }} params
 */
const Button = React.forwardRef(
    (
        {
            children,
            name,
            ariaLabel,
            role,
            theme = 'mild',
            className,
            style,
            tabIndex,
            disabled = false,
            propagate = false,
            onClick,
            onStateChange,
            onPointerEnter,
            onPointerLeave,
            onPointerDown,
            onPointerUp,
            onKeyPress,
            onBlur,
            onFocus,
        },
        ref
    ) => {
        const btnRef = useRef();
        const [buttonState, setButtonState] = useState('normal'); // 'normal' | 'hovered' | 'focused' | 'active'

        useEffect(() => {
            if (onStateChange) {
                onStateChange(buttonState);
            }
        }, [buttonState, onStateChange]);

        useEffect(() => {
            if (disabled && onStateChange) {
                onStateChange('disabled');
            }
        }, [disabled, onStateChange]);

        ////////////////////
        // EVENT HANDLERS //
        ////////////////////

        const handleClick = useCallback(
            (e) => {
                if (!propagate) e.stopPropagation();
                // setTimeout(() => {
                //     if (btnRef.current) {
                //         btnRef.current.blur();
                //     }
                // }, 10);
                if (!disabled && onClick) onClick(e);
            },
            [propagate, disabled, onClick]
        );

        const handlePointerDown = useCallback(
            (e) => {
                if (!propagate) e.stopPropagation();
                setButtonState('active');
                if (onPointerDown) onPointerDown();
            },
            [propagate, onPointerDown]
        );

        const handlePointerUp = useCallback(
            (e) => {
                if (!propagate) e.stopPropagation();
                setButtonState('normal');
                // btnRef.current.blur();
                if (onPointerUp) onPointerUp();
            },
            [propagate, onPointerUp]
        );

        const handlePointerEnter = useCallback(
            (e) => {
                if (!propagate) e.stopPropagation();
                setButtonState('hovered');
                if (onPointerEnter) onPointerEnter();
            },
            [propagate, onPointerEnter]
        );

        const handlePointerLeave = useCallback(
            (e) => {
                if (!propagate) e.stopPropagation();
                setButtonState('normal');
                if (onPointerLeave) onPointerLeave();
            },
            [propagate, onPointerLeave]
        );

        const handleFocus = useCallback(() => {
            if (buttonState !== 'active' && buttonState !== 'hovered') {
                setButtonState('focused');
                if (onFocus) onFocus();
            }
        }, [onFocus, buttonState]);

        const handleBlur = useCallback(() => {
            setButtonState('normal');
            if (onBlur) onBlur();
        }, [onBlur]);

        const handleKeyPress = useCallback(
            (e) => {
                if (e.key === 'Enter') {
                    setButtonState('active');
                }

                if (onKeyPress) onKeyPress(e);
            },
            [onKeyPress]
        );

        ////////////
        // RENDER //
        ////////////

        const _theme = useMemo(() => {
            if (typeof theme === 'object') {
                return theme;
            }

            if (themes[theme]) {
                return themes[theme];
            }

            return themes.mild;
        }, [theme]);

        const buttonStyle = useMemo(() => {
            const state = disabled ? 'disabled' : buttonState;

            return {
                backgroundColor: _theme[state].background,
                borderColor: _theme[state].border,
                color: _theme[state].overlay,
                cursor: disabled ? 'default' : 'pointer',
                pointerEvents: disabled ? 'none' : 'auto',
                ...style,
            };
        }, [style, _theme, disabled, buttonState]);

        return (
            <button
                ref={mergeRefs([btnRef, ref])}
                type="button"
                alt={name}
                aria-label={ariaLabel || name}
                role={role}
                className={`${className} ${styles.basic}`}
                style={buttonStyle}
                tabIndex={disabled ? '-1' : tabIndex}
                onClick={handleClick}
                onPointerDown={handlePointerDown}
                onPointerUp={handlePointerUp}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }
);

export default Button;
