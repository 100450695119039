import { useCallback, useMemo } from 'react'
import confetti  from 'canvas-confetti';
import { useSelector } from 'react-redux';

const palettes = {
    '000': ['#76B7F4','#BAFEFF'],
    '001': ['#B2FFCA','#ECFFD8'],
    '002': ['#FAB8D5','#FFE1CD'],
    '003': ['#FADF45','#F9FF8C'],
    '004': ['#FAB545','#FFE19F'],
}

export function useConfetti() {
    
    const palette = useSelector( state => state.survey.display.palette );

    const colors = useMemo( () => {

        let cols = ['#000000', '#ffffff'];

        if( palettes[ palette ] ) cols = cols.concat( palettes[ palette ] )
        else cols = cols.concat( palettes[ '000' ]);

        return cols; 

    }, [palette])

    const confettiHandler = useCallback( () => {
        confetti({
            colors,
            shapes: ['circle', 'square'],
            scalar: 0.8,
            origin: {x: 0, y: 1},
            angle: 60,
            particleCount: 200,
            spread: 60, 
            gravity: 0.5,
            decay: 0.93,
            drift: 0.5,
            disableForReducedMotion: true
        });
        confetti({
            colors,
            shapes: ['circle', 'square'],
            scalar: 0.8,
            origin: {x: 1, y: 1},
            angle: 120,
            particleCount: 200,
            spread: 60, 
            gravity: 0.5,
            decay: 0.93,
            drift: -0.5,
            disableForReducedMotion: true
        });

    }, [colors])

    return confettiHandler; 
}