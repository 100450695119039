import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useEscapeKey = (callBack) => {
    useEffect(() => {
        const escHandler = (e) => {
            if (e.key === 'Escape' && callBack) {
                callBack();
            }
        };
        window.addEventListener('keydown', escHandler);

        return () => {
            window.removeEventListener('keydown', escHandler);
        };
    }, [callBack]);
};

export const useArrowKeys = ({ upCB, downCB, leftCB, rightCB }) => {
    useEffect(() => {
        const keysHandler = (e) => {
            if (e.key === 'ArrowUp' && upCB) {
                e.preventDefault();
                upCB();
                return;
            }
            if (e.key === 'ArrowDown' && downCB) {
                e.preventDefault();
                downCB();
                return;
            }
            if (e.key === 'ArrowLeft' && leftCB) {
                e.preventDefault();
                leftCB();
                return;
            }
            if (e.key === 'ArrowRight' && rightCB) {
                e.preventDefault();
                rightCB();
                return;
            }
        };
        window.addEventListener('keydown', keysHandler);

        return () => {
            window.removeEventListener('keydown', keysHandler);
        };
    }, [upCB, downCB, leftCB, rightCB]);
};

export const useNumberKeys = (callback) => {
    useEffect(() => {
        const keysHandler = (e) => {
            const key = parseInt(e.key);
            if (!isNaN(key) && callback) {
                callback(key);
            }
        };
        window.addEventListener('keydown', keysHandler);

        return () => {
            window.removeEventListener('keydown', keysHandler);
        };
    }, [callback]);
};

/**
 * Let user navigate using the arrow keys and focus on next element in the list
 * @param {Array} elementsRefs
 * @param {'vertical' | 'horizontal'} dir
 */
export const useRoveFocus = (elementsRefs, dir = 'vertical') => {
    const uiDirection = useSelector((state) => state.survey.display.direction);

    const [currentFocus, setCurrentFocus] = useState(-1);

    const setFocusByArrowKeys = useCallback(
        (delta) => {
            if (elementsRefs && elementsRefs.length > 0) {
                let nextFocus = (currentFocus + delta) % elementsRefs.length;
                if (nextFocus < 0) nextFocus = elementsRefs.length - 1;
                elementsRefs[nextFocus].current?.focus();
                setCurrentFocus(nextFocus);
            }
        },
        [elementsRefs, currentFocus]
    );

    useArrowKeys({
        downCB: dir === 'vertical' ? () => setFocusByArrowKeys(1) : null,
        upCB: dir === 'vertical' ? () => setFocusByArrowKeys(-1) : null,
        leftCB: dir === 'horizontal' ? () => setFocusByArrowKeys(uiDirection === 'ltr' ? -1 : 1) : null,
        rightCB: dir === 'horizontal' ? () => setFocusByArrowKeys(uiDirection === 'ltr' ? 1 : -1) : null,
    });
};
