import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './ExternalPageModule.module.css';
import mobileFooterStyles from 'features/survey/views/mobile/MobilePageFooter.module.css';
import TextButton from 'components/TextButton';
import DesktopContinueButton from 'features/survey/views/desktop/DesktopContinueButton';
import ActivityIndicator from 'components/ActivityIndicator';
import ExternalEventsHandler, { eventsTypes } from 'utils/ExternalEventsHandler';
import { useDispatch, useSelector } from 'react-redux';
import { pageTexts } from 'utils/appTexts';
import { pageStateChanged } from 'features/survey/surveySlice';
import { getRawText } from 'components/RichTextRenderer';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useOnPageDone } from 'features/survey/page/Page';
import { getNested } from 'utils/miscHelpers';
import { addDataForSubmission, setUserAnswer } from 'features/record/recordSlice';
import useUrlWithVars from 'utils/useUrlWithVars';

export default function ExternalPageModule({ id, data, lang }) {
    const dispatch = useDispatch();
    const platform = useSelector((state) => state.survey.display.layout.platform);
    const pageTitle = useMemo(() => getRawText(data.title[lang]), [data, lang]);
    const answers = useSelector((state) => state.record.userAnswers);

    const getUrlWithVars = useUrlWithVars();

    const answerValue = useMemo(() => {
        const current = answers[data.key];
        if (current && current.value) {
            return current.value;
        }
        return {};
    }, [data, answers]);

    const Content = useMemo(() => {
        switch (platform) {
            case 'mobile':
                return Mobile;
            case 'desktop':
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    const { showContinueButton, allowCamera, allowMic } = useMemo(() => {
        if (data) {
            return data.settings.general;
        }
        return {};
    }, [data]);

    const [continueBtnVisible, setContinueBtnVisible] = useState(true);

    // Set initial visibility of continue button by page settings:
    useEffect(() => setContinueBtnVisible(showContinueButton), [showContinueButton]);

    // Valid by default:
    useEffect(() => {
        dispatch(pageStateChanged({ property: 'isCurrentPageValid', value: true }));
    }, [dispatch]);

    const handleEvents = useCallback(
        (eventData) => {
            switch (eventData.type) {
                case eventsTypes.SET_CONTINUE_BUTTON:
                    // Update continue button visibility by external event:
                    const isVisible = getNested(eventData, 'payload', 'isVisible');
                    setContinueBtnVisible(isVisible);
                    break;

                case eventsTypes.SET_USER_ANSWER:
                    try {
                        const { key, value } = eventData.payload;
                        dispatch(
                            setUserAnswer({
                                key: data.key, // page key
                                ansData: {
                                    value: {
                                        ...answerValue, // existing key & values
                                        [key]: value, // new external input key and value
                                    },
                                },
                            })
                        );
                        dispatch(
                            addDataForSubmission({
                                inputType: 'userAnswers',
                                key: data.key,
                            })
                        );
                    } catch (error) {
                        console.warn('Could not set user answer from ExternalPage. Details: ' + error);
                    }
                    break;
                default:
                    break;
            }
        },
        [data, answerValue, dispatch]
    );

    return (
        <>
            <ExternalEventsHandler onEvent={handleEvents} />
            <Content
                kay={id}
                lang={lang}
                url={getUrlWithVars(data.url)}
                continueBtnVisible={continueBtnVisible}
                allowCamera={allowCamera}
                allowMic={allowMic}
                pageTitle={pageTitle}
            />
        </>
    );
}

const Mobile = ({ url, lang, continueBtnVisible, pageTitle, allowCamera, allowMic }) => {
    const dispatch = useDispatch();
    const [siteLoaded, setSiteLoaded] = useState(false);

    useEffect(() => {
        // Hide default mobile footer (we will use a custom version instead)
        // delay is needed because of racing condition with MobileView reset effect.
        setTimeout(() => {
            dispatch(pageStateChanged({ property: 'isFooterHidden', value: true }));
        }, 1);
    }, [dispatch]);

    return (
        <div className={styles.mainCont}>
            {!siteLoaded && (
                <div className={styles.loaderCont} aria-busy="true">
                    <ActivityIndicator size={32} />
                </div>
            )}
            <iframe
                title={pageTitle}
                className={styles.iframe}
                src={url}
                allowFullScreen={true}
                allow={`${allowCamera ? 'camera' : ''};${allowMic ? 'microphone' : ''}`}
                onLoad={() => setSiteLoaded(true)}
            />
            <CustomMobileFooter lang={lang} continueBtnVisible={continueBtnVisible} />
        </div>
    );
};

const CustomMobileFooter = ({ lang, continueBtnVisible: isVisible }) => {
    const direction = useSelector((state) => state.survey.display.direction);
    const { continueButtonCentered, continueButtonText } = useSelector((state) => {
        const currentPage = state.survey.navigation.currentPage;
        const pageData = state.survey.data?.content.pagesData[currentPage];
        return pageData?.settings.general || {};
    });

    const hideContinueBtnVarients = useMemo(
        () => ({
            hidden: { y: 80, opacity: 0 },
            visible: { y: 0, opacity: 1 },
        }),
        []
    );

    const onDone = useOnPageDone();

    return (
        <div
            className={mobileFooterStyles.footer}
            style={{
                background: 'none',
                pointerEvents: isVisible ? 'all' : 'none',
            }}
        >
            <motion.div
                initial="hidden"
                animate={isVisible ? 'visible' : 'hidden'}
                exit={'hidden'}
                transition={
                    isVisible
                        ? { duration: 0.6, ease: easing.outQuart, delay: 0.5 }
                        : { duration: 0.4, ease: easing.inOutQuart }
                }
                variants={hideContinueBtnVarients}
                className={mobileFooterStyles.continueBtnWrapper}
            >
                <TextButton
                    label={
                        continueButtonText && continueButtonText[lang]
                            ? continueButtonText[lang]
                            : pageTexts.continueBtn[lang]
                    }
                    className={mobileFooterStyles.continueBtn}
                    iconAfter={direction === 'rtl' ? 'chevron_left_arrow' : 'chevron_right_arrow'}
                    disabled={!isVisible}
                    onClick={onDone}
                />
            </motion.div>
        </div>
    );
};

const Desktop = ({ url, lang, continueBtnVisible, pageTitle, allowCamera, allowMic }) => {
    const [parent, setParent] = useState();
    const [siteLoaded, setSiteLoaded] = useState(false);
    // Update 'desktop_root' when window changes layout between mobile & desktop:
    useEffect(() => {
        setParent(document.getElementById('desktop_root'));
    }, []);

    if (!parent) return null;

    // Render this module outside of pageModuleCont, so it's absolute position will take the full width of the window:
    return ReactDOM.createPortal(
        <div className={styles.mainCont}>
            {!siteLoaded && (
                <div className={styles.loaderCont}>
                    <ActivityIndicator size={32} />
                </div>
            )}
            <iframe
                title={pageTitle}
                className={styles.iframe}
                src={url}
                allowFullScreen={true}
                allow={`${allowCamera ? 'camera' : ''};${allowMic ? 'microphone' : ''}`}
                onLoad={() => setSiteLoaded(true)}
            />
            {continueBtnVisible && <DesktopContinueButton className={styles.desktopContinueBtnCont} />}
        </div>,
        parent
    );
};
