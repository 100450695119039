import { surveyModes } from 'features/survey/surveySlice';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStorage } from 'utils/storageManager';
import { addDataForSubmission, addToRecord, closeRecord, localRecordRestored, openRecord } from './recordSlice';
import { setCurrentPage } from 'features/survey/surveySlice';

import { pageTypes } from 'features/survey/surveyConfiguration';
import { getNested } from 'utils/miscHelpers';
import useAnalytics, { analyticEvents } from './useAnalytics';

export default function RecordHandling() {
    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);

    if (surveyMode !== surveyModes.LIVE) return null;

    return <RecordHandlers />;
}

function RecordHandlers() {
    const dispatch = useDispatch();

    const clientKey = useSelector((state) => state.user.clientKey);
    const surveyData = useSelector((state) => state.survey.data);
    const env = useSelector((state) => state.survey.runtimeConfig.env);
    const milgoRef = useSelector((state) => state.survey.runtimeConfig.milgoRef);
    const milgoRefData = useSelector((state) => state.survey.milgoRefData);

    const recordId = useSelector((state) => state.record.recordId);
    const recordState = useSelector((state) => state.record.recordState);
    const dataForSubmission = useSelector((state) => state.record.dataForSubmission);
    const addToRecordStatus = useSelector((state) => state.record.addToRecordStatus);
    const closeRecordStatus = useSelector((state) => state.record.closeRecordStatus);
    const urlParams = useSelector((state) => state.record.urlParams);
    const variables = useSelector((state) => state.record.variables);

    const pagesData = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesData'));
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);

    const getStoredData = useReadRecordFromStorage();
    const storeData = useWriteRecordToStorage();
    const clearStoredData = useRemoveRcordFromStorage();

    const sendAnalyticsEvent = useAnalytics();

    // Handle record initiation:
    useEffect(() => {
        if (clientKey && surveyData) {
            if (recordState === 'idle') {
                function openNewRecored() {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Opening a new record. clientKey: ', clientKey);
                    }
                    console.log(surveyData);
                    dispatch(
                        openRecord({
                            milgoRef,
                            clientKey,
                            sourceName: surveyData.sourceName,
                            env,
                        })
                    ).then((result) => {
                        const recordId = result?.payload;
                        if (recordId) {
                            // Notify parent page about the opened record:
                            if (process.env.NODE_ENV === 'development') {
                                console.log('post openedRecord message');
                            }
                        }
                    });
                    // Submit url parameters:
                    Object.keys(urlParams).forEach((key) => {
                        dispatch(
                            addDataForSubmission({
                                inputType: 'urlParams',
                                key,
                            })
                        );
                    });
                }

                const storedData = getStoredData();
                // console.log( storedData );
                if (storedData) {
                    // User has already visited this survey.

                    const fillingLimit = parseInt(surveyData.settings.general.fillingLimit);
                    const timeSpan = Date.now() - storedData.lastEntry;
                    const daysSinceLastEntry = timeSpan / (1000 * 60 * 60 * 24);

                    sendAnalyticsEvent({
                        eventKey: analyticEvents.SURVEY_REVISITED,
                        params: {
                            hoursSinceLastEntry: timeSpan / (1000 * 60 * 60),
                        },
                    });

                    if (storedData.version !== surveyData.version) {
                        // The survey's version has changed since last session.
                        clearStoredData();
                        openNewRecored();
                        dispatch(setCurrentPage(surveyData.content.pagesOrder[0]));
                        return;
                    }

                    if (fillingLimit === -2) {
                        // Reset record
                        clearStoredData();
                        openNewRecored();
                        dispatch(setCurrentPage(surveyData.content.pagesOrder[0]));
                        return;
                    }

                    if (storedData.recordState === 'open') {
                        if (fillingLimit > 0 && daysSinceLastEntry > fillingLimit) {
                            // According to survey settings, too much time has passed since last entry.
                            // Clear storage and open a new record:
                            clearStoredData();
                            openNewRecored();
                            return;
                        }

                        // Restore last session and jump to last page:
                        dispatch(localRecordRestored(storedData));

                        if (storedData.currentPage) {
                            dispatch(setCurrentPage(storedData.currentPage));
                        }
                    }

                    if (storedData.recordState === 'closed') {
                        if (isNaN(fillingLimit) || fillingLimit === 0) {
                            // Fill only once

                            if (storedData.currentPage) {
                                dispatch(localRecordRestored(storedData));
                                dispatch(setCurrentPage(storedData.currentPage));
                                return;
                            }
                        }

                        if (fillingLimit === -1) {
                            // No limit
                            clearStoredData();
                            openNewRecored();
                            return;
                        }

                        if (fillingLimit > 0) {
                            if (daysSinceLastEntry > fillingLimit) {
                                // Allow refill if enough time has passed:
                                clearStoredData();
                                openNewRecored();
                                return;
                            } else if (storedData.currentPage) {
                                // Else jump to last page:
                                dispatch(localRecordRestored(storedData));
                                dispatch(setCurrentPage(storedData.currentPage));
                            }
                        }
                    }
                } else {
                    // New survey. Open record:
                    openNewRecored();
                }
            }
        }
    }, [
        milgoRef,
        clientKey,
        env,
        milgoRefData,
        surveyData,
        recordState,
        urlParams,
        variables,
        getStoredData,
        clearStoredData,
        dispatch,
    ]);

    // Handle data for submission:
    useEffect(() => {
        if (recordId && Object.keys(dataForSubmission).length > 0) {
            // console.log( dataForSubmission )
            if (addToRecordStatus !== 'loading') {
                dispatch(
                    addToRecord({
                        clientKey,
                        recordId,
                        data: dataForSubmission,
                        env,
                    })
                ).then(() => {
                    storeData();
                });
            }
        }
    }, [addToRecordStatus, clientKey, env, recordId, dataForSubmission, storeData, dispatch]);

    // Handle record closure:
    useEffect(() => {
        if (currentPage && pagesData[currentPage].type === pageTypes.ENDING.key) {
            if (
                Object.keys(dataForSubmission).length === 0 &&
                addToRecordStatus === 'succeeded' &&
                closeRecordStatus === 'idle'
            ) {
                if (process.env.NODE_ENV === 'development') {
                    console.log('End of survey. Closing record.');
                }
                dispatch(closeRecord({ clientKey, recordId, env })).then(() => {
                    sendAnalyticsEvent({ eventKey: analyticEvents.SURVEY_ENDED });
                });
            }
        }
    }, [
        pagesData,
        currentPage,
        variables,
        dataForSubmission,
        addToRecordStatus,
        closeRecordStatus,
        clientKey,
        recordId,
        env,
        dispatch,
        sendAnalyticsEvent,
    ]);

    // Final storage:
    useEffect(() => {
        if (recordState === 'closed') {
            storeData();
        }
    }, [recordState, storeData]);

    return null;
}

export function useReadRecordFromStorage() {
    const surveyId = useSelector((state) => state.survey.runtimeConfig.surveyId);
    const milgoRef = useSelector((state) => state.survey.runtimeConfig.milgoRef);

    const read = useCallback(() => {
        const surveys = LocalStorage.Read(LocalStorage.keys.SURVEYS);
        if (surveys) {
            const surveyKey = process.env.REACT_APP_IS_MILGO === 'true' ? milgoRef : surveyId;
            return surveys[surveyKey];
        }

        return null;
    }, [surveyId, milgoRef]);

    return read;
}

export function useWriteRecordToStorage() {
    const surveyId = useSelector((state) => state.survey.runtimeConfig.surveyId);
    const milgoRef = useSelector((state) => state.survey.runtimeConfig.milgoRef);
    const surveyData = useSelector((state) => state.survey.data);
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);

    const urlParams = useSelector((state) => state.record.urlParams);
    const variables = useSelector((state) => state.record.variables);
    const userAnswers = useSelector((state) => state.record.userAnswers);
    const recordId = useSelector((state) => state.record.recordId);
    const recordState = useSelector((state) => state.record.recordState);

    const write = useCallback(() => {
        let newSurveys = {};
        const surveys = LocalStorage.Read(LocalStorage.keys.SURVEYS);

        if (surveys) {
            newSurveys = Object.assign(newSurveys, surveys);
        }

        const version = surveyData ? surveyData.version : null;
        const lastEntry = Date.now();

        const storageKey = process.env.REACT_APP_IS_MILGO === 'true' ? milgoRef : surveyId;

        newSurveys[storageKey] = {
            version,
            urlParams,
            variables,
            userAnswers,
            recordId,
            recordState,
            currentPage,
            lastEntry,
        };

        LocalStorage.Save(LocalStorage.keys.SURVEYS, newSurveys);
    }, [surveyId, milgoRef, surveyData, urlParams, variables, userAnswers, recordId, recordState, currentPage]);

    return write;
}

export function useRemoveRcordFromStorage() {
    const surveyId = useSelector((state) => state.survey.runtimeConfig.surveyId);
    const milgoRef = useSelector((state) => state.survey.runtimeConfig.milgoRef);

    const storageKey = process.env.REACT_APP_IS_MILGO === 'true' ? milgoRef : surveyId;

    const remove = useCallback(() => {
        const surveys = LocalStorage.Read(LocalStorage.keys.SURVEYS);
        const newSurveys = Object.assign({}, surveys);

        delete newSurveys[storageKey];

        LocalStorage.Save(LocalStorage.keys.SURVEYS, newSurveys);
    }, [storageKey]);

    return remove;
}
