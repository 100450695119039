import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './SearchableSelect.module.css';
import Modal from './Modal';
import IconButton from './IconButton';
import TextButton from './TextButton';
import SearchBar from './SearchBar';
import { FixedSizeList } from 'react-window';
import { componentsTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useArrowKeys, useEscapeKey, useRoveFocus } from 'utils/keyboardHooks';

const texts = componentsTexts.searchableSelect;

/**
 * Searchable dropdown selection list
 * @param {{
 * options, [{key: "", label: ""}]
 * value,
 * name,
 * placeholder,
 * isFullscreen, for mobile...
 * searchEnabled = true,
 * onChange
 * disabled
 * }} params
 */
export default function SearchableSelect({
    options,
    value,
    name,
    placeholder,
    isFullscreen,
    searchEnabled = true,
    onChange,
    disabled,
}) {
    const lang = useSelector((state) => state.user.lang);
    const listRef = useRef();
    const [query, setQuery] = useState('');

    const Content = useMemo(() => {
        if (isFullscreen) return FullScreen;
        return Inline;
    }, [isFullscreen]);

    const valueLabel = useMemo(() => {
        const op = options.find((x) => x.key === value);
        if (op) return op.label;
        return '';
    }, [value, options]);

    const filteredOption = useMemo(() => {
        if (!searchEnabled || !query) return options;

        const q = query.toLowerCase();
        const exact = new RegExp(`^${q}.*$`);
        const pattern = q
            .toLowerCase()
            .split('')
            .map((x) => `(?=.*${x === ' ' ? '\\s' : x})`)
            .join('');

        const regex = new RegExp(`${pattern}`, 'g');
        const filtered = options.filter((op) => {
            if (!op.label) return false;
            const str = op.label.toLowerCase();
            return str.match(regex);
        });

        filtered.sort((opA, opB) => {
            const strA = opA.label.toLowerCase();
            const strB = opB.label.toLowerCase();
            const lenA = (strA.match(regex) || []).length;
            const lenB = (strB.match(regex) || []).length;
            const exactLenA = (strA.match(exact) || []).length * 100;
            const exactLenB = (strB.match(exact) || []).length * 100;

            return lenB + exactLenB - (lenA + exactLenA);
        });

        if (listRef.current) {
            // Wait until the list is updated, then:
            setTimeout(() => {
                listRef.current.scrollToItem(0);
            }, 10);
        }

        return filtered;
    }, [searchEnabled, options, query, listRef]);

    return (
        <Content
            lang={lang}
            listRef={listRef}
            valueLabel={valueLabel}
            placeholder={placeholder}
            searchEnabled={searchEnabled}
            query={query}
            filteredOption={filteredOption}
            onQueryChange={(q) => setQuery(q)}
            onChange={onChange}
            disabled={disabled}
        />
    );
}

const FullScreen = ({
    lang,
    listRef,
    valueLabel,
    placeholder,
    searchEnabled,
    query,
    onQueryChange,
    filteredOption,
    onChange,
    disabled,
}) => {
    const direction = useSelector((state) => state.survey.display.direction);
    const [listHeight, setListHeight] = useState(500);
    const [isActive, setIsActive] = useState(false);
    useEscapeKey(() => setIsActive(false));
    useEffect(() => {
        function updateHeight() {
            setListHeight(window.innerHeight - 64);
        }

        updateHeight();

        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const handleSelect = useCallback(
        (op) => {
            onChange(op);
            setIsActive(false);
        },
        [onChange]
    );

    return (
        <>
            <TextButton
                label={valueLabel || placeholder || texts.select[lang]}
                className={styles.openListButton}
                iconAfter="chevron_down_arrow"
                onClick={() => setIsActive(!isActive)}
                disabled={disabled}
            />
            <Modal isActive={isActive} onClose={() => setIsActive(false)} className={styles.background}>
                <div className={styles.topBar}>
                    {searchEnabled && <SearchBar className={styles.searchBar} query={query} onChange={onQueryChange} />}
                    <IconButton
                        name="chevron_down_arrow"
                        style={{ width: 38, height: 38, padding: 6 }}
                        onClick={() => setIsActive(false)}
                    />
                </div>
                <FixedSizeList
                    ref={listRef}
                    height={listHeight}
                    itemCount={filteredOption.length}
                    itemSize={64}
                    direction={direction}
                >
                    {({ index, style }) => {
                        const op = filteredOption[index];
                        return (
                            <div
                                key={op.key}
                                tabIndex={0}
                                className={styles.option}
                                onClick={() => handleSelect(op)}
                                onKeyDown={(e) => {
                                    if (['Enter', ' '].includes(e.key)) handleSelect(op);
                                }}
                                style={style}
                            >
                                {op.label}
                            </div>
                        );
                    }}
                </FixedSizeList>
            </Modal>
        </>
    );
};

const Inline = ({
    lang,
    listRef,
    valueLabel,
    placeholder,
    searchEnabled,
    query,
    onQueryChange,
    filteredOption,
    onChange,
    disabled,
}) => {
    const direction = useSelector((state) => state.survey.display.direction);
    const [listHeight, setListHeight] = useState(500);
    const [isActive, setIsActive] = useState(false);

    const triggerBtnRef = useRef();
    const contRef = useRef();

    // Keyboard controlls:
    // useArrowKeys({
    //     downCB: () => {
    //         if (!isActive) {
    //             if (document.activeElement === triggerBtnRef.current) {
    //                 setIsActive(true);
    //             } else {
    //                 triggerBtnRef.current?.focus();
    //             }
    //         }
    //     },
    // });
    useEscapeKey(() => setIsActive(false));

    // Layout:
    useEffect(() => {
        function updateHeight() {
            let h = 500 - 64; // @TODO:  replace 500 with responsive height '40vh'
            if (contRef.current) {
                h = contRef.current.clientHeight - 64;
            }
            setListHeight(h);
        }

        updateHeight();

        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, [contRef]);

    const handleSelect = useCallback(
        (op) => {
            setIsActive(false);
            setTimeout(() => onChange(op), 200);
        },
        [onChange]
    );

    return (
        <>
            <TextButton
                ref={triggerBtnRef}
                label={valueLabel || placeholder || texts.select[lang]}
                className={styles.openListButton}
                iconAfter="chevron_down_arrow"
                onClick={() => setIsActive(!isActive)}
                disabled={disabled}
            />
            <AnimatePresence>
                {isActive && (
                    <motion.div
                        ref={contRef}
                        className={styles.inlineCont}
                        initial={{ height: 0 }}
                        animate={{ height: 500 }} // @TODO: replace 500 with responsive height '40vh'
                        exit={{ height: 0 }}
                        transition={{ duration: 0.2, ease: easing.inOutQuart }}
                    >
                        <div className={styles.topBar}>
                            {searchEnabled && (
                                <SearchBar className={styles.inlineSearchBar} query={query} onChange={onQueryChange} />
                            )}
                        </div>
                        <FixedSizeList
                            ref={listRef}
                            height={listHeight}
                            itemCount={filteredOption.length}
                            itemSize={64}
                            direction={direction}
                        >
                            {({ index, style }) => {
                                const op = filteredOption[index];
                                return (
                                    <div
                                        key={op.key}
                                        className={styles.option}
                                        onClick={() => handleSelect(op)}
                                        onKeyDown={(e) => {
                                            if (['Enter', ' '].includes(e.key)) handleSelect(op);
                                        }}
                                        style={style}
                                        tabIndex={0}
                                    >
                                        {op.label}
                                    </div>
                                );
                            }}
                        </FixedSizeList>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
