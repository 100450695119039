import { configureStore } from '@reduxjs/toolkit';
import surveyReducer from 'features/survey/surveySlice';
import userSlice from 'features/user/userSlice';
import recordSlice from 'features/record/recordSlice';
import webviewSlice from 'features/webview/webviewSlice';

export default configureStore({
    reducer: {
        survey: surveyReducer,
        user: userSlice,
        record: recordSlice,
        webview: webviewSlice
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false, // disabled due to performance issues during development. this is a test provided by redux toolkit
            immutableCheck: false // disabled due to performance issues during development. this is a test provided by redux toolkit
        })
        
})