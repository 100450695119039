import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import { useConditionSolver } from './LogicHooks';
/**
 *
 * @param {array} list
 * @returns new array with the same items in adifferent order
 */
export function itemsShuffle(list, randomDrawCount) {
    let newList = [];
    if (list) {
        if (list.length === 1) {
            return list;
        }
        const bank = Array.from(list);
        while (bank.length > 0) {
            const i = Math.floor(bank.length * Math.random());
            newList.push(bank[i]);
            bank.splice(i, 1);
        }
    }

    if (randomDrawCount) {
        if (randomDrawCount !== -1) {
            // when randomDrawCount is -1 we will show all items.
            newList = newList.slice(0, randomDrawCount);
        }
    }

    return newList;
}

/**
 *
 * @param {object} data // page data
 * @returns array of items. Randomized if defined in page data settings.
 */
export const usePageItems = (data) => {
    const conditionSolver = useConditionSolver();

    const items = useMemo(() => {
        const randomize = getNested(data, 'settings', 'general', 'randomizeItems');
        const randomDrawCount = getNested(data, 'settings', 'general', 'randomDrawCount');

        if (data && data.itemsOrder) {
            // Handle hidden items:
            const itemsToShow = [];
            data.itemsOrder.forEach((itemId) => {
                const itemData = data.itemsData[itemId];
                let shouldHide = false;

                if (itemData.hidingCondition) {
                    const condition = { ...itemData.hidingCondition };
                    condition.rules = condition.rule; // conditionSolver expects plural: 'rules'
                    shouldHide = conditionSolver(condition);
                }

                if (!shouldHide) {
                    itemsToShow.push(itemId);
                    return;
                }
            });

            if (randomize) {
                const limitAmount = Math.min(itemsToShow.length, randomDrawCount); // Make sure we're not trying to shuffle more items than visible.
                return itemsShuffle(itemsToShow, limitAmount);
            } else {
                return itemsToShow;
            }
        } else return [];
    }, [data, conditionSolver]);

    return items;
};

/**
 * Check if page exit time has already been stored in user answers.
 * @param {object} data // page data
 * @returns true / false.
 */
export const useHasExitTime = (data) => {
    const answers = useSelector((state) => state.record.userAnswers);

    const hasTime = useMemo(() => {
        if (data && data.key) {
            const currentAnswer = answers[data.key];
            if (currentAnswer && currentAnswer.exitTime) {
                return true;
            }
        }

        return false;
    }, [data, answers]);

    return hasTime;
};

/**
 * Check if page enter time has already been stored in user answers.
 * @param {object} data // page data
 * @returns true / false.
 */
export const useHasEnterTime = (data) => {
    const answers = useSelector((state) => state.record.userAnswers);

    const hasTime = useMemo(() => {
        if (data && data.key) {
            const currentAnswer = answers[data.key];
            if (currentAnswer && currentAnswer.enterTime) {
                return true;
            }
        }

        return false;
    }, [data, answers]);

    return hasTime;
};
