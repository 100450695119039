import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './MultipleChoicePageModule.module.css';
import SingleChoiceItem from 'components/SingleChoiceItem';
import MultipleChoiceItem from 'components/MultipleChoiceItem';
import PopupTextInput from 'components/PopupTextInput';
import DesktopContinueButton from 'features/survey/views/desktop/DesktopContinueButton';
import Media from 'components/media/Media';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useDispatch, useSelector } from 'react-redux';
import { pageStateChanged } from 'features/survey/surveySlice';
import { getNested } from 'utils/miscHelpers';
import { useHasExitTime, usePageItems } from 'utils/PageHelpersHooks';
import { useOnPageDone } from '../Page';
import { OPEN_ITEM } from 'features/survey/surveyConfiguration';
import { useConditionSolver } from 'utils/LogicHooks';
import { useRoveFocus } from 'utils/keyboardHooks';

export default function MultipleChoicePageModule({ data, lang, onSetAnswer }) {
    const dispatch = useDispatch();
    const answers = useSelector((state) => state.record.userAnswers);
    const platform = useSelector((state) => state.survey.display.layout.platform);
    const randomizeItems = useMemo(() => getNested(data, 'settings', 'general', 'randomizeItems'), [data]);

    const onDone = useOnPageDone();

    const Content = useMemo(() => {
        switch (platform) {
            case 'mobile':
                return Mobile;
            case 'desktop':
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    const {
        minChoices,
        choicesLimit,
        autoContinue,
        allowRepeatedInput,
        showOpenItem,
        centeredAnswers, // @TODO: Remove after Pakadim experiment ends.
        // randomizeItems // solved in 'useShuffledItems'
    } = useMemo(() => {
        if (data) {
            return data.settings.general;
        }

        return {};
    }, [data]);

    const isSingleChoice = choicesLimit === 1;
    const unlimited = choicesLimit === -1;

    const currentAnswers = answers[data.key];
    const selected = useMemo(() => currentAnswers?.value ?? [], [currentAnswers]);

    const randomizationOrder = currentAnswers?.randomizationOrder;
    const openItemUserValue = currentAnswers?.openItemUserValue;

    const shuffledItems = usePageItems(data);
    const hasExitTime = useHasExitTime(data);
    const openItemData = showOpenItem ? data.itemsData[OPEN_ITEM] : null;
    const isLocked = !allowRepeatedInput && hasExitTime;

    // If page has already been submitted, don't shuffe, return items as answered:
    const itemsOrder = randomizeItems && randomizationOrder ? randomizationOrder : shuffledItems;
    const ChoiceItem = isSingleChoice ? SingleChoiceItem : MultipleChoiceItem;

    // Store randomized items order:
    useEffect(() => {
        if (!randomizationOrder && randomizeItems && shuffledItems && shuffledItems.length > 0) {
            onSetAnswer({ randomizationOrder: shuffledItems });
        }
    }, [shuffledItems, randomizeItems, onSetAnswer, randomizationOrder]);

    // Handle validation:
    useEffect(() => {
        if (minChoices !== undefined) {
            const isValid = minChoices === 0 || (selected && selected.length >= minChoices);
            dispatch(
                pageStateChanged({
                    property: 'isCurrentPageValid',
                    value: isValid,
                })
            );
        }
    }, [minChoices, selected, dispatch]);

    const handleSelection = useCallback(
        (key, id) => {
            if (isSingleChoice) {
                onSetAnswer({ value: [key], ids: [id] });
                if (autoContinue && onDone && id !== OPEN_ITEM) {
                    onDone();
                    dispatch(
                        pageStateChanged({
                            property: 'isFooterHidden',
                            value: true,
                        })
                    );
                }
                return;
            }

            const newSelected = Array.from(selected);

            // Store also items ids (usefull for logic):
            const selectedIds = currentAnswers?.ids ?? [];
            const newSelectedIds = Array.from(selectedIds);

            const inx = newSelected.indexOf(key);
            if (inx > -1) {
                newSelected.splice(inx, 1);
                newSelectedIds.splice(inx, 1);
            } else {
                newSelected.push(key);
                newSelectedIds.push(id);
            }

            onSetAnswer({ value: newSelected, ids: newSelectedIds });
        },
        [selected, currentAnswers, isSingleChoice, autoContinue, onDone, onSetAnswer, dispatch]
    );

    return (
        <Content
            data={data}
            itemsOrder={itemsOrder}
            lang={lang}
            ChoiceItem={ChoiceItem}
            onSelection={handleSelection}
            selected={selected}
            unlimited={unlimited}
            isSingleChoice={isSingleChoice}
            choicesLimit={choicesLimit}
            autoContinue={autoContinue}
            showOpenItem={showOpenItem}
            openItemData={openItemData}
            openItemUserValue={openItemUserValue}
            onOpenItemValueChange={(v) => onSetAnswer({ openItemUserValue: v })}
            isLocked={isLocked}
            centeredAnswers={centeredAnswers} // @TODO: remove
        />
    );
}

const Mobile = ({
    data,
    itemsOrder,
    lang,
    ChoiceItem,
    onSelection,
    selected,
    unlimited,
    isSingleChoice,
    choicesLimit,
    showOpenItem,
    openItemData,
    openItemUserValue,
    onOpenItemValueChange,
    isLocked,
    centeredAnswers, // @TODO: remove
}) => {
    const isOpenItemHidden = useHideOpenItem(openItemData);
    const [isOpenItemInputActive, setIsOpentItemInputActive] = useState(false);
    const itemsRefs = useMemo(() => {
        const refs = itemsOrder?.map(() => React.createRef());
        if (refs && showOpenItem) refs.push(React.createRef());
        return refs;
    }, [itemsOrder, showOpenItem]);

    useRoveFocus(itemsRefs);

    const isSelected = useCallback(
        (itemKey) => {
            return selected.includes(itemKey);
        },
        [selected]
    );

    const isDisabled = useCallback(
        (itemKey) => {
            return (
                isLocked || (!isSingleChoice && !isSelected(itemKey) && !unlimited && selected.length >= choicesLimit)
            );
        },
        [isLocked, isSingleChoice, unlimited, selected, choicesLimit, isSelected]
    );

    const handleOpenItemSelection = useCallback(() => {
        onSelection(openItemData.key, OPEN_ITEM);
        setIsOpentItemInputActive(true);
        // if (!isSelected(openItemData.key)) {
        // }
    }, [onSelection, openItemData, isSelected]);

    return (
        <div className={styles.itemsListCont_mobile}>
            <Media
                fileId={data.media}
                settings={data.settings.media}
                padding={'var(--space-12)'}
                motionProps={{
                    initial: { opacity: 0 },
                    animate: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.5 },
                    },
                }}
            />
            {itemsOrder &&
                itemsOrder.map((id, i) => {
                    const item = data.itemsData[id];

                    return (
                        <ItemAnimationWrapper key={id} index={i}>
                            <ChoiceItem
                                ref={itemsRefs[i]}
                                key={id}
                                text={item.text[lang]}
                                className={styles.itemCont}
                                style={
                                    centeredAnswers ? { justifyContent: 'center' } : {} // @TODO: remove
                                }
                                isSelected={isSelected(item.key)}
                                onSelect={() => onSelection(item.key, id)}
                                disabled={isDisabled(item.key)}
                            />
                        </ItemAnimationWrapper>
                    );
                })}
            {showOpenItem && !isOpenItemHidden && (
                <ItemAnimationWrapper key={OPEN_ITEM} index={itemsOrder.length}>
                    <ChoiceItem
                        ref={itemsRefs[itemsRefs.length - 1]}
                        key={OPEN_ITEM}
                        text={openItemUserValue || openItemData.text[lang]}
                        className={styles.itemCont}
                        style={
                            centeredAnswers ? { justifyContent: 'center' } : {} // @TODO: remove
                        }
                        isSelected={isSelected(openItemData.key)}
                        onSelect={handleOpenItemSelection}
                        disabled={isDisabled(openItemData.key)}
                    />
                </ItemAnimationWrapper>
            )}
            <PopupTextInput
                isActive={isOpenItemInputActive}
                isFullscreen={true}
                value={openItemUserValue}
                isValid={true}
                onChange={onOpenItemValueChange}
                onDone={() => setIsOpentItemInputActive(false)}
            />
        </div>
    );
};

const Desktop = ({
    data,
    itemsOrder,
    lang,
    ChoiceItem,
    onSelection,
    selected,
    unlimited,
    isSingleChoice,
    choicesLimit,
    autoContinue,
    showOpenItem,
    openItemData,
    openItemUserValue,
    onOpenItemValueChange,
    isLocked,
}) => {
    const isOpenItemHidden = useHideOpenItem(openItemData);
    const [isOpenItemInputActive, setIsOpentItemInputActive] = useState(false);

    const itemsRefs = useMemo(() => {
        const refs = itemsOrder?.map(() => React.createRef());
        if (refs && showOpenItem) refs.push(React.createRef());
        return refs;
    }, [itemsOrder, showOpenItem]);

    useRoveFocus(itemsRefs);

    const isSelected = useCallback(
        (itemKey) => {
            return selected.includes(itemKey);
        },
        [selected]
    );

    const isDisabled = useCallback(
        (itemKey) => {
            return (
                isLocked || (!isSingleChoice && !isSelected(itemKey) && !unlimited && selected.length >= choicesLimit)
            );
        },
        [isLocked, isSingleChoice, unlimited, selected, choicesLimit, isSelected]
    );

    const handleOpenItemSelection = useCallback(() => {
        onSelection(openItemData.key, OPEN_ITEM);
        setIsOpentItemInputActive(true);
    }, [onSelection, openItemData]);

    return (
        <div className={styles.itemsListCont_desktop}>
            <Media
                fileId={data.media}
                settings={data.settings.media}
                padding={'var(--space-64)'}
                style={{
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0,
                }}
                motionProps={{
                    initial: { opacity: 0 },
                    animate: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.5 },
                    },
                }}
            />
            {itemsOrder &&
                itemsOrder.map((id, i) => {
                    const item = data.itemsData[id];
                    return (
                        <ItemAnimationWrapper key={id} index={i}>
                            <ChoiceItem
                                ref={itemsRefs[i]}
                                key={id}
                                text={item.text[lang]}
                                className={styles.itemCont}
                                isSelected={isSelected(item.key)}
                                onSelect={() => onSelection(item.key, id)}
                                disabled={isDisabled(item.key)}
                            />
                        </ItemAnimationWrapper>
                    );
                })}
            {showOpenItem && !isOpenItemHidden && (
                <>
                    <ItemAnimationWrapper key={OPEN_ITEM} index={itemsOrder.length}>
                        {!isOpenItemInputActive && (
                            <ChoiceItem
                                ref={itemsRefs[itemsRefs.length - 1]}
                                key={OPEN_ITEM}
                                text={openItemUserValue || openItemData.text[lang]}
                                className={styles.itemCont}
                                isSelected={isSelected(openItemData.key)}
                                onSelect={handleOpenItemSelection}
                                disabled={isDisabled(openItemData.key)}
                            />
                        )}
                    </ItemAnimationWrapper>

                    <PopupTextInput
                        isActive={isOpenItemInputActive}
                        value={openItemUserValue}
                        wrapperClassName={styles.desktopOpenInputCont}
                        isValid={true}
                        onChange={onOpenItemValueChange}
                        onDone={() => setIsOpentItemInputActive(false)}
                    />
                </>
            )}
            <DesktopContinueButton transitionDelay={0.4} />
        </div>
    );
};

const ItemAnimationWrapper = ({ index, children }) => {
    return (
        <motion.div
            initial={{ y: -40 * (1 + index), opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                duration: 0.6,
                ease: easing.inOutQuart,
                delay: 0.6 + index * 0.03,
            }}
        >
            {children}
        </motion.div>
    );
};

const useHideOpenItem = (itemData) => {
    const conditionSolver = useConditionSolver();

    const isHidden = useMemo(() => {
        let shouldHide = false;

        if (itemData && itemData.hidingCondition) {
            const condition = { ...itemData.hidingCondition };
            condition.rules = condition.rule; // conditionSolver expects plural: 'rules'
            shouldHide = conditionSolver(condition);
        }

        return shouldHide;
    }, [itemData, conditionSolver]);

    return isHidden;
};
