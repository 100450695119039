import { motion } from 'framer-motion';
import React from 'react';
import { easing } from 'utils/animationEasing';

const varients = {
    'active': {
        height: '100%',
        opacity: 1
    },
    'inactive': {
        height: '0%',
        opacity: 0.3
    }
}

/**
 * 
 * @param {{
 * isActive,
 * style
 * color
 * }} param0 
 */
export default function ChoiceItemFill({
    isActive,
    style,
    color = 'var( --color-mono-5 )'
}) {

    return (
        <motion.div
            initial= 'inactive'
            animate={ isActive ? 'active' : 'inactive' }
            variants={ varients }
            transition={{ duration: 0.5, ease:easing.inOutQuart}}
            style={{
                position: 'absolute',
                right: 0, bottom: 0, left: 0,
                pointerEvents: 'none',
                boxSizing: 'border-box',
                backgroundColor: color,
                ...style
            }}
        />

    )
}