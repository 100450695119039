import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ScalePageModule.module.css";
import { Pagination, PaginationBullets } from "components/Pagination";
import ScaleItem from "./ScaleItem";
import DesktopContinueButton from "features/survey/views/desktop/DesktopContinueButton";
import { getNested } from "utils/miscHelpers";
import { useHasExitTime, usePageItems } from "utils/PageHelpersHooks";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { easing } from "utils/animationEasing";
import { pageStateChanged, surveyModes } from "features/survey/surveySlice";
import Media from "components/media/Media";

export default function ScalePageModule({ data, lang, onSetAnswer }) {
    const dispatch = useDispatch();
    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    const debug = useSelector((state) => state.survey.data.debug);
    const answers = useSelector((state) => state.record.userAnswers);
    const platform = useSelector(
        (state) => state.survey.display.layout.platform
    );

    const pagination = React.useRef();

    const [randomizeItems, showSecondaryButton] = useMemo(() => {
        return [
            getNested(data, "settings", "general", "randomizeItems"),
            getNested(data, "settings", "general", "showSecondaryButton"),
        ];
    }, [data]);

    const [values, randomizationOrder] = useMemo(() => {
        return [
            getNested(answers, data.key, "value"),
            getNested(answers, data.key, "randomizationOrder"),
        ];
    }, [answers, data]);

    const shuffledItems = usePageItems(data);
    const isLocked = useHasExitTime(data);

    const itemsOrder = useMemo(() => {
        // If page has already been submitted, don't shuffe, return items as answered:
        if (randomizeItems && randomizationOrder) {
            return randomizationOrder;
        }
        return shuffledItems;
    }, [randomizeItems, shuffledItems, randomizationOrder]);

    const secondaryButton = useMemo(() => {
        return {
            isVisible: showSecondaryButton,
            ...data.secondaryButtonData,
        };
    }, [data, showSecondaryButton]);

    const [currentItem, setCurrentItem] = useState(0);

    const Content = useMemo(() => {
        switch (platform) {
            case "mobile":
                return Mobile;
            case "desktop":
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    const mediaSettings = data.settings.media;

    // Store randomized items order:
    useEffect(() => {
        if (
            !randomizationOrder &&
            randomizeItems &&
            shuffledItems &&
            shuffledItems.length > 0
        ) {
            onSetAnswer({ randomizationOrder: shuffledItems });
        }
    }, [shuffledItems, randomizeItems, onSetAnswer, randomizationOrder]);

    // Handle validation:
    useEffect(() => {
        if (data) {
            let isValid = false;
            if (values) {
                const required = itemsOrder.length;
                if (Object.keys(values).length === required) {
                    isValid = true;
                }
            }

            dispatch(
                pageStateChanged({
                    property: "isCurrentPageValid",
                    value: isValid,
                })
            );

            // @DOTO: handle none mandatory items
        }
    }, [values, data, itemsOrder, dispatch]);

    // Find the next item that hasn't been answered yet and jump to it:
    useEffect(() => {
        if (
            surveyMode !== surveyModes.EDITING &&
            data &&
            values &&
            pagination.current
        ) {
            for (let i = 0; i < itemsOrder.length; i++) {
                const id = itemsOrder[i];
                const item = data.itemsData[id];
                if (!Object.keys(values).includes(item.key)) {
                    pagination.current.jumpTo(i);
                    break;
                }
            }
        }
    }, [surveyMode, values, data, itemsOrder, pagination]);

    const previewItem = useMemo(() => {
        if (surveyMode === surveyModes.EDITING && debug.currentItemId)
            return debug.currentItemId;
        return null;
    }, [surveyMode, debug]);

    const handleSelect = useCallback(
        ({ itemKey, valueKey, valueIndex }) => {
            const newValues = { ...values };
            let index = valueIndex;
            // index is increased by 1 to match the value's key i.e( scale_1,scale_2,scale_3,scale_4,scale_5)
            if (index > -1) index++;
            newValues[itemKey] = { key: valueKey, index };

            onSetAnswer({ value: newValues });
        },
        [values, onSetAnswer]
    );

    if (!data.itemsOrder || !data.itemsData) return null;

    return (
        <Content
            data={data}
            mediaSettings={mediaSettings}
            itemsOrder={itemsOrder}
            pagination={pagination}
            currentItem={currentItem}
            previewItem={previewItem}
            onCurrentItemChange={(inx) => setCurrentItem(inx)}
            onSelect={handleSelect}
            isLocked={isLocked}
            secondaryButton={secondaryButton}
        />
    );
}

const Mobile = ({
    data,
    mediaSettings,
    itemsOrder,
    pagination,
    currentItem,
    previewItem,
    onCurrentItemChange,
    onSelect,
    isLocked,
    secondaryButton,
}) => {
    return (
        <motion.div
            className={styles.itemsListCont}
            initial={{ y: 40, opacity: 0, scale: 0.9 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{ duration: 1, ease: easing.inOutQuart, delay: 0.6 }}
        >
            <Media
                fileId={data.media}
                settings={mediaSettings}
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    padding:
                        mediaSettings?.width === "content"
                            ? "var(--space-32)"
                            : 0,
                    height: mediaSettings?.heightLimit,
                    width: "100%",
                    zIndex: 2,
                    boxSizing: "border-box",
                }}
                captionStyle={{
                    position: "absolute",
                    top:
                        mediaSettings?.width === "content"
                            ? mediaSettings?.heightLimit
                            : `calc( ${mediaSettings?.heightLimit} + var(--space-32))`,
                    right: "var(--space-32)",
                    left: "var(--space-32)",
                }}
            />
            <Pagination
                ref={pagination}
                className={styles.scaleItemCont}
                style={
                    data.media
                        ? {
                              top: `calc(${mediaSettings?.heightLimit} + var(--space-32))`,
                          }
                        : {}
                }
                numberOfPages={itemsOrder.length}
                onChange={onCurrentItemChange}
            >
                <ScaleItem
                    index={currentItem}
                    previewItem={previewItem}
                    data={data}
                    itemsOrder={itemsOrder}
                    onSelect={onSelect}
                    disabled={isLocked}
                    secondaryButton={secondaryButton}
                />
            </Pagination>

            {itemsOrder.length > 1 && (
                <PaginationBullets
                    bulletSize={4}
                    className={styles.bulletsCont}
                    style={
                        data.media
                            ? {
                                  top: `calc(${mediaSettings?.heightLimit} + var(--space-32))`,
                              }
                            : {}
                    }
                    numberOfPages={itemsOrder.length}
                    index={currentItem}
                />
            )}
        </motion.div>
    );
};

const Desktop = ({
    data,
    mediaSettings,
    itemsOrder,
    pagination,
    currentItem,
    onCurrentItemChange,
    onSelect,
    isLocked,
    secondaryButton,
}) => {
    return (
        <>
            <motion.div
                className={styles.itemsListCont}
                initial={{ y: 40, opacity: 0, scale: 0.9 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    ease: easing.inOutQuart,
                    delay: 0.6,
                }}
            >
                <Media
                    fileId={data.media}
                    settings={mediaSettings}
                    style={{
                        position: "absolute",
                        marginTop: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        zIndex: 10,
                        width: mediaSettings?.width == "full" ? "100%" : "auto",
                        paddingTop: "var(--space-64)",
                    }}
                    captionStyle={{
                        position: "absolute",
                        top: `calc( ${mediaSettings?.heightLimit} + var(--space-32))`,
                    }}
                />
                <Pagination
                    ref={pagination}
                    className={styles.scaleItemCont}
                    style={
                        data.media
                            ? {
                                  top: `calc(${mediaSettings?.heightLimit} + var(--space-64))`,
                                  paddingTop: 0,
                              }
                            : {}
                    }
                    numberOfPages={itemsOrder.length}
                    onChange={onCurrentItemChange}
                >
                    <ScaleItem
                        index={currentItem}
                        data={data}
                        itemsOrder={itemsOrder}
                        onSelect={onSelect}
                        disabled={isLocked}
                        secondaryButton={secondaryButton}
                    />
                </Pagination>
            </motion.div>
            {itemsOrder.length > 1 && (
                <PaginationBullets
                    bulletSize={6}
                    className={styles.bulletsCont}
                    numberOfPages={itemsOrder.length}
                    index={currentItem}
                    hideWhileIdle={false}
                />
            )}
            <DesktopContinueButton />
        </>
    );
};
