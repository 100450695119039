import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./DropdownListPageModule.module.css";
import SearchableSelect from "components/SearchableSelect";
import DesktopContinueButton from "features/survey/views/desktop/DesktopContinueButton";
import Media from "components/media/Media";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { easing } from "utils/animationEasing";
import { pageStateChanged } from "features/survey/surveySlice";
import { getNested } from "utils/miscHelpers";
import { useHasExitTime, usePageItems } from "utils/PageHelpersHooks";

export default function DropdownListPageModule({ data, lang, onSetAnswer }) {
    const dispatch = useDispatch();

    const platform = useSelector(
        (state) => state.survey.display.layout.platform
    );
    const answers = useSelector((state) => state.record.userAnswers);
    const randomizeItems = useMemo(
        () => getNested(data, "settings", "general", "randomizeItems"),
        [data]
    );

    const shuffledItems = usePageItems(data);
    const isLocked = useHasExitTime(data);

    const [selected, randomizationOrder] = useMemo(() => {
        const value = getNested(answers, data.key, "value");

        return [
            value ? value[0] : null, // @TODO: pass all aray when SearchableSelect will support multiple selection
            getNested(answers, data.key, "randomizationOrder"),
        ];
    }, [data, answers]);

    const itemsOrder = useMemo(() => {
        // If page has already been submitted, don't shuffe, return items as answered:
        if (randomizeItems && randomizationOrder) {
            return randomizationOrder;
        }
        return shuffledItems;
    }, [randomizeItems, shuffledItems, randomizationOrder]);

    const Content = useMemo(() => {
        switch (platform) {
            case "mobile":
                return Mobile;
            case "desktop":
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    const options = useMemo(() => {
        if (itemsOrder && itemsOrder.length > 0) {
            return itemsOrder.map((id) => {
                const item = data.itemsData[id];
                return { id, key: item.key, label: item.text[lang] };
            });
        }

        return [];
    }, [data, itemsOrder, lang]);

    // Store randomized items order:
    useEffect(() => {
        if (
            !randomizationOrder &&
            randomizeItems &&
            shuffledItems &&
            shuffledItems.length > 0
        ) {
            onSetAnswer({ randomizationOrder: shuffledItems });
        }
    }, [shuffledItems, randomizeItems, onSetAnswer, randomizationOrder]);

    // Handle validation:
    useEffect(() => {
        const isValid = selected !== null;
        dispatch(
            pageStateChanged({ property: "isCurrentPageValid", value: isValid })
        );
    }, [selected, dispatch]);

    const handleSelect = useCallback(
        (op) => {
            onSetAnswer({ value: [op.key], ids: [op.id] });
        },
        [onSetAnswer]
    );

    return (
        <Content
            lang={lang}
            data={data}
            options={options}
            selected={selected}
            onChange={handleSelect}
            disabled={isLocked}
        />
    );
}

const Mobile = ({ lang, data, options, selected, onChange, disabled }) => {
    return (
        <div
            key={data.id}
            className={styles.mainCont}
            style={
                data.media
                    ? {
                          paddingTop: "var(--space-24)",
                          justifyContent: "flex-start",
                      }
                    : {}
            }
        >
            <Media
                fileId={data.media}
                settings={data.settings.media}
                pagePadding="var(--space-24)"
                motionProps={{
                    initial: { opacity: 0 },
                    animate: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.5 },
                    },
                }}
            />
            <motion.div
                style={data.media ? { margin: "auto 0" } : {}}
                initial={{ y: 80, opacity: 0, scale: 0.8 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    ease: easing.inOutQuart,
                    delay: 0.6,
                }}
            >
                <SearchableSelect
                    key={selected}
                    isFullscreen={true}
                    placeholder={data.listTitle[lang]}
                    options={options}
                    value={selected}
                    onChange={onChange}
                    disabled={disabled}
                />
            </motion.div>
        </div>
    );
};

const Desktop = ({ lang, data, options, selected, onChange, disabled }) => {
    return (
        <div key={data.id} className={styles.mainCont}>
            <Media
                fileId={data.media}
                settings={data.settings.media}
                style={{
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0,
                    marginBottom: "var(--space-64)",
                }}
                motionProps={{
                    initial: { opacity: 0 },
                    animate: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.5 },
                    },
                }}
            />
            <motion.div
                initial={{ y: 80, opacity: 0, scale: 0.8 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    ease: easing.inOutQuart,
                    delay: 0.6,
                }}
            >
                <SearchableSelect
                    key={selected}
                    isFullscreen={false}
                    placeholder={data.listTitle[lang]}
                    options={options}
                    value={selected}
                    onChange={onChange}
                    disabled={disabled}
                />
                <DesktopContinueButton />
            </motion.div>
        </div>
    );
};
