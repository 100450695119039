import React, { useEffect, useRef, useState } from 'react';
import styles from './MobileView.module.css';

import MobileLandscapeView from './MobileLandscapeView';
import MobilePageTopPanel from './MobilePageTopPanel';
import MobilePageFooter from './MobilePageFooter';
import Splash from '../Splash';
import Page from 'features/survey/page/Page';

import { useDispatch, useSelector } from 'react-redux';
import { layoutModes } from 'utils/useLayout';
import { pageStateChanged, surveyModes } from 'features/survey/surveySlice';
import ActivityIndicator from 'components/ActivityIndicator';
import NotFound from '../NotFound';

export default function MobileView() {
    const layoutMode = useSelector((state) => state.survey.display.layout.mode);
    const fetchSurveyStatus = useSelector((state) => state.survey.fetchSurveyStatus);
    const fetchMilgoRefStatus = useSelector((state) => state.survey.fetchMilgoRefStatus);
    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);

    const [pageMounted, setPageMounted] = useState(false);

    if (fetchSurveyStatus === 'failed' || fetchMilgoRefStatus === 'failed') {
        return (
            <div className={styles.desktopViewCont} aria-busy="true">
                <div className={styles.centeredView}>
                    <NotFound />
                </div>
            </div>
        );
    }

    if (fetchSurveyStatus !== 'succeeded' && surveyMode !== surveyModes.EDITING) {
        return (
            <div className={styles.mobileViewCont} aria-busy="true">
                <div className={styles.centeredView}>
                    <ActivityIndicator size={32} />
                </div>
            </div>
        );
    }

    if (layoutMode === layoutModes.MOBILE_LANDSCAPE) {
        return (
            <div className={styles.mobileViewCont}>
                <MobileLandscapeView />
            </div>
        );
    }

    return (
        <>
            {pageMounted && <Content />}
            <Splash onComplete={() => setPageMounted(true)} />
        </>
    );
}

const Content = () => {
    const viewContRef = useRef();
    const dispatch = useDispatch();
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);

    // Reset footer state when page changes:
    useEffect(() => {
        dispatch(
            pageStateChanged({
                property: 'isFooterHidden',
                value: false,
                source: 'MobileView',
            })
        );
    }, [currentPage, dispatch]);

    // Scroll to top on page change:
    useEffect(() => {
        if (viewContRef.current) {
            viewContRef.current.scrollTop = 0;
        }
    }, [currentPage, viewContRef]);

    return (
        <>
            <fieldset ref={viewContRef} className={styles.mobileViewCont}>
                <MobilePageTopPanel id={currentPage} />
                <Page id={currentPage} />
            </fieldset>
            <MobilePageFooter />
        </>
    );
};
