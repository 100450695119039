import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useOnPageEnter, useOnPageExit } from 'utils/LogicHooks';
import { useDispatch, useSelector } from 'react-redux';
import { pageTypes } from 'features/survey/surveyConfiguration';
import { addDataForSubmission, setUserAnswer, setChangedInput, clearUserAnswer } from 'features/record/recordSlice';
import { selectPageData, setHistoryIndex, setNextPage } from '../surveySlice';
import { useHasExitTime } from 'utils/PageHelpersHooks';
import { getNested } from 'utils/miscHelpers';
import useAnalytics, { analyticEvents } from 'features/record/useAnalytics';
import { useProgress } from 'components/ProgressBar';

export default function Page({ id }) {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.user.lang);

    const data = useSelector((state) => selectPageData(state, id));
    const userAnswers = useSelector((state) => state.record.userAnswers);
    const allowRepeatedInput = useMemo(() => getNested(data, 'settings', 'general', 'allowRepeatedInput'), [data]);
    const resetInputOnRevisit = useMemo(() => getNested(data, 'settings', 'general', 'resetInputOnRevisit'), [data]);

    const pageAnswer = useMemo(() => {
        if (data && data.key) {
            return userAnswers[data.key];
        }
    }, [data, userAnswers]);

    const onPageEnter = useOnPageEnter();
    const onDone = useOnPageDone();
    const sendAnalyticsEvent = useAnalytics();
    const progress = useProgress();

    const [initiated, setInitiated] = useState(false);

    const Module = useMemo(() => {
        if (!data) return;
        return pageTypes[data.type].module;
    }, [data]);

    // Save user answer //
    const setAnswer = useCallback(
        (answer) => {
            if (!data) return;

            dispatch(
                setUserAnswer({
                    key: data.key,
                    ansData: answer,
                })
            );
        },
        [dispatch, data]
    );

    // Mark data as new:
    useEffect(() => {
        setInitiated(false);
    }, [data]);

    // Handle page enter //
    useEffect(() => {
        if (!data) return;

        // Set initial userAnswer data of a new page:
        if (!initiated && !getNested(pageAnswer, 'enterTime')) {
            setAnswer({
                enterTime: Date.now(),
                pageId: id,
            });

            // Submit page enter time:
            dispatch(
                addDataForSubmission({
                    inputType: 'userAnswers',
                    key: data.key,
                })
            );

            onPageEnter(data);
            setInitiated(true);
        }
    }, [id, data, pageAnswer, initiated, setAnswer, onPageEnter, dispatch]);

    // Send analytics event on page enter //
    useEffect(() => {
        if (!data) return;

        // Send analytics event:
        sendAnalyticsEvent({
            eventKey: analyticEvents.PAGE_ENTERED,
            params: {
                pageKey: data.key,
                progress,
            },
        });
    }, [data, sendAnalyticsEvent, progress]);

    // Handle page re-visit //
    useEffect(() => {
        if (!data) return;

        // Page has already been visited and repeated input is allowed:
        if (!initiated && pageAnswer && pageAnswer.exitTime && allowRepeatedInput) {
            // store previous answer:
            dispatch(
                setChangedInput({
                    key: data.key,
                    ansData: pageAnswer,
                })
            );
            dispatch(
                addDataForSubmission({
                    inputType: 'userAnswers',
                    key: '___changed_inputs',
                })
            );

            if (resetInputOnRevisit) {
                // reset page answers:
                dispatch(clearUserAnswer({ key: data.key }));
            }

            // set new data:
            dispatch(
                setUserAnswer({
                    key: data.key,
                    ansData: {
                        pageId: id,
                        enterTime: Date.now(),
                    },
                })
            );

            onPageEnter(data);
            setInitiated(true);
            return;
        } else if (!initiated) {
            // Page has already been visited and has answer. Continue normally:
            setInitiated(true);
            return;
        }
    }, [id, data, initiated, pageAnswer, allowRepeatedInput, resetInputOnRevisit, onPageEnter, dispatch]);

    /////////////////////
    // RENDER ///////////
    /////////////////////

    if (!data) return null;

    return (
        <>
            <Module key={id} id={id} data={data} lang={lang} onSetAnswer={setAnswer} onNext={onDone} />
        </>
    );
}

export const useOnPageDone = () => {
    const dispatch = useDispatch();
    // const userAnswers = useSelector( state => state.record.userAnswers );
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);
    const history = useSelector((state) => state.survey.navigation.history);

    const data = useSelector((state) => selectPageData(state, currentPage));
    const allowRepeatedInput = useMemo(() => getNested(data, 'settings', 'general', 'allowRepeatedInput'), [data]);
    const onPageExit = useOnPageExit();
    const hasExitTime = useHasExitTime(data);

    const handler = useCallback(() => {
        // When there is no record for this page yet or repeated filling is allowed:
        if (!hasExitTime || allowRepeatedInput) {
            // Set history index to latest to make sure the survey proceedes normaly on 'next'
            // (in case the user reached this page from history and 'allowRepeatedInput' is enabled).
            dispatch(setHistoryIndex(history.length - 1));

            dispatch(
                setUserAnswer({
                    key: data.key,
                    ansData: { exitTime: Date.now() },
                })
            );

            onPageExit(data);

            dispatch(
                addDataForSubmission({
                    inputType: 'userAnswers',
                    key: data.key,
                })
            );
        } else {
            dispatch(setNextPage('next'));
        }
    }, [dispatch, onPageExit, data, hasExitTime, allowRepeatedInput, history]);

    return handler;
};
