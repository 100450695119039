import { useCallback } from "react"
import { useSelector } from "react-redux";

export default function useUrlWithVars() {

    const variables = useSelector( state => state.record.variables );

    const urlWithVars = useCallback( (url) => {
        if( !url ) return null;
        let _url = url;
        const regex = /\${\w+}/g;
        const matches = _url.match( regex );
        if( matches ) {
            matches.forEach( m => {
                const varName = m.slice( 2, m.length - 1 );
                const matchingSurveyVariable = variables[ varName ];
                if( matchingSurveyVariable ) {
                    _url = _url.replace( m, matchingSurveyVariable );
                }
            });
        }
        return _url;

    },[variables])

    return urlWithVars;
}