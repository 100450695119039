import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentBlock,
    setCurrentPage,
    addPageToHistory,
    setNextPage,
    setHistoryIndex,
    surveyModes,
    resetHistory,
    setBlockPageIndex,
    addToVisitedPages,
} from 'features/survey/surveySlice';
import { pageTypes } from 'features/survey/surveyConfiguration';
import { useReadRecordFromStorage } from 'features/record/RecordHandling';
import { getNested } from 'utils/miscHelpers';
import useAnalytics, { analyticEvents } from 'features/record/useAnalytics';

export default function Navigator() {
    const dispatch = useDispatch();
    const getStoredData = useReadRecordFromStorage();

    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    const readDebug = useSelector((state) => state.survey.runtimeConfig.readDebug);
    const pagePreviewId = useSelector((state) => state.survey.runtimeConfig.pagePreviewId);
    const surveyData = useSelector((state) => state.survey.data);

    const pagesOrder = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesOrder'));
    const pagesData = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesData'));
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);
    const nextPage = useSelector((state) => state.survey.navigation.nextPage);
    const currentBlock = useSelector((state) => state.survey.navigation.currentBlock);
    const blockPageIndex = useSelector((state) => state.survey.navigation.blockPageIndex);

    // We are using 2 ways to store visited pages:
    // 1. history -> pages are stored upon entering. It is used when the user navigates via the window.history api.
    const history = useSelector((state) => state.survey.navigation.history);
    const historyIndex = useSelector((state) => state.survey.navigation.historyIndex);
    // 2. visitedPages -> pages are stored upon exiting. It is used by the hook that handles block navigation.
    const visitedPages = useSelector((state) => state.survey.navigation.visitedPages);

    const sendAnalyticsEvent = useAnalytics();

    // HANDLE CURRENT PAGE CHANGE //
    useEffect(() => {
        // Set the first screen of the survey:
        if (pagesOrder && !currentPage) {
            const storedData = getStoredData();

            // On live mode, if the survey hasn been visited before, RecordHandling will take care of current page.
            if (surveyMode === surveyModes.LIVE && storedData && storedData.currentPage) {
                return;
            }

            dispatch(setCurrentPage(pagesOrder[0]));
        }

        if (pagesData && currentPage) {
            const data = pagesData[currentPage];

            if (!data) {
                // Page not found. Probably deleted while in editing mode.
                dispatch(setCurrentPage(pagesOrder[0]));
                return;
            }

            if (data.type === pageTypes.BLOCK.key) {
                dispatch(setCurrentBlock(currentPage));
            } else {
                // Handle history:
                if (data.type === pageTypes.ENDING.key) {
                    // On survey end, clear history so the user won't be able to navigate back:
                    if (history.length > 0) {
                        dispatch(resetHistory());
                    }
                } else if (!history.includes(currentPage)) {
                    // Store current page:
                    dispatch(addPageToHistory(currentPage));
                }
            }
        }
    }, [surveyMode, pagesOrder, pagesData, currentPage, history, getStoredData, dispatch]);

    // HANDLE PREVIEW DEBUG //
    useEffect(() => {
        if (surveyData && ((surveyMode === surveyModes.PREVIEW && readDebug) || surveyMode === surveyModes.EDITING)) {
            if (pagePreviewId) {
                dispatch(setCurrentPage(pagePreviewId));
            } else {
                dispatch(setCurrentPage(surveyData.debug.previewId));
            }
        }
    }, [surveyMode, readDebug, surveyData, pagePreviewId, dispatch]);

    // HANDLE BLOCK NAVIGATION //

    const blockEnded = useCallback(() => {
        const targetInx = pagesOrder.indexOf(currentBlock) + 1;

        // Make sure it's not the end of the survey:
        if (targetInx < pagesOrder.length) {
            const targetPage = pagesOrder[targetInx];

            dispatch(setCurrentPage(targetPage));
            dispatch(setCurrentBlock(null));
            dispatch(setBlockPageIndex(0));
        }
    }, [pagesOrder, currentBlock, dispatch]);

    const blockRandomNavigation = useCallback(
        (blockData) => {
            const { randomDrawCount, randomizationWeights } = blockData.settings.general;
            const visitedBlockPages = [];
            visitedPages.forEach((p) => {
                if (blockData.pages.includes(p)) {
                    visitedBlockPages.push(p);
                }
            });

            const visitedCount = visitedBlockPages.length;

            if (visitedCount >= blockData.pages.length) {
                blockEnded();
                return;
            }

            if (visitedCount >= randomDrawCount) blockEnded();
            else {
                const pagesWeights = { ...randomizationWeights };
                visitedBlockPages.forEach((id) => delete pagesWeights[id]);

                // Calculate sum of remaining weights:
                let sum = 0;
                Object.values(pagesWeights).forEach((w) => (sum += w));

                // Map pages to probability steps by normalized weights:
                const normalized = Object.values(pagesWeights).map((w) => w / sum);

                let lastWeight = 0;
                const probabilities = [];
                Object.keys(pagesWeights).forEach((id, i) => {
                    const step = normalized[i] + lastWeight;
                    probabilities.push({ step, id });
                    lastWeight = step;
                });

                // Sort by step:
                probabilities.sort((a, b) => a.step - b.step);

                // Draw page:
                const r = Math.random();
                probabilities.forEach((item, i) => {
                    if (r <= item.step && (i < 1 || r > probabilities[i - 1].step)) {
                        dispatch(setCurrentPage(item.id));
                        return;
                    }
                });
            }
        },
        [visitedPages, blockEnded, dispatch]
    );

    const blockNormalNavigation = useCallback(
        (blockData) => {
            if (blockPageIndex < blockData.pages.length) {
                const targetPage = blockData.pages[blockPageIndex];
                dispatch(setCurrentPage(targetPage));
            } else {
                blockEnded();
            }
        },
        [blockPageIndex, blockEnded, dispatch]
    );

    useEffect(() => {
        if (pagesData && currentBlock) {
            const blockData = pagesData[currentBlock];
            const { isRandom } = blockData.settings.general;

            if (isRandom) {
                blockRandomNavigation(blockData);
            } else {
                blockNormalNavigation(blockData);
            }
        }
    }, [currentBlock, pagesData, blockRandomNavigation, blockNormalNavigation]);

    // HANDLE NEXT PAGE //
    useEffect(() => {
        if (!nextPage) return;

        // Add the page we're currently moving out from to the visited pages list.
        if (!visitedPages.includes(currentPage)) {
            dispatch(addToVisitedPages(currentPage));
        }

        // Solve next:
        if (nextPage === 'next') {
            // Navigating next from history:
            if (historyIndex < history.length - 1) {
                dispatch(setNextPage(null));
                window.history.forward();

                return;
            }

            if (!currentBlock) {
                // In case the current page is inside a block and we entered it directly through preview or goto action,
                // the next page should be handled by the block effect.
                const currentPageData = pagesData[currentPage];
                if (currentPageData.parentBlock) {
                    const blockData = pagesData[currentPageData.parentBlock];
                    const targetInx = blockData.pages.indexOf(currentPage) + 1;

                    dispatch(setBlockPageIndex(targetInx));
                    dispatch(setCurrentBlock(currentPageData.parentBlock));
                    dispatch(setNextPage(null));
                    return;
                }

                const targetInx = pagesOrder.indexOf(currentPage) + 1;

                // Make sure it's not the end of the survey:
                if (targetInx < pagesOrder.length) {
                    const targetPage = pagesOrder[targetInx];

                    dispatch(setCurrentPage(targetPage));
                }
            } else {
                dispatch(setBlockPageIndex(blockPageIndex + 1));
                dispatch(setNextPage(null));
            }
            return;
        }

        // Goto page:
        if (pagesData[nextPage]) {
            dispatch(setCurrentBlock(null));
            dispatch(setBlockPageIndex(0));
            dispatch(setCurrentPage(nextPage));
        } else {
            console.error('Trying to navigate to an undefined target: ', nextPage);
        }
    }, [
        nextPage,
        currentPage,
        pagesOrder,
        pagesData,
        currentBlock,
        blockPageIndex,
        visitedPages,
        history,
        historyIndex,
        dispatch,
    ]);

    // HANDLE HISTORY EVENTS //
    useEffect(() => {
        function historyHandler(event) {
            // console.log( event.state.historyIndex );
            if (event.state) {
                const targetIndex = event.state.historyIndex;

                if (targetIndex >= 0 && targetIndex < history.length) {
                    dispatch(setCurrentPage(history[targetIndex]));
                    dispatch(setHistoryIndex(targetIndex));
                    dispatch(setCurrentBlock(null));
                }

                if (targetIndex < historyIndex) {
                    // Send back_navigation event to analytics:
                    backNavAnalytics();
                }
            }
        }

        window.addEventListener('popstate', historyHandler);

        return () => window.removeEventListener('popstate', historyHandler);
    }, [dispatch, history, historyIndex]);

    const backNavAnalytics = useCallback(() => {
        const eventParams = {
            pageKey: pagesData[currentPage]?.key,
        };

        sendAnalyticsEvent({ eventKey: analyticEvents.BACK_NAVIGATION, params: eventParams });
    }, [sendAnalyticsEvent, currentPage, pagesData]);

    return null;
}
