import OpeningPageModule from './page/page_modules/OpeningPageModule';
import MultipleChoicePageModule from './page/page_modules/MultipleChoicePageModule';
import ScalePageModule from './page/page_modules/scale_page_module/ScalePageModule';
import NumberPageModule from './page/page_modules/NumberPageModule';
import DropdownListPageModule from './page/page_modules/DropdownListPageModule';
import OpenEndedPageModule from './page/page_modules/OpenEndedPageModule';
import DescriptionPageModule from './page/page_modules/DescriptionPageModule';
import ExternalPageModule from './page/page_modules/ExternalPageModule';
import EndingPageModule from './page/page_modules/EndingPageModule';

export const supportedLanguages = {
    HE: 'he',
    EN: 'en',
    AR: 'ar',
};

export const pageLayoutModes = {
    PANEL_VIEW: 'panelView',
    FULL_VIEW: 'fullView',
};

export const textColors = {
    color_000: { light: 'hsl(209, 100%, 40%)', dark: 'hsl(209, 100%, 65%)' }, // Blue
    color_001: { light: 'hsl(139, 100%, 40%)', dark: 'hsl(139, 100%, 65%)' }, // Green
    color_002: { light: 'hsl(354, 100%, 40%)', dark: 'hsl(354, 100%, 65%)' }, // Pink
    color_003: { light: 'hsl(51, 100%, 40%)', dark: 'hsl(51, 100%, 65%)' }, // Yellow
    color_004: { light: 'hsl(30, 100%, 40%)', dark: 'hsl(30, 100%, 65%)' }, // Orange
};

export const OPEN_ITEM = 'open_item';

export const pageTypes = {
    OPENING: {
        key: 'OPENING',
        module: OpeningPageModule,
        mode: pageLayoutModes.FULL_VIEW,
    },
    MULTIPLE_CHOICE: {
        key: 'MULTIPLE_CHOICE',
        module: MultipleChoicePageModule,
        mode: pageLayoutModes.PANEL_VIEW,
    },
    SCALE: {
        key: 'SCALE',
        module: ScalePageModule,
        mode: pageLayoutModes.PANEL_VIEW,
    },
    NUMBER: {
        key: 'NUMBER',
        module: NumberPageModule,
        mode: pageLayoutModes.PANEL_VIEW,
    },
    DROPDOWN_LIST: {
        key: 'DROPDOWN_LIST',
        module: DropdownListPageModule,
        mode: pageLayoutModes.PANEL_VIEW,
    },
    OPEN_ENDED_QUESTION: {
        key: 'OPEN_ENDED_QUESTION',
        module: OpenEndedPageModule,
        mode: pageLayoutModes.PANEL_VIEW,
    },
    DESCRIPTION: {
        key: 'DESCRIPTION',
        module: DescriptionPageModule,
        mode: pageLayoutModes.FULL_VIEW,
    },
    EXTERNAL_PAGE: {
        key: 'EXTERNAL_PAGE',
        module: ExternalPageModule,
        mode: pageLayoutModes.FULL_VIEW,
    },
    ENDING: {
        key: 'ENDING',
        module: EndingPageModule,
        mode: pageLayoutModes.FULL_VIEW,
    },
    BLOCK: {
        key: 'BLOCK',
    },
};
