import React, { useMemo, useEffect } from 'react';
import styles from './MainView.module.css';

import MobileView from './mobile/MobileView';
import DesktopView from './desktop/DesktopView';
import PreviewModeWarning from './debug/PreviewModeWarning';
import { useSelector } from 'react-redux';
import PlatformLimitationMessage from './platform-limitation/PlatformLimitationMessage';
import { useDispatch } from 'react-redux';
import { layoutModes } from 'utils/useLayout';
import { displayChanged } from '../surveySlice';
import PageTitleAnnouncer from '../page/PageTitleAnnouncer';

export default function MainView() {
    const dispatch = useDispatch();
    const direction = useSelector((state) => state.survey.display.direction);
    const theme = useSelector((state) => state.survey.display.theme);
    const layout = useSelector((state) => state.survey.display.layout);
    const platformLimitation = useSelector((state) => state.survey.data?.settings?.general?.platformLimitation);

    useEffect(() => {
        if (platformLimitation === 'force_mobile' && layout.platform !== 'mobile') {
            dispatch(displayChanged({ property: 'layout', value: { ...layout, platform: 'mobile' } }));
        }
    }, [layout, platformLimitation, dispatch]);

    const platformView = useMemo(() => {
        if (layout.platform === 'mobile' && platformLimitation === 'desktop_only') {
            return <PlatformLimitationMessage limitation="desktop_only" />;
        }

        if (layout.platform === 'desktop' && platformLimitation === 'mobile_only') {
            return <PlatformLimitationMessage limitation="mobile_only" />;
        }

        if (
            platformLimitation === 'force_mobile' &&
            ![layoutModes.MOBILE, layoutModes.MOBILE_LANDSCAPE].includes(layout.mode)
        ) {
            return (
                <div className={styles.forcedMobileView}>
                    <MobileView />;
                </div>
            );
        }

        if (layout.platform === 'mobile') return <MobileView />;
        if (layout.platform === 'desktop') return <DesktopView />;

        return null;
    }, [layout.platform, layout.mode, platformLimitation]);

    return (
        <main className={styles.root} dir={direction} data-platform={layout.platform} data-theme={theme}>
            <PageTitleAnnouncer />
            {platformView}
            <PreviewModeWarning />
        </main>
    );
}
