import { setWebViewActive, setWebViewTitle, setWebViewUrl } from 'features/webview/webviewSlice';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useUrlWithVars from 'utils/useUrlWithVars';

export default function LinkText({ data, children }) {
    const dispatch = useDispatch();
    const getUrlWithVars = useUrlWithVars();

    const handleWebview = useCallback(() => {
        dispatch(setWebViewUrl(data.url));
        dispatch(setWebViewActive(true));
        if (data.title) {
            dispatch(setWebViewTitle(data.title));
        }
    }, [data, dispatch]);

    return (
        <>
            {data.targetWindow === '_webview' ? (
                <span className={'link'} onClick={handleWebview}>
                    {children}
                </span>
            ) : (
                <a href={getUrlWithVars(data.url)} target={data.targetWindow} rel="noopener noreferrer">
                    {children}
                </a>
            )}
        </>
    );
}
