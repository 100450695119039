import React, { useMemo } from 'react';
import styles from './DesktopContinueButton.module.css';
import TextButton from 'components/TextButton';
import { useSelector } from 'react-redux';
import { pageTexts } from 'utils/appTexts';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useOnPageDone } from 'features/survey/page/Page';

const texts = pageTexts;

const hideContinueBtnVarients = {
    hidden: { y: 80 },
    visible: { y: 0 },
};

export default function DesktopContinueButton({ className, style, transitionDelay = 0 }) {
    const lang = useSelector((state) => state.user.lang);
    const direction = useSelector((state) => state.survey.display.direction);
    const { continueButtonCentered, continueButtonText } = useSelector((state) => {
        const currentPage = state.survey.navigation.currentPage;
        const pageData = state.survey.data?.content.pagesData[currentPage];
        return pageData?.settings.general || {};
    });

    const onDone = useOnPageDone();

    const isPageValid = useSelector((state) => state.survey.currentPageState.isCurrentPageValid);

    const isVisible = useMemo(() => isPageValid, [isPageValid]);

    return (
        <div className={`${styles.container} ${className}`} style={style}>
            <motion.div
                initial="hidden"
                animate={isVisible ? 'visible' : 'hidden'}
                transition={
                    isVisible
                        ? {
                              duration: 0.6,
                              ease: easing.outQuart,
                              delay: transitionDelay,
                          }
                        : { duration: 0.4, ease: easing.inOutQuart }
                }
                variants={hideContinueBtnVarients}
                className={styles.continueBtnWrapper}
            >
                <TextButton
                    label={
                        continueButtonText && continueButtonText[lang]
                            ? continueButtonText[lang]
                            : texts.continueBtn[lang]
                    }
                    // className={ styles.continueBtn } // for some unknow reason the css style won't compile here..
                    style={{
                        minWidth: 128,
                        padding: '12px 16px',
                        borderRadius: 12,
                        margin: 2,
                        justifyContent: continueButtonCentered ? 'center' : 'space-between',
                    }}
                    theme={'strong'}
                    iconAfter={
                        continueButtonCentered ? '' : direction === 'rtl' ? 'chevron_left_arrow' : 'chevron_right_arrow'
                    }
                    disabled={!isVisible}
                    onClick={onDone}
                />
            </motion.div>
        </div>
    );
}
