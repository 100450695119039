import {
    addDataForSubmission,
    receivedUrlParams,
    resetRecord,
    setMilgoParams,
    setVariable,
} from 'features/record/recordSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import {
    surveyModes as modes,
    editingSurveyDataReceived,
    setCurrentBlock,
    runtimeConfigChanged,
    fetchSurvey,
    resetHistory,
    setBlockPageIndex,
    surveyEnv,
    fetchMilgoRef,
    fetchMilgoSurveyData,
} from './surveySlice';
import { useMediaPreloader } from 'components/media/mediaPreloading';
import useAnalytics, { analyticEvents } from 'features/record/useAnalytics';

const redirectionUrl = 'https://kayma.com/'; // when survey not found

export default function SurveyFetcher() {
    const dispatch = useDispatch();
    const env = useSelector((state) => state.survey.runtimeConfig.env);
    const fetchMilgoRefStatus = useSelector((state) => state.survey.fetchMilgoRefStatus);
    const milgoRefData = useSelector((state) => state.survey.milgoRefData);

    const mode = useSelector((state) => state.survey.runtimeConfig.mode);
    const surveyId = useSelector((state) => state.survey.runtimeConfig.surveyId);
    const fetchSurveyStatus = useSelector((state) => state.survey.fetchSurveyStatus);
    const surveyData = useSelector((state) => state.survey.data);
    const urlParams = useSelector((state) => state.record.urlParams);
    const recordVariables = useSelector((state) => state.record.variables);

    const [initiatedVars, setInitiatedVars] = useState(false);

    const sendAnalyticsEvent = useAnalytics();

    // Send init_app analytics event:
    useEffect(() => {
        sendAnalyticsEvent({ eventKey: analyticEvents.APP_INIT });
    }, []);

    useEffect(() => {
        // Reset when survey data changes. (Usefull while in editing mode)
        setInitiatedVars(false);
    }, [surveyData]);

    // Set initial runtime configuration:
    // Determine surveyMode according to url
    // Determine survey environment
    useEffect(() => {
        if (!mode) {
            let path = window.location.pathname;
            path = path.substring(1);
            const urlInfo = path.split('/');

            const hash = window.location.hash;
            if (hash && !urlInfo[0]) {
                // In case the url belongs to the old survey platform (it begins with '/#' ), redirect users to the legacy address.
                // @TODO: replace host url with the final legacy survey address:
                window.location.href = 'https://survey.kayma.com/' + hash;
                return;
            }

            //#region MILGO
            const env = process.env.REACT_APP_IS_MILGO === 'true' ? surveyEnv.MILGO : surveyEnv.HORIZON;
            if (env === surveyEnv.MILGO) {
                switch (urlInfo[0]) {
                    case modes.MILGO_PREVIW:
                        dispatch(
                            runtimeConfigChanged({
                                env,
                                mode: modes.MILGO_PREVIW,
                                surveyId: urlInfo[1],
                            })
                        );
                        break;
                    case modes.VIEW_ONLY:
                        dispatch(
                            runtimeConfigChanged({
                                env,
                                mode: modes.VIEW_ONLY,
                                surveyId: null,
                                milgoRef: urlInfo[1],
                            })
                        );
                        break;
                    default:
                        dispatch(
                            runtimeConfigChanged({
                                env,
                                mode: modes.LIVE,
                                surveyId: null,
                                milgoRef: urlInfo[0],
                            })
                        );
                        break;
                }
                return;
            }
            //#endregion MIGO

            switch (urlInfo[0]) {
                case modes.EDITING:
                    dispatch(
                        runtimeConfigChanged({
                            env,
                            mode: modes.EDITING,
                            surveyId: null,
                        })
                    );
                    break;
                case modes.PREVIEW:
                    dispatch(
                        runtimeConfigChanged({
                            env,
                            mode: modes.PREVIEW,
                            surveyId: urlInfo[1],
                        })
                    );
                    break;
                case modes.VIEW_ONLY:
                    dispatch(
                        runtimeConfigChanged({
                            env,
                            mode: modes.VIEW_ONLY,
                            surveyId: urlInfo[1],
                        })
                    );
                    break;
                case 'debug-xyz-abc':
                    dispatch(
                        runtimeConfigChanged({
                            env,
                            mode: modes.DEBUG,
                        })
                    );
                    break;
                default:
                    dispatch(
                        runtimeConfigChanged({
                            env,
                            mode: modes.LIVE,
                            surveyId: urlInfo[0],
                        })
                    );
                    break;
            }
        }
    }, [mode, dispatch]);

    //#region MILGO
    // Handle milgo refs:
    useEffect(() => {
        if (env === surveyEnv.MILGO) {
            if (mode === modes.LIVE || mode === modes.VIEW_ONLY) {
                // 1. First get ref data:
                if (fetchMilgoRefStatus === 'idle') {
                    dispatch(fetchMilgoRef()).then((res) => {
                        if (res.type === 'survey/fetchMilgoRef/rejected' || res.payload?.error) {
                            sendAnalyticsEvent({
                                eventKey: analyticEvents.NOT_FOUND,
                                params: { error: `Failed to fetch milgo ref. Details: ${res.payload.error}` },
                            });

                            return;
                        }
                    });
                }

                if (fetchMilgoRefStatus === 'succeeded' && milgoRefData !== null) {
                    // Store milgoParams in the recordSlice:
                    dispatch(setMilgoParams(milgoRefData.milgoParams));
                    sendAnalyticsEvent({ eventKey: analyticEvents.GOT_MILGO_REF_DATA });

                    // 2. Store urlParams
                    dispatch(receivedUrlParams(milgoRefData.surveyParams));

                    // 3. Fetch survey data
                    dispatch(fetchMilgoSurveyData()).then((res) => {
                        if (res.type === 'survey/fetchMilgoSurveyData/rejected') {
                            sendAnalyticsEvent({
                                eventKey: analyticEvents.NOT_FOUND,
                                params: { error: 'Got milgo ref data but failed to fetch survey data.' },
                            });

                            return;
                        }

                        sendAnalyticsEvent({
                            eventKey: analyticEvents.SURVEY_LOADED,
                        });
                    });

                    // 4. Milgo params will be handled by RecordHandling
                }
            }

            if (mode === modes.MILGO_PREVIW) {
                dispatch(fetchMilgoSurveyData());
            }
        }
    }, [env, mode, fetchMilgoRefStatus, milgoRefData, dispatch, sendAnalyticsEvent]);
    //#endregion

    // Get horizon survey data according to mode:
    useEffect(() => {
        if (env !== surveyEnv.HORIZON) return;

        switch (mode) {
            case modes.EDITING:
                const messageHandler = (event) => {
                    const type = event.data.type;

                    switch (type) {
                        case 'SURVEY_DATA':
                            const { surveyJson } = event.data;
                            dispatch(resetRecord());
                            dispatch(setCurrentBlock(null));
                            dispatch(setBlockPageIndex(0));
                            dispatch(resetHistory());
                            dispatch(editingSurveyDataReceived({ surveyJson }));

                            break;

                        default:
                            break;
                    }
                };

                window.addEventListener('message', messageHandler);
                window.parent.postMessage({ type: 'READY' }, '*');

                // clean up
                return () => window.removeEventListener('message', messageHandler);

            case modes.PREVIEW:
                if (fetchSurveyStatus === 'idle' && surveyId) {
                    dispatch(fetchSurvey());
                    dispatch(resetHistory());
                }
                break;

            case modes.VIEW_ONLY:
            case modes.LIVE:
                if (fetchSurveyStatus === 'idle') {
                    if (surveyId) {
                        dispatch(fetchSurvey()).then((response) => {
                            // In case fetchSurvey returned an error redirect to this address:
                            if (response.error) {
                                sendAnalyticsEvent({ eventKey: analyticEvents.NOT_FOUND });
                                window.location.href = redirectionUrl;
                                return;
                            }
                            sendAnalyticsEvent({
                                eventKey: analyticEvents.SURVEY_LOADED,
                            });
                        });
                        dispatch(resetHistory());
                    } else {
                        sendAnalyticsEvent({ eventKey: analyticEvents.NOT_FOUND });
                        window.location.href = redirectionUrl;
                    }
                }
                break;

            default:
                break;
        }
    }, [env, mode, fetchSurveyStatus, surveyId, dispatch]);

    // Extract url params:
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = {};

        for (const p of searchParams) {
            params[p[0]] = p[1];
        }

        if (Object.entries(params).length > 0) {
            dispatch(receivedUrlParams(params));

            if (params.read_debug) {
                // Run Preview mode with debug capabilities:
                dispatch(
                    runtimeConfigChanged({
                        readDebug: params.read_debug === 'true',
                    })
                );
            }

            if (params.page_preview_id) {
                // Page preview id will force navigation to the given page without waiting for the debug info to update:
                dispatch(
                    runtimeConfigChanged({
                        pagePreviewId: params.page_preview_id,
                    })
                );
            }
        }
    }, [dispatch]);

    // Extract survey variables:
    useEffect(() => {
        if (!initiatedVars) {
            const vars = getNested(surveyData, 'logic', 'variables');

            if (vars) {
                vars.forEach((varData) => {
                    const varName = varData.name;
                    let value = varData.value;

                    // If variable data exists and already restored from localStorage, skip assignment.
                    const existingVarInRecord = Object.keys(recordVariables).find((k) => k === varName);
                    if (existingVarInRecord) {
                        return;
                    }

                    // Find matching variables from url params and assign their values:
                    if (varData.type === 'url_param' || varData.type === 'datasource') {
                        Object.entries(urlParams).forEach(([paramName, paramValue]) => {
                            // Ignore periods and capital letters:
                            const p = paramName.replace('.', '').toLowerCase();
                            if (p === varName) {
                                value = paramValue;
                            }
                        });
                    }

                    dispatch(setVariable({ varName, value }));

                    // Submit initial variables:
                    dispatch(
                        addDataForSubmission({
                            inputType: 'variables',
                            key: varName,
                        })
                    );
                });

                setInitiatedVars(true);
            }
        }
    }, [surveyData, urlParams, recordVariables, initiatedVars, dispatch]);

    // Preload media:
    useMediaPreloader((result) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(result);
        }
    });

    return (
        <>
            {/** Force preload of Ploni font */}
            {fetchSurveyStatus === 'idle' && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            height: 0,
                            width: 0,
                            visibility: 'hidden',
                            fontFamily: 'Ploni',
                            fontWeight: 400,
                        }}
                    >
                        abc
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            height: 0,
                            width: 0,
                            visibility: 'hidden',
                            fontFamily: 'Ploni',
                            fontWeight: 500,
                        }}
                    >
                        abc
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            height: 0,
                            width: 0,
                            visibility: 'hidden',
                            fontFamily: 'Ploni',
                            fontWeight: 600,
                        }}
                    >
                        abc
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            height: 0,
                            width: 0,
                            visibility: 'hidden',
                            fontFamily: 'Ploni',
                            fontWeight: 700,
                        }}
                    >
                        abc
                    </div>
                </>
            )}
        </>
    );
}
