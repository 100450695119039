import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageData, setNextPage } from 'features/survey/surveySlice';
import { addDataForSubmission, setUserAnswer, setVariable } from 'features/record/recordSlice';
import JsonLogic from 'json-logic-js';
import { getNested } from 'utils/miscHelpers';
import { getRawText } from 'components/RichTextRenderer';
import { OPEN_ITEM, pageTypes } from 'features/survey/surveyConfiguration';
import useUrlWithVars from 'utils/useUrlWithVars';

// Extended "in" operator to support comparison of to arrays.
// It's usefull when dealing with multiple choice questions where the user selected multiple answers.
JsonLogic.add_operation('in', (a, b) => {
    let result = false;

    if (Array.isArray(a) && Array.isArray(b)) {
        a.forEach((item) => {
            if (b.indexOf(item) !== -1) result = true;
        });
    } else {
        // Default JsonLogic functionality:
        if (!b || typeof b.indexOf === 'undefined') result = false;
        else result = b.indexOf(a) !== -1;
    }

    return result;
});

export function useOnPageEnter() {
    const execute = useActionsExecutor();

    const handler = useCallback(
        (pageData) => {
            const logicItems = getNested(pageData, 'logic', 'onPageEnter');
            execute(logicItems, null);
        },
        [execute]
    );

    return handler;
}

export function useOnPageExit() {
    const execute = useActionsExecutor();

    const handler = useCallback(
        (pageData) => {
            const logicItems = getNested(pageData, 'logic', 'onPageExit');
            execute(logicItems, 'next');
        },
        [execute]
    );

    return handler;
}

const useActionsExecutor = () => {
    const lang = useSelector((state) => state.user.lang);
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);
    const pageData = useSelector((state) => selectPageData(state, currentPage));

    const variablesData = useSelector((state) => getNested(state, 'survey', 'data', 'logic', 'variables'));
    const pagesData = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesData'));
    const logicItemsData = useSelector((state) => getNested(state, 'survey', 'data', 'logic', 'logicItemsData'));

    // Record Data:
    const variables = useSelector((state) => state.record.variables);
    const userAnswers = useSelector((state) => state.record.userAnswers);
    // const urlParams = useSelector( state => state.record.urlParams ); // @TODO: support conditions based on url parameters.

    const dispatch = useDispatch();
    const solve = useConditionSolver();

    const getUrlWithVars = useUrlWithVars();

    const [initiated, setInitiated] = useState(false);

    const [logicItems, setLogicItems] = useState([]);
    const [actionsOrder, setActionsOrder] = useState([]);
    const [actionsData, setActionsData] = useState({});

    const [navigateTo, setNavigateTo] = useState(null);
    const [stopIteration, setStopIteration] = useState(false);

    const shiftLogicItems = useCallback(() => {
        if (logicItems.length > 0) {
            const newLogicItems = Array.from(logicItems);
            newLogicItems.shift();

            setLogicItems(newLogicItems);
        }
    }, [logicItems]);

    const handleLogicItem = useCallback(
        (logicItemId) => {
            const logicData = logicItemsData[logicItemId];

            // STEP 3:
            // Solve condition:
            if (logicData && logicData.condition && solve(logicData.condition)) {
                // The condition is met, set action to execute:
                const actions = logicData.actions;
                if (actions && actions.order && actions.order.length) {
                    setActionsOrder(actions.order);
                    setActionsData(actions.data);
                }
            } else {
                // The condition is not met. Remove current item and move on:
                shiftLogicItems();
            }
        },
        [logicItemsData, shiftLogicItems, solve]
    );

    const getUserInputValue = useCallback(
        (arg) => {
            let argValue = '';
            const pageData = pagesData[arg.page];
            const userAns = userAnswers[pageData.key];
            if (userAns) {
                if (arg.inputType === 'answer') {
                    if (pageData.type === pageTypes.SCALE.key) {
                        // Find item key:
                        try {
                            let itemKey = '';
                            Object.values(pageData.itemsData).forEach((item) => {
                                if (item.id === arg.item) {
                                    itemKey = item.key;
                                }
                            });

                            const itemAns = userAns.value[itemKey];
                            if (itemAns) {
                                argValue = itemAns.key;
                            }
                        } catch (err) {
                            console.warn(err);
                        }
                    } else {
                        argValue = userAns.value;
                    }
                }
                if (arg.inputType === 'answer_text') {
                    try {
                        let answerText = '';
                        if (userAns.ids.length > 1) {
                            // Assign text of multiple answers
                            const allAnswers = [];
                            userAns.ids.forEach((itemId, i) => {
                                if (itemId === OPEN_ITEM) {
                                    allAnswers.push(userAns.openItemUserValue);
                                } else {
                                    const rawText = getRawText(pageData.itemsData[itemId].text[lang]);
                                    allAnswers.push(rawText);
                                }
                                answerText = allAnswers.toString();
                            });
                        } else {
                            // Assign text of a single answer:
                            const itemId = userAns.ids[0];
                            if (itemId === OPEN_ITEM) {
                                answerText = userAns.openItemUserValue;
                            } else {
                                answerText = pageData.itemsData[itemId].text[lang];
                                answerText = getRawText(answerText);
                            }
                        }

                        argValue = answerText;
                    } catch (error) {
                        console.warn("Error while trying to assign answer's text to variable. Details:", error);
                        argValue = userAns.value;
                    }
                }
                if (arg.inputType === 'time_span') argValue = (userAns.exitTime - userAns.enterTime) / 1000;
            }
            return argValue;
        },
        [pagesData, userAnswers, lang]
    );

    const handleAction = useCallback(
        (action) => {
            if (action) {
                switch (action.type) {
                    case 'stopAndGoto':
                        setNavigateTo(action.page);
                        setStopIteration(true);
                        break;

                    case 'setNextPage':
                        setNavigateTo(action.page);
                        break;

                    case 'stopAndSkipPage':
                        // Store exit time for current page, because we're moving out of it:
                        dispatch(
                            setUserAnswer({
                                key: pageData.key,
                                ansData: {
                                    exitTime: Date.now(),
                                },
                            })
                        );

                        if (action.gotoOption === 'nextInOrder') {
                            setNavigateTo('next');
                        }
                        if (action.gotoOption === 'targetPage') {
                            setNavigateTo(action.page);
                        }
                        setStopIteration(true);
                        break;

                    case 'setVar':
                        const { variable, operator, arg } = action;
                        const varData = variablesData.find((v) => v.id === variable);
                        if (varData && arg) {
                            const varName = varData.name;
                            let value = variables[varName];
                            let argValue = '';

                            // console.log( arg, varData )
                            if (arg.type === 'constant') {
                                argValue = arg.value;
                                if (arg.variableType === 'number') {
                                    value = parseFloat(value);
                                    argValue = parseFloat(argValue);
                                }
                            }

                            if (arg.type === 'surveyVariables') {
                                const varData = variablesData.find((v) => v.id === arg.variable);
                                if (varData) {
                                    const varName = varData.name;
                                    argValue = variables[varName];
                                }
                            }

                            if (arg.type === 'userInput') {
                                const userInput = getUserInputValue(arg);
                                if (Array.isArray(userInput)) {
                                    //In case the value is array (i.e MULTIPLE_CHOICE) set var as a string:
                                    argValue = userInput.join(',');
                                } else {
                                    argValue = userInput;
                                }
                            }

                            if (arg.type === 'random') {
                                value = parseFloat(value);
                                argValue = Math.random();
                            }

                            switch (operator) {
                                case '=':
                                    value = argValue;
                                    break;
                                case '+=':
                                    value += argValue;
                                    break;
                                case '-=':
                                    value -= argValue;
                                    break;
                                case '*=':
                                    value *= argValue;
                                    break;
                                case '/=':
                                    value /= argValue;
                                    break;
                                case '%=':
                                    value %= argValue;
                                    break;
                                case '**=':
                                    value **= argValue;
                                    break;
                                default:
                                    break;
                            }

                            // console.log(value);
                            dispatch(setVariable({ varName, value }));
                            dispatch(
                                addDataForSubmission({
                                    inputType: 'variables',
                                    key: varName,
                                })
                            );
                        }
                        break;

                    case 'redirectToUrl':
                        let url = getUrlWithVars(action.url);
                        window.open(url, action.openInNewTab ? '' : '_self');
                        break;

                    case 'consoleLog':
                        if (action.arg) {
                            const arg = action.arg;
                            let value = undefined;
                            let varName = '';

                            if (arg.type === 'surveyVariables') {
                                const varData = variablesData.find((v) => v.id === arg.variable);
                                if (varData) {
                                    varName = varData.name;
                                    value = variables[varName];
                                }
                            }
                            if (arg.type === 'userInput') {
                                value = getUserInputValue(arg);
                            }
                            if (arg.type === 'constant') {
                                value = arg.value;
                            }
                            if (arg.type === 'random') {
                                value = Math.random();
                            }

                            console.log(
                                `%c${varName} = ${value}`,
                                'background-color: #D6E0FF; font-size: 18px; padding: 4px 8px; border-radius: 4px'
                            );
                            // @TODO: print message in editor debug panel
                        }
                        break;

                    default:
                        break;
                }
            }
        },
        [dispatch, getUserInputValue, variablesData, getUrlWithVars, variables, pageData /* userAnswers */]
    );

    // Handle logic items:
    useEffect(() => {
        // STEP 2:
        if (initiated && logicItems.length > 0 && actionsOrder.length === 0) {
            // If there are logicItems waiting to be handled, and no actions are currently being executed, handle next logic item:
            handleLogicItem(logicItems[0]);
        }

        if (initiated && logicItems.length === 0 && actionsOrder.length === 0) {
            // When there are no more logic items to handle, finish:

            if (navigateTo) {
                // Navigate:
                dispatch(setNextPage(navigateTo));
                setNavigateTo(null);
            }

            // Reset state:
            setActionsData({});
            setStopIteration(false);
            setInitiated(false);
        }
    }, [initiated, logicItems, handleLogicItem, navigateTo, actionsOrder, stopIteration, dispatch]);

    // Handle actions:
    useEffect(() => {
        if (actionsOrder.length > 0 && !stopIteration) {
            // STEP 4:
            handleAction(actionsData[actionsOrder[0]]);

            const newArray = Array.from(actionsOrder);
            newArray.shift();

            setActionsOrder(newArray);
        } else if (initiated) {
            // All actions have been executed. Remove current logic item and move on:
            shiftLogicItems();
        }
    }, [actionsOrder, handleAction, actionsData, logicItems, stopIteration, shiftLogicItems, initiated]);

    // Handle stop iteration.
    // Some actions like 'stopAndSkip' are set to stop the execution of all following actions.
    // When 'stopIteration' is set to true, all existing logic items and actions should be disposed.
    useEffect(() => {
        if (stopIteration) {
            setActionsOrder([]);
            setLogicItems([]);
        }
    }, [stopIteration]);

    // STEP 1: Pass the logic items of the current phase, and default navigation value (relevant only for onPageExit ):
    const executor = useCallback(
        (logicItems, defaultNavigation) => {
            if (logicItems && logicItems.length > 0 && logicItemsData) {
                // Set navigation items to last:

                const navItems = [];
                const normalItems = [];
                logicItems.forEach((id) => {
                    if (logicItemsData[id].isNavigationLogic) {
                        navItems.push(id);
                    } else {
                        normalItems.push(id);
                    }
                });

                const items = normalItems.concat(navItems);

                setLogicItems(items);
                setInitiated(true);
            } else if (defaultNavigation) {
                dispatch(setNextPage(defaultNavigation));
            }

            if (defaultNavigation) {
                setNavigateTo(defaultNavigation);
            }
        },
        [logicItemsData, dispatch]
    );

    return executor;
};

/**
 *
 * @param {*} condition
 * @returns true / false
 */
export function useConditionSolver() {
    const lang = useSelector((state) => state.user.lang);

    const variablesData = useSelector((state) => getNested(state, 'survey', 'data', 'logic', 'variables'));
    const pagesData = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesData'));

    // Record Data:
    const variables = useSelector((state) => state.record.variables);
    const userAnswers = useSelector((state) => state.record.userAnswers);
    // const urlParams = useSelector( state => state.record.urlParams ); // @TODO: support conditions based on url parameters.

    const solver = useCallback(
        (condition) => {
            if (!condition) return false;
            if (condition.isAlways) return true;

            let failed = false;
            // console.log( condition );
            const argsData = {};
            Object.entries(condition.args).forEach(([key, data], i) => {
                // console.log( key, data)
                let value = '';

                switch (data.type) {
                    case 'surveyVariables':
                        const varId = data.variable;
                        const varData = variablesData.find((v) => v.id === varId);
                        if (varData) {
                            const varKey = varData.name;
                            const varValue = variables[varKey];
                            if (varValue) {
                                if (varData.type === 'number') {
                                    value = parseFloat(varValue);
                                } else {
                                    value = varValue;
                                }
                            }
                        }
                        break;
                    case 'userInput':
                        const pageData = pagesData[data.page];
                        if (!pageData) {
                            failed = true;
                            return;
                        }
                        const pageAnsData = userAnswers[pageData.key];

                        if (!pageAnsData) {
                            failed = true;
                            return;
                        }

                        if (data.inputType === 'answer') {
                            value = pageAnsData.value;

                            // When answer include items, spread the array and replace key with id:
                            if (Array.isArray(value) && pageAnsData.ids) {
                                if (pageAnsData.ids.length === 1) value = pageAnsData.ids[0];
                                else if (pageAnsData.ids.length > 1) value = pageAnsData.ids;
                            }

                            // Handle scale page answer data:
                            if (pageData.type === pageTypes.SCALE.key) {
                                // Find item key:
                                try {
                                    let itemKey = '';
                                    Object.values(pageData.itemsData).forEach((item) => {
                                        if (item.id === data.item) {
                                            itemKey = item.key;
                                        }
                                    });

                                    const itemAnsKey = value[itemKey].key;
                                    value = itemAnsKey;
                                } catch (err) {
                                    console.warn(err);
                                }
                            }
                        }

                        if (data.inputType === 'time_span') {
                            value = (pageAnsData.exitTime - pageAnsData.enterTime) / 1000;
                        }

                        if (data.inputType === 'answer_text') {
                            try {
                                const itemId = pageAnsData.ids[0];
                                const itemText = pageData.itemsData[itemId].text[lang];
                                value = getRawText(itemText);
                            } catch (error) {
                                console.warn("Error while trying to assign answer's text to variable. Details:", error);
                                value = pageAnsData.value;
                            }
                        }

                        if (data.inputType === 'answer_index') {
                            value = pageAnsData.value;

                            // Handle scale page answer index:
                            if (pageData.type === pageTypes.SCALE.key) {
                                // Find item key:
                                try {
                                    let itemKey = '';
                                    Object.values(pageData.itemsData).forEach((item) => {
                                        if (item.id === data.item) {
                                            itemKey = item.key;
                                        }
                                    });

                                    const itemAnsKey = value[itemKey].key;
                                    let inx = pageData.scaleData.findIndex((v) => v.key === itemAnsKey);
                                    // If the choosen answer is not found in the scale data, 'findIndex' will return -1, which matches the secondary button index.
                                    // otherwise, increase inx by 1 to match the key that is visible to the users i.e 0:'scale_1' => 1:'scale_1'
                                    if (inx > -1) {
                                        inx++;
                                    }
                                    value = inx;
                                } catch (err) {
                                    console.warn(err);
                                }
                            }
                        }

                        break;

                    case 'constant':
                        switch (data.variableType) {
                            case 'number':
                                value = parseFloat(data.value);
                                break;
                            case 'string':
                                value = data.value;
                                break;
                            case 'boolean':
                                value = data.value;
                                break;
                            case 'array':
                                value = data.value;
                                break;
                            case 'url_param':
                                value = data.value;
                                break;
                            case 'datasource':
                                value = data.value;
                                break;
                            default:
                                break;
                        }
                        break;

                    case 'random':
                        value = Math.random();
                        break;

                    default:
                        break;
                }

                argsData[key] = value;
            });

            const result = !failed && JsonLogic.apply(condition.rules, argsData);

            return result;
        },
        [lang, /* urlParams, */ variables, userAnswers, variablesData, pagesData]
    );

    return solver;
}
