import React, { useMemo, useRef } from 'react';
import styles from './PopupTextInput.module.css';
import Modal from './Modal';
import TextButton from './TextButton';
import { TextInput } from './TextInput';
import { motion, AnimatePresence } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useSelector } from 'react-redux';
import { pageTexts } from 'utils/appTexts';


/**
 * Text area component (multiline)
 * @param {{
 * isActive
 * placeholder
 * value
 * onChange
 * isValid
 * onDone
 * disabled
 * isFullscreen
 * wrapperStyle // style of the inline cont
 * wrapperClassName // class name of the inline cont
 * }} params 
 */
export default function PopupTextInput ({
    isActive, 
    placeholder, 
    value, 
    onChange, 
    isValid, 
    onDone, 
    disabled,
    isFullscreen,
    wrapperStyle,
    wrapperClassName
}) {

    const lang = useSelector( state => state.user.lang );
    const inputRef = useRef();
    const direction = useSelector( state => state.survey.display.direction );

    const Wrapper = useMemo( () => {
        return isFullscreen ? FullscreenWrapper : InlineWrapper
    }, [isFullscreen])

    return (
        <Wrapper 
            isActive={ isActive } 
            onClose={ onDone }
            style={ wrapperStyle }
            className={ wrapperClassName }
        >          
            <div 
                className={ styles.inputCont}
                style={{
                    padding: isFullscreen ? 'var(--space-12)' : 0
                }}
            >
                <motion.div 
                    style={{minHeight: 'fit-content'}}
                    initial={ {y:20, opacity: 0} }
                    animate={ {y:0, opacity: 1} }
                    transition={{ duration: 0.4, ease: easing.outQuart }}
                >
                    <TextInput
                        ref={ inputRef }
                        placeholder={ placeholder }
                        value={ value || "" }
                        onChange={ onChange }
                        autoFocus={ true }
                        dir= {direction} 
                        disabled={ disabled }
                        onEnter={ onDone }
                        onBlur={ onDone }
                    />
                </motion.div>
                <div className={ styles.inputFooter}>
                    <motion.div 
                        initial={ {y:30, opacity:0} }
                        animate={ isValid ? {y:0, opacity:1} : {y:30, opacity:0} }
                        transition={{ duration: 0.4, ease: easing.outQuart, delay: 0.4 }}
                    >
                        <TextButton
                            label={ pageTexts.confirmBtn[ lang ] }
                            className={ styles.continueBtn }
                            iconBefore={ 'check'}
                            disabled={ !isValid }
                            onClick={ onDone }
                        />
                    </motion.div>
                </div>
            </div>
        </Wrapper>

    );
}

const wrapperMotionVarients = {
    hidden: {
        opacity: 0,
        transition: { 
            default: { duration: 0.3, ease: easing.outQuart }
        }
    },
    visible: {
        opacity: 1,
        transition: { 
            default: { duration: 0.2, ease: easing.outQuart }
        }
    }
}

const InlineWrapper = ({onDone, isActive, style, className, children}) => {
    return (
        <AnimatePresence initial={false}>
            { isActive &&
                <motion.div 
                    style={ style } 
                    className={ className }

                    variants={ wrapperMotionVarients }
                    initial='hidden'
                    animate='visible'
                    exit='hidden'   
                >
                    { children }
                </motion.div>
            }
        </AnimatePresence>
    )
}

const FullscreenWrapper = ({onDone, isActive, children}) => {

    return (
        <Modal 
            isActive={ isActive } 
            style={{
                background: `linear-gradient(0deg, var( --color-mono-1 ) 0%, transparent 300%)`
            }}
            onClose={ onDone }
            motionVarients={ wrapperMotionVarients }
        > 
            { children }
        </Modal>
    )
}

