import React, { useMemo, useRef } from 'react';
import styles from './DesktopSidePanel.module.css';
import GradientPanel from 'components/GradientPanel';
import ProgressBar from 'components/ProgressBar';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { easing } from 'utils/animationEasing';
import { pageLayoutModes, pageTypes } from 'features/survey/surveyConfiguration';
import { getNested } from 'utils/miscHelpers';
import Text from 'components/Text';
import { selectPageData } from 'features/survey/surveySlice';

const contentVatients = {
    hidden: { y: 40, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.5, ease: easing.outQuart },
    },
    exit: {
        y: -40,
        opacity: 0,
        transition: { duration: 0.3, ease: easing.inQuart },
    },
};

const panelContVarients = {
    collapsed: {
        y: '100vh',
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: easing.inOutQuart,
        },
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            delay: 0.1,
            ease: easing.inOutQuart,
        },
    },
};

export default function DesktopSidePanel({ id }) {
    const panelRef = useRef();
    const lang = useSelector((state) => state.user.lang);
    const data = useSelector((state) => selectPageData(state, id));

    const showSubtitle = useMemo(() => {
        return getNested(data, 'settings', 'general', 'showSubtitle');
    }, [data]);

    const hideProgressbar = useSelector((state) => {
        return state.survey.data?.settings.general.hideProgressbar;
    });

    const collapsed = useMemo(() => {
        if (data) {
            const mode = pageTypes[data.type].mode;
            return mode === pageLayoutModes.FULL_VIEW;
        } else return true;
    }, [data]);

    // if( !data ) return null;

    return (
        <motion.header
            ref={panelRef}
            className={styles.panel}
            initial={'collapsed'}
            variants={panelContVarients}
            animate={collapsed ? 'collapsed' : 'visible'}
        >
            <GradientPanel className={styles.gradienPanel} />
            <AnimatePresence exitBeforeEnter>
                {data && !collapsed && (
                    <motion.div
                        key={id}
                        className={styles.container}
                        variants={contentVatients}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        tabIndex={-1}
                    >
                        <Text tagType="legend" className={styles.title}>
                            {data.title[lang]}
                        </Text>
                        {showSubtitle && data.subtitle && (
                            <Text tagType="p" className={styles.subtitle}>
                                {data.subtitle[lang]}
                            </Text>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>

            {!hideProgressbar && (
                <ProgressBar
                    className={styles.progressCont}
                    height={12}
                    style={{
                        opacity: collapsed ? 0 : 1,
                    }}
                />
            )}
        </motion.header>
    );
}
