import React, { useMemo, useState } from 'react';
import styles from './shared/Button.module.css';
import Button, { themes } from './shared/Button';
import Icon from './Icon';

/**
 * @param {{
 * name {string} // Icon name
 * className
 * style
 * size {number}
 * disabled {boolean}
 * onClick
 * tabIndex
 * }} params
 */
const IconButton = React.forwardRef(
    ({ name, theme = 'plain', className, style, size, disabled, onClick, tabIndex, ariaLabel }, ref) => {
        const [buttonState, setButtonState] = useState('normal'); // 'normal' | 'hovered' | 'focused' | 'active'

        const overlayColor = useMemo(() => {
            return themes[theme][buttonState].overlay;
        }, [theme, buttonState]);

        return (
            <Button
                ref={ref}
                name={name}
                ariaLabel={ariaLabel}
                className={`${className} ${styles.iconButton}`}
                style={style}
                theme={theme}
                tabIndex={tabIndex}
                disabled={disabled}
                onClick={onClick}
                onStateChange={(s) => setButtonState(s)}
            >
                <Icon name={name} size={size} color={overlayColor} />
            </Button>
        );
    }
);

export default IconButton;
