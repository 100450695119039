import React from 'react';

const defaultBlockComponent = (blockType) =>
    ({
        unstyled: 'p',
        paragraph: 'p',
        'header-one': 'h1',
        'header-two': 'h2',
        'header-three': 'h3',
        'header-four': 'h4',
        'header-five': 'h5',
        'header-six': 'h6',
        'unordered-list-item': ['li', 'ul'],
        'ordered-list-item': ['li', 'ol'],
        blockquote: 'blockquote',
        'code-block': 'pre',
        legend: 'legend',
        atomic: 'div',
    }[blockType] || 'p');

export const styleNodesMap = {};

const defaultStyleComponent = (style) =>
    ({
        BOLD: 'b',
        ITALIC: 'i',
        UNDERLINE: 'u',
        STRIKETHROUGH: 's',
        CODE: 'code',
    }[style] || 'span');

const defaultEntityComponent = (entityType) =>
    ({
        IMAGE: ({ data }) => <img {...data} alt={data.alt} />,
        LINK: ({ data, children }) => (
            <a href={data.url} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        ),
    }[entityType]);

const parseBlockComponent = (component) => {
    if (typeof component === 'object' && component instanceof Array) {
        return component;
    }
    return [component, null];
};

const RichTextRenderer = ({
    className,
    style,
    data: { blocks, entityMap },
    getBlockComponent = defaultBlockComponent,
    getStyleComponent = defaultStyleComponent,
    getEntityComponent = defaultEntityComponent,
    getInlineStyleProps = () => ({}),
    textComponent = ({ children }) => children,
}) => {
    let blockList = [];
    let blocksDepthStack = [];

    blocks = fixEmojiIndexes(blocks);

    for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];

        if (block.depth > 0) {
            blocksDepthStack[block.depth - 1].children = blocksDepthStack[block.depth - 1].children || [];
            blocksDepthStack[block.depth - 1].children.push(block);
        } else {
            blockList.push(block);
        }
        blocksDepthStack[block.depth] = block;
        blocksDepthStack.splice(block.depth + 1);
    }
    const renderArray = (array) => {
        let newArray = [];
        let innerArray = [];
        for (let i = 0; i < array.length; i++) {
            let block = array[i];
            if (innerArray.length > 0 && innerArray[0].type === block.type) {
                innerArray.push(block);
            } else {
                innerArray = [block];
                newArray.push(innerArray);
            }
        }
        return newArray.map((group) =>
            React.createElement(
                parseBlockComponent(getBlockComponent(group[0].type))[1] || React.Fragment,
                { key: group[0].key },
                group.map((block) =>
                    React.createElement(
                        parseBlockComponent(getBlockComponent(block.type))[0],
                        { key: block.key, data: block.data, style, className },
                        block.children ? [renderRichText(block), ...renderArray(block.children)] : renderRichText(block)
                    )
                )
            )
        );
    };

    const renderRichText = (block) => {
        let indexes = [0];
        // console.log(JSON.stringify(blocks, null, 4));

        block.entityRanges.forEach((entity) => {
            indexes.push(entity.offset);
            indexes.push(entity.offset + entity.length);
        });
        block.inlineStyleRanges.forEach((style) => {
            indexes.push(style.offset);
            indexes.push(style.offset + style.length);
        });
        indexes.sort((a, b) => a - b);

        indexes = indexes.filter((v, i) => indexes.indexOf(v) === i && v !== block.text.length);

        let ranges = indexes
            .map((index, i) => ({
                start: index,
                end: indexes[i + 1] || block.text.length,
            }))
            .map(({ start, end }) => ({
                start,
                end,
                text: block.text.substring(start, end),
                styles: block.inlineStyleRanges
                    .filter((style) => style.offset <= start && style.offset + style.length >= end)
                    .map((style) => style.style),
                entities: block.entityRanges
                    .filter((entity) => entity.offset <= start && entity.offset + entity.length >= end)
                    .map((entity) => entityMap[entity.key]),
            }));

        const renderStyledText = (text, styles) => {
            let rendered = text;
            for (let i = 0; i < styles.length; i++) {
                rendered = React.createElement(
                    getStyleComponent(styles[i]),
                    { style: getInlineStyleProps(styles[i]) },
                    rendered
                );
            }
            return rendered;
        };

        const renderEntities = (text, entities) => {
            let rendered = text;
            for (let i = 0; i < entities.length; i++) {
                rendered = React.createElement(
                    getEntityComponent(entities[i].type),
                    { data: entities[i].data },
                    rendered
                );
            }
            return rendered;
        };

        return ranges.map((range, index) =>
            React.cloneElement(
                renderEntities(
                    renderStyledText(React.createElement(textComponent, {}, range.text), range.styles),
                    range.entities
                ),
                { key: index }
            )
        );
    };

    return renderArray(blockList);
};

export default RichTextRenderer;

export const isRichText = (value) => typeof value === 'object' && value.blocks && value.entityMap;

export const getRawText = (value) => {
    if (!isRichText(value)) return value;
    let txt = '';
    for (let i = 0; i < value.blocks.length; i++) {
        txt += value.blocks[i].text;
    }
    return txt;
};

//#region Emoji Fix
// Based on this discussion: https://github.com/jpuri/draftjs-to-html/issues/41#issuecomment-775625824
function inRange(number, start, end) {
    function baseInRange(number, start, end) {
        return number >= Math.min(start, end) && number < Math.max(start, end);
    }
    if (end === undefined) {
        end = start;
        start = 0;
    }
    return baseInRange(+number, +start, +end);
}

const calculateEmojiNumberWithinRange = (emojiInfo, start, end) => {
    // calculate how many emoji appear in a certain range
    let counter = 0;
    emojiInfo.forEach((info) => {
        if (inRange(info.pos, start, end)) {
            counter += info.length - 1;
        }
    });
    return counter;
};

const calculateNewOffsetLength = (emojiInfo, range) => {
    // For new offset, the value should be original value + how many Emoji appeared from 0 to offset
    // For new length,
    // the value should be original length + how many Emoji appeared from offset to offset + length
    const newOffset = range.offset + calculateEmojiNumberWithinRange(emojiInfo, 0, range.offset);
    const newLength =
        range.length + calculateEmojiNumberWithinRange(emojiInfo, range.offset, range.offset + range.length);
    const newRange = { offset: newOffset, length: newLength };

    // inlineStyleRanges:
    if (range.hasOwnProperty('style')) newRange['style'] = range.style;

    // entityRanges:
    if (range.hasOwnProperty('key')) newRange['key'] = range.key;

    return newRange;
};

const findEmojiPositionAndLength = (text) => {
    const regex = /\p{Emoji_Presentation}/gu;
    let resultArray = [];
    const returnArray = [];
    let minusCount = 0;
    while ((resultArray = regex.exec(text)) !== null) {
        // If target character is Emoji, push it's index to the returnArray
        // As Emoji is count as 2 index here but in draftjs-to-html it count as 1,
        // therefore need to reduce it's index by how many Emoji appeared before
        const length = resultArray[0].length;
        returnArray.push({ pos: resultArray.index - minusCount, length });
        minusCount += length - 1;
    }
    return returnArray;
};

const handleEmojiExtraIndex = (entry) => {
    // get the Array of position where the Emoji exist
    const emojiInfo = findEmojiPositionAndLength(entry.text);
    let { inlineStyleRanges } = entry;
    inlineStyleRanges = inlineStyleRanges.map((range) => {
        // modify all the inlineStyleRanges offset and length one by one
        return calculateNewOffsetLength(emojiInfo, range);
    });
    let { entityRanges } = entry;
    entityRanges = entityRanges.map((range) => {
        // modify all the entityRanges offset and length one by one
        return calculateNewOffsetLength(emojiInfo, range);
    });
    return { ...entry, inlineStyleRanges, entityRanges };
};

const fixEmojiIndexes = (blocks) => {
    // Loop all the rawState blocks first
    return blocks.map((entry) => {
        return handleEmojiExtraIndex(entry);
    });
};

//#endregion
