import React, { useCallback, useMemo } from 'react';
import RichTextRenderer, { isRichText } from './RichTextRenderer';
import DynamicText from './DynamicText';
import LinkText from './LinkText';
import { useSelector } from 'react-redux';
import { textColors } from 'features/survey/surveyConfiguration';

const textComponents = {
    p: (props) => <p {...props}>{props.children}</p>,
    h1: (props) => <h1 {...props}>{props.children}</h1>,
    h2: (props) => <h2 {...props}>{props.children}</h2>,
    h3: (props) => <h3 {...props}>{props.children}</h3>,
    h4: (props) => <h4 {...props}>{props.children}</h4>,
    h5: (props) => <h5 {...props}>{props.children}</h5>,
    legend: (props) => <legend {...props}>{props.children}</legend>,
};

const EntityComponent = (entityType) =>
    ({
        IMAGE: ({ data }) => <img {...data} alt={data.alt} />,
        LINK: LinkText,
        DYNAMIC_TEXT: DynamicText,
    }[entityType]);

/**
 * Render string as html element or rich text
 * @param {{
 *  className
 *  style
 *  tagType // i.e 'p' | 'h1' | 'h2' | ...
 *  children // Either a simple string or RichText raw data { blocks, entityMap }
 * }} params
 */
export default function Text({ className, style, tagType = 'p', children }) {
    const theme = useSelector((state) => state.survey.display.theme);
    const getInlineStyle = useCallback(
        (styleKey) => {
            // red: { color: '#ff0000' } }[styleKey] || {}
            const colors = {};
            Object.entries(textColors).forEach(([key, value]) => {
                colors[key] = { color: value[theme] };
            });

            const styles = {
                // TBD: other styles...
                ...colors,
            };
            return styles[styleKey] || {};
        },
        [theme]
    );

    const Block = useMemo(() => textComponents[tagType], [tagType]);
    const content = useMemo(() => {
        if (typeof children === 'string')
            return (
                <Block className={className} style={style}>
                    {' '}
                    {children}{' '}
                </Block>
            );

        if (isRichText(children)) {
            return (
                <div>
                    <RichTextRenderer
                        className={className}
                        style={style}
                        data={children}
                        getBlockComponent={(blockType) => tagType}
                        getEntityComponent={EntityComponent}
                        getInlineStyleProps={getInlineStyle}
                    />
                </div>
            );
        }

        return null;
    }, [children, tagType, className, style]);

    return <> {content} </>;
}
