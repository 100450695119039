import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './OpenEndedPageModule.module.css';
import TextButton from 'components/TextButton';
import Caption from 'components/Caption';
import FullscreenTextAreaInput from 'components/FullscreenTextAreaInput';
import TextAreaInput from 'components/TextAreaInput';
import { TextInput } from 'components/TextInput';
import DesktopContinueButton from 'features/survey/views/desktop/DesktopContinueButton';
import Media from 'components/media/Media';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { validateEmail } from 'utils/miscHelpers';
import { useHasExitTime } from 'utils/PageHelpersHooks';
import { pageTexts } from 'utils/appTexts';
import { useOnPageDone } from '../Page';
import { pageStateChanged, surveyModes } from 'features/survey/surveySlice';
import { useEscapeKey } from 'utils/keyboardHooks';
import { getRawText } from 'components/RichTextRenderer';

const texts = pageTexts.openEndedPage;

export default function OpenEndedPageModule({ data, lang, onSetAnswer }) {
    const dispatch = useDispatch();

    const platform = useSelector((state) => state.survey.display.layout.platform);
    const answers = useSelector((state) => state.record.userAnswers);
    const hasExitTime = useHasExitTime(data);

    const { allowRepeatedInput } = useMemo(() => {
        if (data) {
            return data.settings.general;
        }
        return {};
    }, [data]);

    const isLocked = useMemo(() => !allowRepeatedInput && hasExitTime, [allowRepeatedInput, hasExitTime]);

    const value = useMemo(() => {
        const current = answers[data.key];

        if (current && current.value) {
            return current.value;
        }

        return '';
    }, [data, answers]);

    const InputComp = useMemo(() => {
        switch (data.inputType) {
            case 'multiline':
                return MultilineInput;
            case 'text':
                return SingleLineInput;
            default:
                return SingleLineInput;
        }
    }, [data.inputType]);

    const handleChange = useCallback(
        (newValue) => {
            onSetAnswer({ value: newValue });
        },
        [onSetAnswer]
    );

    const handleValidation = useCallback(
        (isValid) => {
            dispatch(
                pageStateChanged({
                    property: 'isCurrentPageValid',
                    value: isValid,
                })
            );
        },
        [dispatch]
    );

    return (
        <div
            key={data.id}
            className={styles.mainCont}
            style={
                data.media
                    ? {
                          paddingTop: 'var(--space-32)',
                          justifyContent: 'flex-start',
                      }
                    : {}
            }
        >
            <Media
                fileId={data.media}
                settings={data.settings.media}
                style={
                    platform === 'desktop'
                        ? {
                              marginTop: 'calc( -1 * var(--space-32))',
                              marginRight: 0,
                              marginLeft: 0,
                          }
                        : {}
                }
                motionProps={{
                    initial: { opacity: 0 },
                    animate: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.5 },
                    },
                }}
            />
            <motion.div
                initial={{ y: 80, opacity: 0, scale: 0.8 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    ease: easing.inOutQuart,
                    delay: 0.6,
                }}
            >
                <InputComp
                    data={data}
                    value={value}
                    platform={platform}
                    lang={lang}
                    onChange={handleChange}
                    onValidation={handleValidation}
                    isLocked={isLocked}
                />
            </motion.div>
        </div>
    );
}

const MultilineInput = ({ lang, platform, data, value, onChange, onValidation, isLocked }) => {
    const [captionState, setCaptionState] = useState('tip');
    const [captionText, setCaptionText] = useState(texts.caption.tip[lang]);

    const [isValid, setIsValid] = useState(false);

    const PlatformComp = useMemo(() => {
        switch (platform) {
            case 'mobile':
                return MultilineMobile;
            case 'desktop':
                return MultilineDesktop;
            default:
                return MultilineMobile;
        }
    }, [platform]);

    // Handle validation
    useEffect(() => {
        const isRequiredLength = value.length >= data.minCharacters;
        if (value.length > 0 && !isRequiredLength) {
            setCaptionState('warning');
            setCaptionText(texts.caption.minCharactersWarning[lang].replace('{c}', data.minCharacters));
        } else if (value.length > 0) {
            setCaptionState('success');
            setCaptionText('');
        } else {
            setCaptionState('tip');
            setCaptionText(texts.caption.tip[lang]);
        }

        setIsValid(isRequiredLength);
        onValidation(isRequiredLength);
    }, [lang, value, data, onValidation]);

    return (
        <PlatformComp
            lang={lang}
            data={data}
            value={value}
            onChange={onChange}
            captionState={captionState}
            captionText={captionText}
            isValid={isValid}
            isLocked={isLocked}
        />
    );
};

const MultilineMobile = ({ lang, data, value, onChange, isValid, captionState, captionText, isLocked }) => {
    const onDone = useOnPageDone();
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);

    const handleHideFooter = useCallback(
        (isHidden) => {
            dispatch(
                pageStateChanged({
                    property: 'isFooterHidden',
                    value: isHidden,
                })
            );
        },
        [dispatch]
    );

    useEscapeKey(() => setIsEditing(false));

    useEffect(() => {
        handleHideFooter(isEditing);
    }, [isEditing, handleHideFooter]);

    return (
        <>
            <div
                role="textbox"
                className={styles.textAreaPreviewCont}
                onClick={() => setIsEditing(true)}
                onKeyDown={(e) => {
                    if (['Enter', ' '].includes(e.key)) setIsEditing(true);
                }}
                style={{
                    color: value ? 'var( --color-mono-5 )' : 'var( --color-mono-4 )',
                }}
                data-disabled={isLocked}
                tabIndex={0}
            >
                {value || data.placeholder[lang]}
            </div>
            <Caption state={captionState} text={captionText} />
            <FullscreenTextAreaInput
                isActive={isEditing}
                onClose={() => setIsEditing(false)}
                placeholder={data.placeholder[lang]}
                value={value}
                onChange={onChange}
                isValid={isValid}
                onFocusOut={() => setIsEditing(false)}
                onNext={onDone}
                disabled={isLocked}
                title={data.title}
            />
        </>
    );
};

const MultilineDesktop = ({ lang, value, data, onChange, captionState, captionText, isLocked }) => {
    return (
        <>
            <TextAreaInput
                className={styles.textAreaDesktop}
                value={value}
                placeholder={data.placeholder[lang]}
                onChange={onChange}
                disabled={isLocked}
                autoFocus={parseInt(data.minCharacters) > 0}
            />
            <div className={styles.inputBottomCont}>
                <Caption state={captionState} text={captionText} />
                <DesktopContinueButton style={{ paddingTop: 0 }} />
            </div>
        </>
    );
};

const SingleLineInput = ({ lang, platform, data, value, onChange, onValidation, isLocked }) => {
    const dispatch = useDispatch();
    const onDone = useOnPageDone();

    const inputRef = useRef();
    const direction = useSelector((state) => state.survey.display.direction);
    const [isFocused, setIsFocused] = useState(false);
    const mode = useSelector((state) => state.survey.runtimeConfig.mode);

    const [captionState, setCaptionState] = useState('tip');
    const [captionText, setCaptionText] = useState(texts.caption.tip[lang]);

    const [isValid, setIsValid] = useState(false);

    const handleHideFooter = useCallback(
        (isHidden) => {
            dispatch(
                pageStateChanged({
                    property: 'isFooterHidden',
                    value: isHidden,
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        const minChars = parseInt(data.minCharacters);
        const isRequiredLength = value.length >= minChars;

        if (value.length > 0 && !isRequiredLength) {
            setCaptionState('warning');
            setCaptionText(texts.caption.minCharactersWarning[lang].replace('{c}', data.minCharacters));
        } else {
            setCaptionState('tip');
            setCaptionText(texts.caption.tip[lang]);
        }

        let valid = isRequiredLength;

        if (data.inputType === 'email') {
            setCaptionText(texts.caption.emailTip[lang]);

            const isEmailValid = validateEmail(value);
            valid &= isEmailValid;

            if (value.length > 0 && !isEmailValid) {
                setCaptionState('warning');
            }
        }

        if (value.length > 0 && valid) {
            setCaptionState('success');
            setCaptionText('');
        }

        handleHideFooter(isFocused || !isRequiredLength);

        setIsValid(valid);
        onValidation(valid);
    }, [lang, value, data, onValidation, isFocused, handleHideFooter]);

    // useEffect(() => {
    //     // Delaying the focus on the input to let accessibility screen readers read the page title first.
    //     setTimeout(() => {
    //         if (parseInt(data.minCharacters) > 0 && mode !== surveyModes.EDITING) inputRef.current?.focus();
    //     }, 300);
    // }, [data]);

    const handleEnter = useCallback(() => {
        if (isValid) {
            onDone();
        } else {
            inputRef.current.blur();
        }
    }, [isValid, onDone, inputRef]);

    const handleFocus = useCallback(
        (focused) => {
            setIsFocused(focused);
            handleHideFooter(focused);
        },
        [handleHideFooter]
    );

    return (
        <>
            <TextInput
                ref={inputRef}
                type={data.inputType}
                dir={direction}
                placeholder={data.placeholder[lang]}
                value={value}
                onChange={onChange}
                onEnter={handleEnter}
                onFocus={() => handleFocus(true)}
                onBlur={() => handleFocus(false)}
                disabled={isLocked}
                ariaLabel={getRawText(data.title[lang])}
            />
            <div className={styles.inputBottomCont}>
                <Caption state={captionState} text={captionText} />
                {platform === 'mobile' && (
                    <motion.div
                        initial={{ y: 30, opacity: 0 }}
                        animate={isValid && isFocused ? { y: 0, opacity: 1 } : { y: 30, opacity: 0 }}
                        transition={{ duration: 0.4, ease: easing.outQuart }}
                    >
                        <TextButton
                            label={pageTexts.continueBtn[lang]}
                            className={styles.continueBtn}
                            iconAfter={direction === 'rtl' ? 'chevron_left_arrow' : 'chevron_right_arrow'}
                            disabled={!isValid}
                            onPointerUp={onDone} // in a rare case where the viewport is scrolled, onClick event is not responding. onPointerUp always works
                        />
                    </motion.div>
                )}
                {platform === 'desktop' && <DesktopContinueButton style={{ paddingTop: 0 }} />}
            </div>
        </>
    );
};
