import React, { useState } from 'react';
import styles from './PreviewModeWarning.module.css';
import { useSelector } from 'react-redux';
import { surveyModes } from 'features/survey/surveySlice';
import { AnimatePresence, motion } from 'framer-motion';
import Button from 'components/shared/Button';
import { general } from 'utils/appTexts';

const dismissTime = 10;
const texts = general.previewModeWarning;

export default function PreviewModeWarning() {
    const lang = useSelector((state) => state.user.lang);
    const direction = useSelector((state) => state.survey.display.direction);
    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    const urlParams = useSelector((state) => state.record.urlParams);
    const data = useSelector((state) => state.survey.data);
    const [isActive, setIsActive] = useState(true);

    if (
        data &&
        [surveyModes.PREVIEW, surveyModes.VIEW_ONLY].includes(surveyMode) &&
        urlParams?.['hide_preview_warning'] !== 'true'
    ) {
        return (
            <div className={styles.root} aria-hidden="true">
                <AnimatePresence>
                    {isActive && (
                        <motion.div className={styles.cont} initial={{ y: -200 }} animate={{ y: 0 }} exit={{ y: -300 }}>
                            <motion.div
                                className={styles.timerFill}
                                style={{
                                    transformOrigin: direction === 'rtl' ? 'right' : 'left',
                                }}
                                initial={{ scaleX: 0 }}
                                animate={{ scaleX: 1 }}
                                transition={{
                                    duration: dismissTime,
                                    ease: 'linear',
                                }}
                                onAnimationComplete={() => {
                                    setIsActive(false);
                                }}
                            />
                            <span className={styles.message}>{texts.message[lang]}</span>
                            <Button className={styles.actionBtn} onClick={() => setIsActive(false)}>
                                {texts.gotIt[lang]}
                            </Button>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        );
    }

    return null;
}
