import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addDataForSubmission, setVariable } from "features/record/recordSlice";
import { useOnPageDone } from "features/survey/page/Page";

export const eventsTypes = {
    CLOSE_WEBVIEW: "closeWebview", // Relevant for WebView component
    SET_CONTINUE_BUTTON: "setContinueButton", // Relevant for ExternalPageModule. payload: { isVisible }
    NEXT_PAGE: "nextPage",
    SET_VAR: "setVar", // payload: { varName, value }
    SET_USER_ANSWER: "setUserAnswer", // Relevant for ExternalPageModule. payload: { key, value }
};

// Child window post message example:
// window.parent.postMessage( {
//     isHorizonClientWebviewEvent: true,
//     type:'setVar',
//     payload: {
//         varName: 'coin',
//         value: side
//     }
// }, '*' );

export default function ExternalEventsHandler({ onEvent }) {
    const dispatch = useDispatch();
    const onDone = useOnPageDone();

    const messageHandler = useCallback(
        (event) => {
            if (event.data.isHorizonClientWebviewEvent) {
                switch (event.data.type) {
                    case eventsTypes.CLOSE_WEBVIEW:
                        // Should be handled by WebView through 'onEvent' callback.
                        break;
                    case eventsTypes.SET_CONTINUE_BUTTON:
                        // Should be handled by ExternalPageModule through 'onEvent' callback.
                        break;
                    case eventsTypes.NEXT_PAGE:
                        onDone();
                        break;
                    case eventsTypes.SET_VAR:
                        try {
                            const { varName, value } = event.data.payload;
                            dispatch(setVariable({ varName, value }));
                            dispatch(
                                addDataForSubmission({
                                    inputType: "variables",
                                    key: varName,
                                })
                            );
                        } catch (error) {
                            console.warn(
                                "Could not set var from webview. Details: " +
                                    error
                            );
                        }
                        break;
                    case eventsTypes.SET_USER_ANSWER:
                        // Should be handled by ExternalPageModule through 'onEvent' callback.
                        break;
                    default:
                        break;
                }

                if (onEvent) {
                    onEvent(event.data);
                }
            }
        },
        [dispatch, onDone, onEvent]
    );

    useEffect(() => {
        window.addEventListener("message", messageHandler);

        // clean up
        return () => window.removeEventListener("message", messageHandler);
    }, [messageHandler]);

    return null;
}
