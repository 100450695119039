import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useMediaPreloader = (onLoad) => {
    const surveyData = useSelector((state) => state.survey.data);

    const [status, setStatus] = useState("idle");

    useEffect(() => {
        if (surveyData && status === "idle") {
            if (surveyData.media?.files?.length > 0) {
                const pagesData = surveyData.content.pagesData;
                const filesToLoad = [];
                Object.values(pagesData).forEach((data) => {
                    const fileId = data.media;
                    const file = surveyData.media.files.find(
                        (f) => f.id === fileId
                    );
                    if (file) filesToLoad.push(file);
                });

                setStatus("loading");
                cacheFiles(filesToLoad, (response) => {
                    setStatus("done");
                    if (onLoad) onLoad(response);
                });
            } else {
                setStatus("done");
                if (onLoad) onLoad("no media to load");
            }
        }
    }, [surveyData, onLoad]);
};

export const cacheFiles = async (filesArray, onLoad) => {
    const promises = await filesArray.map((file) => {
        return new Promise((resolve, reject) => {
            if (typeof file !== "object") reject("invalid file");
            if (file.type === "image") {
                const img = new Image();

                img.src = file.src;
                img.onload = () => {
                    // console.log("image loaded");
                    resolve();
                };
                img.onerror = (err) => {
                    console.warn(err);
                    reject("img error");
                };
            }

            if (file.type === "lottie") {
                fetch(file.src)
                    .then((response) => response.json())
                    .then((data) => resolve(data))
                    .catch((err) => {
                        console.warn(err);
                        reject("json error");
                    });
            }
        });
    });

    await Promise.all(promises);

    if (onLoad) onLoad("media done loading");
};
