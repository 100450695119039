import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isActive: false,
    url: '',
    title: '',
};

const webviewSlice = createSlice({
    name: 'webview',
    initialState,
    reducers: {
        setWebViewActive: (state, action) => {
            state.isActive = action.payload;
        },
        setWebViewUrl: (state, action) => {
            state.url = action.payload;
        },
        setWebViewTitle: (state, action) => {
            state.title = action.payload;
        },
    },
});

export const { setWebViewActive, setWebViewUrl, setWebViewTitle } = webviewSlice.actions;

export default webviewSlice.reducer;
