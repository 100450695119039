import React, { useCallback, useEffect, useState } from 'react';
import styles from './WebView.module.css';
import IconButton from 'components/IconButton';
import ActivityIndicator from 'components/ActivityIndicator';
import ExternalEventsHandler, { eventsTypes } from 'utils/ExternalEventsHandler';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { setWebViewActive, setWebViewTitle, setWebViewUrl } from './webviewSlice';
import { easing } from 'utils/animationEasing';
import useUrlWithVars from 'utils/useUrlWithVars';

export default function WebView() {
    const dispatch = useDispatch();
    const direction = useSelector((state) => state.survey.display.direction);
    const isActive = useSelector((state) => state.webview.isActive);
    const url = useSelector((state) => state.webview.url);
    const title = useSelector((state) => state.webview.title);

    const getUrlWithVars = useUrlWithVars();

    const [siteLoaded, setSiteLoaded] = useState(false);

    const handleClose = useCallback(() => {
        dispatch(setWebViewActive(false));
        dispatch(setWebViewUrl(''));
        dispatch(setWebViewTitle(null));
        setSiteLoaded(false);
    }, [dispatch]);

    const handleEvents = useCallback(
        (eventData) => {
            if (eventData.type === eventsTypes.CLOSE_WEBVIEW) {
                handleClose();
            }
        },
        [handleClose]
    );

    // Close webiew on back:
    useEffect(() => {
        function historyHandler(event) {
            handleClose();
        }

        window.addEventListener('popstate', historyHandler);

        return () => window.removeEventListener('popstate', historyHandler);
    }, [handleClose]);

    return (
        <>
            {isActive && <ExternalEventsHandler onEvent={handleEvents} />}
            <AnimatePresence>
                {isActive && (
                    <motion.div
                        className={styles.rootWebView}
                        dir={direction}
                        transition={{ duration: 0.3, ease: easing.outQuart }}
                        initial={{ backgroundColor: 'rgba(0,0,0,0)' }}
                        animate={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
                        exit={{ backgroundColor: 'rgba(0,0,0,0)' }}
                    >
                        <div data-theme="dark" className={styles.header}>
                            <motion.div
                                // transition={{ delay: 0.2 }}
                                style={{ width: 46 }}
                                initial={{ opacity: 0, y: 40 }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    transition: { type: 'spring', stiffness: 80, damping: 8, delay: 0.1 },
                                }}
                                exit={{ opacity: 0, y: -40 }}
                            >
                                <IconButton name="x" onClick={handleClose} />
                            </motion.div>
                            <motion.div
                                className={styles.title}
                                transition={{ duration: 0.3, ease: easing.outQuart }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {title}
                            </motion.div>
                        </div>

                        <motion.div
                            className={styles.panel}
                            transition={{ duration: 0.4, ease: easing.outQuart }}
                            initial={{ y: 300, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 300, opacity: 0, transition: { duration: 0.3 } }}
                        >
                            {!siteLoaded && (
                                <div className={styles.loaderCont} aria-busy="true">
                                    <ActivityIndicator size={32} />
                                </div>
                            )}
                            <iframe
                                title="survey_webview"
                                className={styles.iframe}
                                src={getUrlWithVars(url)}
                                allowFullScreen={true}
                                onLoad={() => setSiteLoaded(true)}
                            />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}
