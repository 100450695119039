import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './OpeningPageModule.module.css';
import DesktopContinueButton from 'features/survey/views/desktop/DesktopContinueButton';
import Text from 'components/Text';
import LanguageSelector from 'components/LanguageSelector';
import Media from 'components/media/Media';

import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { pageStateChanged } from 'features/survey/surveySlice';
import { setWebViewActive, setWebViewUrl } from 'features/webview/webviewSlice';
import AccessibiltyOptions from 'components/AccessibiltyOptions';

const textsTransition = {
    hidden: { opacity: 0, scale: 0.8, y: 24 },
    show: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: 1,
            ease: easing.inOutQuart,
        },
    },
};

export default function OpeningPageModule({ data, lang }) {
    const dispatch = useDispatch();
    const platform = useSelector((state) => state.survey.display.layout.platform);

    const mediaSettings = data.settings.media;

    const Content = useMemo(() => {
        switch (platform) {
            case 'mobile':
                return Mobile;
            case 'desktop':
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    // Valid by default:
    useEffect(() => {
        dispatch(pageStateChanged({ property: 'isCurrentPageValid', value: true }));
    }, [dispatch]);

    return (
        <>
            <Content data={data} lang={lang} mediaSettings={mediaSettings} />
        </>
    );
}

const mediaMotionProps = {
    initial: { opacity: 0, y: -200 },
    animate: {
        opacity: 1,
        y: 0,
    },
    transition: {
        duration: 0.5,
        delay: 0.35,
        ease: easing.outQuart,
    },
};

const Mobile = ({ data, mediaSettings, lang }) => {
    return (
        <>
            <div className={styles.topCont}>
                <AccessibiltyOptions />
                <LanguageSelector />
            </div>
            <motion.div
                className={styles.openingContainer}
                style={data.media ? { top: 0 } : {}}
                initial="hidden"
                animate="show"
                transition={{
                    staggerChildren: 0.1,
                }}
            >
                <Media fileId={data.media} settings={mediaSettings} motionProps={mediaMotionProps} />
                <motion.div className={styles.titleCont} variants={textsTransition}>
                    <Text tagType="h2">{data.title[lang]}</Text>
                </motion.div>

                <motion.div className={styles.subtitleCont} variants={textsTransition}>
                    {data.settings.general.showSubtitle && <Text tagType="p">{data.subtitle[lang]}</Text>}
                </motion.div>

                <BottomLinks data={data} lang={lang} />
            </motion.div>
        </>
    );
};

const Desktop = ({ data, mediaSettings, lang }) => {
    const [parent, setParent] = useState();

    // Update 'desktop_root' when window changes layout between mobile & desktop:
    useEffect(() => {
        setParent(document.getElementById('desktop_root'));
    }, []);

    if (!parent) return null;

    // Render this module outside of pageModuleCont, so it's absolute position will take the full width of the window:
    return ReactDOM.createPortal(
        <motion.div
            className={styles.openingContainer}
            initial="hidden"
            animate="show"
            transition={{
                staggerChildren: 0.1,
            }}
        >
            <div className={styles.topCont}>
                <AccessibiltyOptions />
                <LanguageSelector />
            </div>
            {mediaSettings?.width === 'full' && (
                <Media
                    fileId={data.media}
                    settings={mediaSettings}
                    style={{ width: '100%' }}
                    captionStyle={{
                        alignSelf: 'start',
                        marginInlineStart: 'var(--space-24)',
                    }}
                    motionProps={mediaMotionProps}
                />
            )}
            <div className={styles.contentContainer}>
                {mediaSettings?.width === 'content' && (
                    <Media
                        fileId={data.media}
                        settings={mediaSettings}
                        style={{ width: '100%' }}
                        motionProps={mediaMotionProps}
                    />
                )}
                {data.title[lang] && (
                    <motion.div variants={textsTransition}>
                        <Text tagType="h2">{data.title[lang]}</Text>
                    </motion.div>
                )}

                {data.subtitle[lang] && data.settings.general.showSubtitle && (
                    <motion.div variants={textsTransition}>
                        <Text tagType="p">{data.subtitle[lang]}</Text>
                    </motion.div>
                )}
                <BottomLinks data={data} lang={lang} />
                <DesktopContinueButton style={{ justifyContent: 'flex-start' }} />
            </div>
        </motion.div>,
        parent
    );
};

const BottomLinks = ({ data, lang }) => {
    const dispatch = useDispatch();
    const showLinks = useMemo(() => data?.settings?.general?.showTermsLinks, [data]);
    const links = useMemo(() => data?.termsLinks, [data]);

    const handleLinkClick = useCallback(
        (e) => {
            const targetUrl = e.target.dataset.url;
            dispatch(setWebViewUrl(targetUrl));
            dispatch(setWebViewActive(true));
        },
        [dispatch]
    );

    if (!showLinks) return null;

    return (
        <>
            <div className={styles.bottomLinksCont}>
                {links.map((link, i) => (
                    <div key={'link_' + i} style={{ lineHeight: 1 }}>
                        <span className={styles.bottomLinkLabel} data-url={link.url} onClick={handleLinkClick}>
                            {link.text ? link.text[lang] : ''}
                        </span>
                        {i < links.length - 1 && <span className={styles.linkSepatator}>・</span>}
                    </div>
                ))}
            </div>
        </>
    );
};
