import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Slider.module.css';
import gradientStyle from './GradientPanel.module.css';
import debounce from 'debounce';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { easing } from 'utils/animationEasing';
import { mergeRefs } from 'utils/mergeRefs';
import { useArrowKeys } from 'utils/keyboardHooks';

/**
 *
 * @param {{
 *  min
 *  max
 *  labels // { isCustom: true/false, minLabel, maxLabel }
 *  initialValue
 *  onChange
 *  disabled
 * }} params
 * @returns
 */
export default function Slider({ min, max, labels, initialValue, unit, onChange, disabled }) {
    const [value, setValue] = useState(NaN);
    const [emphasize, setEmphasize] = useState(!isNaN(initialValue));

    const numberFormatter = useMemo(() => {
        return new Intl.NumberFormat('he-IL', { maximumFractionDigits: 0 });
    }, []);

    const range = useMemo(() => {
        return { min: parseFloat(min), max: parseFloat(max) };
    }, [min, max]);

    const [minLabel, maxLabel] = useMemo(() => {
        if (labels && labels.isCustom) {
            return [labels.minLabel, labels.maxLabel];
        } else {
            return [numberFormatter.format(min), numberFormatter.format(max)];
        }
    }, [labels, min, max, numberFormatter]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const debouncedSetValue = useMemo(
        () =>
            debounce((value) => {
                onChange(value);
            }, 200),

        [onChange]
    );

    const handleChange = useCallback(
        (value) => {
            const v = range.min + value * (range.max - range.min);
            setValue(v);
            debouncedSetValue(v);
        },
        [debouncedSetValue, range]
    );

    const getNormalizedValue = useCallback(
        (v) => {
            if (!v) return NaN;
            return (v - range.min) / (range.max - range.min);
        },
        [range]
    );

    return (
        <div className={styles.rootCont}>
            <div className={styles.labelsCont}>
                <span className={styles.rangeLabels}>{minLabel}</span>
                <motion.h3
                    animate={emphasize ? { scale: 1.2 } : { scale: 1 }}
                    transition={
                        emphasize ? { type: 'spring', damping: 10, stiffness: 400, delay: 0.4 } : knobTransition
                    }
                    className={styles.valueLabel}
                >
                    {!isNaN(value) ? numberFormatter.format(value) + unit : ''}
                </motion.h3>
                <span className={styles.rangeLabels}>{maxLabel}</span>
            </div>
            <BasicSlider
                min={range.min}
                max={range.max}
                value={getNormalizedValue(value)}
                onChange={handleChange}
                onDratStart={() => setEmphasize(false)}
                onDragEnd={() => setEmphasize(true)}
                disabled={disabled}
            />
        </div>
    );
}

const knobTransition = { type: 'spring', damping: 40, stiffness: 1000 };

const BasicSlider = React.forwardRef(
    ({ size = 48, min, max, value = NaN, onChange, onDratStart, onDragEnd, disabled }, ref) => {
        const barBgRef = useRef();
        const knobRef = useRef();

        const direction = useSelector((state) => state.survey.display.direction);
        const palette = useSelector((state) => state.survey.display.palette);

        const [isDragging, setIsDragging] = useState(false);
        const [rangeWidth, setRangeWidth] = useState(0);

        const updateValueByKeyboard = useCallback(
            (d) => {
                if (onChange) {
                    barBgRef.current?.focus();
                    const newValue = Math.min(1, Math.max(0, (isNaN(value) ? 0 : value) + d));
                    onChange(newValue);
                }
            },
            [value, onChange]
        );

        useArrowKeys({
            leftCB: () => {
                updateValueByKeyboard(direction === 'ltr' ? -0.05 : 0.05);
            },
            rightCB: () => {
                updateValueByKeyboard(direction === 'ltr' ? 0.05 : -0.05);
            },
        });

        const calculateValue = useCallback(
            (pointerX) => {
                // subtract knob size from calculation:
                const x = pointerX - size * 0.5;
                const w = rangeWidth - size;

                if (direction === 'ltr') {
                    if (x <= 0) {
                        onChange(0);
                    } else if (x >= w) {
                        onChange(1);
                    } else {
                        onChange(x / w);
                    }
                } else {
                    if (x <= 0) {
                        onChange(1);
                    } else if (x >= w) {
                        onChange(0);
                    } else {
                        onChange(1 - x / w);
                    }
                }
            },
            [size, rangeWidth, direction, onChange]
        );

        // Update slider width on resize: (in case it has a flexible css width and the window is being resized);
        useEffect(() => {
            setRangeWidth(barBgRef.current.clientWidth);

            function handleResize() {
                setRangeWidth(barBgRef.current.clientWidth);
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [barBgRef]);

        // Handle dragging of knob even if poiner is outside of boundries:
        useEffect(() => {
            function handlePointerMove(e) {
                if (isDragging) {
                    const offsetLeft = barBgRef.current.getClientRects()[0].left;
                    if (e.changedTouches) {
                        calculateValue(e.changedTouches[0].clientX - offsetLeft);
                    } else {
                        calculateValue(e.clientX - offsetLeft);
                    }
                }
            }

            function handlePointerUp(e) {
                setIsDragging(false);
                if (onDragEnd) onDragEnd();
            }

            if (!disabled) {
                window.addEventListener('pointermove', handlePointerMove);
                window.addEventListener('touchmove', handlePointerMove);
                window.addEventListener('pointerup', handlePointerUp);
                window.addEventListener('touchend', handlePointerUp);
            }

            return () => {
                window.removeEventListener('pointermove', handlePointerMove);
                window.removeEventListener('touchmove', handlePointerMove);
                window.removeEventListener('pointerup', handlePointerUp);
                window.removeEventListener('touchend', handlePointerUp);
            };
        }, [isDragging, barBgRef, calculateValue, onDragEnd, disabled]);

        const handlePointerDown = useCallback(
            (e) => {
                if (!disabled) {
                    setIsDragging(true);
                    if (onDratStart) onDratStart();
                    const offsetLeft = e.target.getClientRects()[0].left;
                    calculateValue(e.clientX - offsetLeft);
                }
            },
            [calculateValue, onDratStart, disabled]
        );

        const getKnobPosition = useCallback(() => {
            const w = rangeWidth - size;

            if (direction === 'ltr') {
                return Math.max(0, value * w);
            } else {
                return Math.max(-w, -(value * w));
            }
        }, [value, rangeWidth, size, direction]);

        const getFillWidth = useCallback(() => {
            return (rangeWidth - size) * value + 0.5 * size;
        }, [value, rangeWidth, size]);

        return (
            <div
                ref={mergeRefs([barBgRef, ref])}
                role="slider"
                aria-orientation="horizontal"
                aria-valuemin={min}
                aria-valuenow={min + value * (max - min)} // For accessibiilty, calculate the final value (although BasicSlider only deals with 0-1)
                aria-valuemax={max}
                tabIndex={0}
                className={styles.barBackground}
                style={{
                    height: size,
                    cursor: disabled ? 'not-allowed' : 'grab',
                    opacity: disabled ? 0.75 : 1,
                }}
                onPointerDown={handlePointerDown}
                data-palette={palette}
            >
                {!isNaN(value) && (
                    <>
                        <motion.div
                            className={`${gradientStyle.panel} ${styles.fill}`}
                            initial={{ width: 0 }}
                            animate={{ width: getFillWidth() }}
                            transition={knobTransition}
                        />
                        <motion.div
                            ref={knobRef}
                            className={styles.knob}
                            animate={{
                                x: getKnobPosition(),
                                scale: isDragging ? 1.2 : 1,
                            }}
                            transition={{
                                x: knobTransition,
                                scale: { duration: 0.3, ease: easing.outQuart },
                            }}
                            style={{
                                width: size,
                                height: size,
                                borderRadius: size * 0.5,
                            }}
                        />
                    </>
                )}
            </div>
        );
    }
);
