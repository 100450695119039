import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './ProgressBar.module.css';
import gradientStyles from './GradientPanel.module.css';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import { accessibilityTexts } from 'utils/appTexts';

export default function ProgressBar({ className, height = 6, style }) {
    const contRef = useRef();

    const lightTheme = useSelector((state) => state.survey.display.theme);
    const palette = useSelector((state) => state.survey.display.palette);
    const lang = useSelector((state) => state.user.lang);

    const progress = useProgress();

    const getWidth = useCallback(() => {
        if (contRef.current) {
            return progress * contRef.current.clientWidth;
        }
        return 0;
    }, [progress, contRef]);

    return (
        <div
            ref={contRef}
            role="progressbar"
            aria-valuetext={accessibilityTexts.progressbar.description[lang]}
            aria-valuenow={progress * 100}
            className={`${styles.barCont} ${className}`}
            data-theme={lightTheme}
            style={{
                height,
                ...style,
            }}
        >
            <motion.div
                data-palette={palette}
                className={`${lightTheme === 'dark' ? gradientStyles.panel : ''} ${styles.fill}`}
                initial={{ width: 0 }}
                animate={{ width: getWidth() }}
                transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
            />
        </div>
    );
}

export const useProgress = () => {
    const pagesOrder = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesOrder'));
    const pagesData = useSelector((state) => getNested(state, 'survey', 'data', 'content', 'pagesData'));
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);
    const [lastProgress, setLastProgress] = useState(0);

    useEffect(() => setLastProgress(0), [pagesData]); // reset last progress on data change (neccessary when editing)

    const progress = useMemo(() => {
        if (!pagesData) return 0;

        let p = 0;

        const steps = pagesOrder.length;
        const page = pagesData[currentPage];

        if (page && page.parentBlock) {
            const blockIndex = pagesOrder.indexOf(page.parentBlock);
            p += (blockIndex + 1) / steps;

            const blockPages = pagesData[page.parentBlock].pages;
            const blockSteps = blockPages.length;
            const pageIndex = blockPages.indexOf(currentPage);
            p += ((1 / steps) * pageIndex) / blockSteps;
        } else {
            const pageIndex = pagesOrder.indexOf(currentPage);
            p += (pageIndex + 1) / steps;
        }

        p = Math.max(lastProgress, p);
        setLastProgress(p);

        return p;
    }, [pagesOrder, pagesData, currentPage, lastProgress]);

    return progress;
};
