import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./EndingPageModule.module.css";
import gradient from "components/GradientPanel.module.css";
import Text from "components/Text";
import Media from "components/media/Media";
import TextButton from "components/TextButton";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { easing } from "utils/animationEasing";
import { useConfetti } from "components/Confetti";
import { pageStateChanged, surveyModes } from "features/survey/surveySlice";
import useUrlWithVars from "utils/useUrlWithVars";

const textsTransition = {
    hidden: { opacity: 0, scale: 0.8, y: 24 },
    show: ({ delay }) => ({
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: 1,
            ease: easing.inOutQuart,
            delay,
        },
    }),
};

export default function EndingPageModule({ data }) {
    const lang = useSelector((state) => state.user.lang);
    const palette = useSelector((state) => state.survey.display.palette);
    const platform = useSelector(
        (state) => state.survey.display.layout.platform
    );
    const dispatch = useDispatch();

    const Content = useMemo(() => {
        switch (platform) {
            case "mobile":
                return Mobile;
            case "desktop":
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    const { showSubtitle, showConfetti, showCallToAction } = useMemo(() => {
        if (data) {
            return data.settings.general;
        }

        return {};
    }, [data]);

    const confetti = useConfetti();

    useEffect(() => {
        // Hide footer
        setTimeout(() => {
            dispatch(
                pageStateChanged({
                    property: "isFooterHidden",
                    value: true,
                    source: "EndingPageModule",
                })
            );
        }, 1);
    }, [dispatch]);

    useEffect(() => {
        if (showConfetti) {
            setTimeout(confetti, 1000);
        }
    }, [confetti, showConfetti]);

    return (
        <Content
            lang={lang}
            palette={palette}
            data={data}
            showSubtitle={showSubtitle}
            showCallToAction={showCallToAction}
        />
    );
}

const Mobile = ({ data, lang, palette, showSubtitle, showCallToAction }) => {
    const direction = useSelector((state) => state.survey.display.direction);
    const action = useCallToAction(data.callToAction);
    const mediaSettings = data.settings.media;

    return (
        <>
            <motion.div
                className={`${styles.gradientBg} ${gradient.panel}`}
                data-palette={palette}
                style={{ originY: 0 }}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                transition={{
                    duration: 0.5,
                    ease: easing.inOutQuart,
                    delay: 0,
                }}
            />
            <div className={styles.endingContainer}>
                <Media
                    fileId={data.media}
                    settings={mediaSettings}
                    style={{ originY: 0 }}
                    motionProps={{
                        initial: { opacity: 0, y: -200 },
                        animate: {
                            opacity: 1,
                            y: 0,
                        },
                        transition: {
                            duration: 0.5,
                            delay: 0.35,
                            ease: easing.outQuart,
                        },
                    }}
                />

                <motion.div
                    className={styles.innerCont}
                    initial="hidden"
                    animate="show"
                    transition={{
                        staggerChildren: 0.1,
                    }}
                >
                    {data.title && (
                        <motion.div
                            variants={textsTransition}
                            custom={{ delay: 0.5 }}
                            data-theme="light"
                        >
                            <Text tagType="h1" className={styles.title}>
                                {data.title[lang]}
                            </Text>
                        </motion.div>
                    )}

                    {showSubtitle && data.subtitle && (
                        <motion.div
                            variants={textsTransition}
                            custom={{ delay: 0.6 }}
                            data-theme="light"
                        >
                            <Text tagType="p">{data.subtitle[lang]}</Text>
                        </motion.div>
                    )}

                    {showCallToAction && data.callToAction && (
                        <motion.div
                            className={styles.callToActionCont}
                            variants={textsTransition}
                            custom={{ delay: 0.7 }}
                            data-theme="light"
                        >
                            <TextButton
                                label={data.callToAction.text[lang]}
                                theme="strong"
                                className={styles.callToActionBtn}
                                iconAfter={
                                    direction === "rtl"
                                        ? "chevron_left_arrow"
                                        : "chevron_right_arrow"
                                }
                                onClick={action}
                            />
                        </motion.div>
                    )}
                </motion.div>
            </div>
        </>
    );
};

const Desktop = ({ data, lang, palette, showSubtitle, showCallToAction }) => {
    const [parent, setParent] = useState();

    const direction = useSelector((state) => state.survey.display.direction);
    const action = useCallToAction(data.callToAction);
    const mediaSettings = data.settings.media;

    // Update 'desktop_root' when window changes layout between mobile & desktop:
    useEffect(() => {
        setParent(document.getElementById("desktop_root"));
    }, []);

    if (!parent) return null;

    // Render this module outside of pageModuleCont, so it's absolute position will take the full width of the window:
    return ReactDOM.createPortal(
        <>
            <motion.div
                className={`${styles.gradientBg} ${gradient.panel}`}
                data-palette={palette}
                style={{ originY: 0 }}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                transition={{
                    duration: 0.5,
                    ease: easing.inOutQuart,
                    delay: 0,
                }}
            />
            <div className={styles.endingContainer}>
                {mediaSettings?.width === "full" && (
                    <Media
                        fileId={data.media}
                        settings={mediaSettings}
                        style={{
                            width: "100vw",
                            originY: 0,
                        }}
                        pagePadding="var(--space-64)"
                        captionStyle={{
                            marginInlineStart: "calc( -1 * var(--space-32))",
                            marginBottom: "var(--space-24)",
                        }}
                        motionProps={{
                            initial: { opacity: 0, y: -200 },
                            animate: {
                                opacity: 1,
                                y: 0,
                            },
                            transition: {
                                duration: 0.5,
                                delay: 0.35,
                                ease: easing.outQuart,
                            },
                        }}
                    />
                )}
                <motion.div
                    className={styles.innerCont}
                    style={data.media ? { marginTop: 0 } : {}}
                    initial="hidden"
                    animate="show"
                    transition={{
                        staggerChildren: 0.1,
                    }}
                >
                    {mediaSettings?.width === "content" && (
                        <Media
                            fileId={data.media}
                            settings={mediaSettings}
                            style={{
                                marginInlineEnd: "auto",
                                width: "100%",
                                marginBottom: "var(--space-24)",
                                originY: 0,
                            }}
                            captionStyle={{
                                marginBottom: "var(--space-24)",
                            }}
                            motionProps={{
                                initial: { opacity: 0, y: -200 },
                                animate: {
                                    opacity: 1,
                                    y: 0,
                                },
                                transition: {
                                    duration: 0.5,
                                    delay: 0.35,
                                    ease: easing.outQuart,
                                },
                            }}
                        />
                    )}
                    {data.title && (
                        <motion.div
                            variants={textsTransition}
                            custom={{ delay: 0.5 }}
                            data-theme="light"
                        >
                            <Text className={styles.title} tagType="h1">
                                {data.title[lang]}
                            </Text>
                        </motion.div>
                    )}

                    {showSubtitle && data.subtitle && (
                        <motion.div
                            variants={textsTransition}
                            custom={{ delay: 0.6 }}
                            data-theme="light"
                        >
                            <Text tagType="p">{data.subtitle[lang]}</Text>
                        </motion.div>
                    )}

                    {showCallToAction && data.callToAction && (
                        <motion.div
                            className={styles.callToActionCont}
                            variants={textsTransition}
                            custom={{ delay: 0.7 }}
                            data-theme="light"
                        >
                            <TextButton
                                label={data.callToAction.text[lang]}
                                theme="strong"
                                className={styles.callToActionBtn}
                                iconAfter={
                                    direction === "rtl"
                                        ? "chevron_left_arrow"
                                        : "chevron_right_arrow"
                                }
                                onClick={action}
                            />
                        </motion.div>
                    )}
                </motion.div>
            </div>
        </>,
        parent
    );
};

const useCallToAction = (callToActionData) => {
    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    // const variables = useSelector( state => state.record.variables );

    const getUrl = useUrlWithVars();
    // @TODO: handle various action types

    const cb = useCallback(() => {
        if (callToActionData) {
            if (callToActionData.link) {
                const url = getUrl(callToActionData.link);
                if (surveyMode === surveyModes.EDITING) {
                    window.open(url, "_blank");
                } else {
                    window.location.href = url;
                }
            }
        }
    }, [callToActionData, getUrl, surveyMode /* variables */]);

    return cb;
};
