import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Splash.module.css';
import gradientStyles from 'components/GradientPanel.module.css';

import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { surveyModes } from 'features/survey/surveySlice';

const textsTransition = {
    hidden: { y: 200, rotate: -20 },
    show: ({ delay }) => ({
        y: 0,
        rotate: 0,
        transition: {
            duration: 1.5,
            ease: easing.inOutQuart,
            delay,
        },
    }),
    exit: ({ delay }) => ({
        y: -200,
        rotate: 20,
        transition: {
            duration: 0.6,
            ease: easing.inOutQuart,
            delay,
        },
    }),
};

const logoTransition = {
    hidden: { y: 100 },
    show: ({ delay }) => ({
        y: 0,
        transition: {
            duration: 0.4,
            ease: easing.inOutQuart,
            delay: delay + 0.4,
        },
    }),
    exit: () => ({
        y: -100,
        transition: {
            duration: 0.2,
            ease: easing.inOutQuart,
        },
    }),
};

const bgTransition = {
    hidden: { scaleY: 0 },
    show: {
        scaleY: 1,
        originY: 1,
        transition: {
            originY: { duration: 0 },
            duration: 1,
            ease: easing.inOutQuart,
        },
    },
    exit: {
        scaleY: 0,
        originY: 0,
        transition: {
            originY: { duration: 0 },
            duration: 0.6,
            ease: easing.inOutQuart,
        },
    },
};

export default function Splash({ onComplete }) {
    const lang = useSelector((state) => state.user.lang);
    const data = useSelector((state) => state.survey.data);
    const palette = useSelector((state) => state.survey.display.palette);

    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    const readDebug = useSelector((state) => state.survey.runtimeConfig.readDebug);

    const [completed, setCompleted] = useState(false);

    const [previewSplash, settings] = useMemo(() => {
        if (data) {
            const preview = data.debug.previewSplash && surveyMode === surveyModes.EDITING;

            return [preview, data.settings.general.splash];
        }

        return [null, null];
    }, [data, surveyMode]);

    const wordsArray = useMemo(() => {
        if (settings?.text && settings.text[lang]) {
            return settings.text[lang].split(' ');
        }

        if (data && data.surveyName[lang]) {
            // @TODO: use rich text blocks as separator
            return data.surveyName[lang].split(' ');
        }

        return null;
    }, [settings, data, lang]);

    const useSplash = useMemo(() => {
        return (
            (surveyMode === surveyModes.EDITING && previewSplash) ||
            (settings &&
                settings.use &&
                surveyMode !== surveyModes.EDITING &&
                !(surveyMode === surveyModes.PREVIEW && readDebug))
        );
    }, [settings, surveyMode, readDebug, previewSplash]);

    const logoUrl = useMemo(() => {
        if (settings && settings.logo && settings.logo.length > 0) {
            return settings.logo[0];
        }
    }, [settings]);

    useEffect(() => {
        if (data && !useSplash) onComplete();

        if (surveyMode === surveyModes.EDITING || (surveyMode === surveyModes.PREVIEW && readDebug)) {
            if (!previewSplash) onComplete();
            else setCompleted(false);
        }
    }, [data, useSplash, surveyMode, readDebug, previewSplash, onComplete]);

    const handleComplete = useCallback(
        (definition) => {
            if (definition === 'show' && !previewSplash) {
                setTimeout(() => setCompleted(true), [1000]);
            }

            if (definition === 'exit') {
                onComplete();
            }
        },
        [onComplete, previewSplash]
    );

    if (!data || !data.sourceName) return <></>;

    return (
        <AnimatePresence>
            {useSplash && !completed && (
                <motion.div
                    className={styles.splashContainer}
                    initial="hidden"
                    animate="show"
                    exit="exit"
                    onAnimationComplete={handleComplete}
                    title={data.surveyName[lang]}
                    role="banner"
                >
                    <motion.div
                        data-palette={palette}
                        className={`${gradientStyles.panel} ${styles.background}`}
                        variants={bgTransition}
                    />
                    <div className={styles.innerCont}>
                        {wordsArray &&
                            wordsArray.map((w, i) => (
                                <div key={w} className={styles.headlineWordWrapper}>
                                    <motion.h1
                                        className={styles.headline}
                                        variants={textsTransition}
                                        custom={{ delay: i * 0.03 }}
                                        data-theme="light"
                                    >
                                        {w}
                                    </motion.h1>
                                </div>
                            ))}
                    </div>

                    {logoUrl && (
                        <div className={styles.logosCont}>
                            <motion.img
                                className={styles.logo}
                                src={logoUrl}
                                alt="logo"
                                variants={logoTransition}
                                custom={{
                                    delay: wordsArray ? wordsArray.length * 0.2 : 0,
                                }}
                            />
                        </div>
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
}
