import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getNested } from 'utils/miscHelpers';
import { useConditionSolver } from 'utils/LogicHooks';

/*
Dynamic Text data
------------
SIMPLE:
{
    type: 'simple',
    id: 'variable_id'
}

CONDITIONAL:
{
    type: 'conditional',
    items: [
        { 
            rules: { '==': ['arg_a_id', 'arg_b_id'] },
            args: {
                'arg_a_id': {
                    type: 'surveyVariables',
                    variable: 'var_id'
                },
                'arg_b_id': {
                    type: 'userInput',
                    page: 'page_id',
                    inputType: 'answer' | 'time_span',
                    item: 'item_id' // when page has different answer for each item ( i.e SCALE pages )
                }
            },
            text: 'some text'
        }
    ],
    varToUseAsDefault: 'var_id', 
    defaultText: 'some text'  
}
*/

export default function DynamicText({ data, children }) {
    const variablesData = useSelector((state) => getNested(state, 'survey', 'data', 'logic', 'variables'));
    const variableValues = useSelector((state) => state.record.variables);

    const solver = useConditionSolver();

    const value = useMemo(() => {
        if (data.type === 'simple') {
            if (data.id) {
                const varData = variablesData.find((v) => v.id === data.id);
                if (varData && varData.name) {
                    return variableValues[varData.name];
                }
            }
        }

        if (data.type === 'conditional') {
            if (data.items && data.items.length !== 0) {
                let conditionalText = '';
                for (let i = 0; i < data.items.length; i++) {
                    const item = data.items[i];

                    if (solver({ rules: item.rule, args: item.args })) {
                        conditionalText = item.text;
                        break;
                    }
                }
                if (conditionalText) return conditionalText;
            }

            if (data.varToUseAsDefault) {
                const varData = variablesData.find((v) => v.id === data.varToUseAsDefault);
                if (varData && varData.name) {
                    return variableValues[varData.name];
                }
            }
            if (data.defaultText) return data.defaultText;
        }

        return '_';
    }, [data, variablesData, solver, variableValues]);

    const dir = useMemo(() => (value && !isNaN(value) ? 'auto' : 'unset'), [value]);

    const rendered = useMemo(() => {
        // Replace textual value of the given entity
        // https://stackoverflow.com/questions/41795585/change-the-children-of-a-component-dynamically-in-react/41796862

        function valueReplacement(_children) {
            return React.Children.map(_children, (childNode) => {
                if (typeof childNode === 'string') return value || ''; // cover case: <div>text<div></div></div>
                if (typeof childNode.props.children === 'string') return React.cloneElement(childNode, [], value || '');
                return React.cloneElement(childNode, [], valueReplacement(childNode.props.children));
            });
        }

        return valueReplacement(children);
    }, [children, value]);

    return <span dir={dir}>{rendered}</span>;
}
