import MainView from 'features/survey/views/MainView';
import WebView from 'features/webview/WebView';
import UserSetup from 'features/user/UserSetup';
import SurveyFetcher from 'features/survey/SurveyFetcher';
import UISetup from 'features/survey/UISetup';
import AppActivity from 'features/survey/AppActivity';
import Navigator from 'features/survey/Navigator';
import RecordHandling from 'features/record/RecordHandling';
import ErrorReporter from './ErrorReporter';
import { MotionConfig } from 'framer-motion';

export default function App() {
    return (
        <ErrorReporter>
            <MotionConfig reducedMotion="user">
                <SurveyFetcher />
                <UserSetup />
                <RecordHandling />
                <UISetup />
                <AppActivity />
                <Navigator />
                <MainView />
                <WebView />
            </MotionConfig>
        </ErrorReporter>
    );
}
