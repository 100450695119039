import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Api from 'app/Api';
import { FetchMilgoSurveyData, FetchRefData } from 'app/MilgoApi';
import { getNested } from 'utils/miscHelpers';
import { layoutModes } from 'utils/useLayout';
import { pageTypes } from './surveyConfiguration';

export const surveyModes = {
    EDITING: 'editing', // Used for continuously passing the survey data through an iframe in the editor.
    PREVIEW: 'preview', // Used for previewing the currently edited survey (unpublished changes).
    VIEW_ONLY: 'view_only', // Used for viewing the live version of the survey (published) without saving the answers.
    LIVE: 'live', // Live survey
    DEBUG: 'DEBUG', // Debug
    MILGO_PREVIW: 'milgo-preview', // When working on a survey resource managed by milgo servers
};

export const surveyEnv = {
    HORIZON: 'horizon',
    MILGO: 'milgo',
};

const initialState = {
    runtimeConfig: {
        env: null, // 'horizon' | 'milgo'
        milgoRef: null,
        mode: null,
        readDebug: false, // Used in PREVIEW mode. Is configured by url param 'read_debug=true'
        pagePreviewId: null, // Received via url params. Will cause navigation directly to the given page.
        surveyId: null,
    },

    fetchMilgoRefStatus: 'idle', // 'idle' | 'loading' | 'succeeded'
    milgoRefData: null,

    fetchSurveyStatus: 'idle', // 'idle' | 'loading' | 'succeeded'

    navigation: {
        currentPage: null,
        nextPage: null, // pageId | 'next'
        currentBlock: null, // block id
        blockPageIndex: 0, // Current page index in current block.

        // See documentation about differences between 'history' and 'visitedPages' inside Navigator.js.
        history: [],
        historyIndex: -1,
        visitedPages: [],
    },

    currentPageState: {
        isFooterHidden: false,
        isCurrentPageValid: false,
    },

    display: {
        direction: 'rtl',
        theme: 'light',
        palette: '000',
        layout: {
            width: 360,
            height: 640,
            mode: layoutModes.MOBILE,
            platform: 'desktop' | 'mobile',
        },
    },

    data: null,
};

////////////////////
// Async actions: //
////////////////////

export const fetchSurvey = createAsyncThunk('survey/fetchSurvey', async (args, thunkApi) => {
    const state = thunkApi.getState();
    const config = state.survey.runtimeConfig;
    let response;

    if (config.mode === surveyModes.PREVIEW) {
        response = await Api.FetchSurveyPreview(config.surveyId);
    } else if ([surveyModes.LIVE, surveyModes.VIEW_ONLY].includes(config.mode)) {
        response = await Api.FetchSurvey(config.surveyId);
    } else {
        return thunkApi.rejectWithValue('wrong mode');
    }

    if (response.error) {
        console.warn(`Error while fetching survey in ${config.mode}. Details: ${response.error}`);
        return thunkApi.rejectWithValue(response.error.statusText);
    } else {
        return response.data;
    }
});

export const fetchMilgoRef = createAsyncThunk('survey/fetchMilgoRef', async (args, thunkApi) => {
    const milgoRef = thunkApi.getState()?.survey.runtimeConfig.milgoRef;
    const response = await FetchRefData(milgoRef);

    if (response.error) {
        console.warn(`Error while trying to get ref data. Details: ${response.error}`);
        return thunkApi.rejectWithValue(response.error);
    } else if (response.data.status === 'Error') {
        console.warn(`Error while trying to get ref data. Details: ${response.data.message}`);
        return thunkApi.rejectWithValue(response.data);
    } else {
        return { refKey: milgoRef, ...response.data };
    }
});

const milgoPreviewUrl = process.env.REACT_APP_MILGO_SERVER_URL ?? 'http://localhost:3000';
export const fetchMilgoSurveyData = createAsyncThunk('survey/fetchMilgoSurveyData', async (args, thunkApi) => {
    const state = thunkApi.getState();
    const surveyJsonUrl = thunkApi.getState()?.survey.milgoRefData?.surveyJsonUrl;
    const config = state.survey.runtimeConfig;
    let response;
    if (config.mode === surveyModes.MILGO_PREVIW) {
        response = await FetchMilgoSurveyData(`${milgoPreviewUrl}/preview-resource/${config.surveyId}`);
        console.log(response);
    } else {
        response = await FetchMilgoSurveyData(surveyJsonUrl);
    }

    if (response.error) {
        console.warn(`Error while trying to fetch milgo survey data. Details: ${response.error}`);
        return thunkApi.rejectWithValue(response.error);
    } else {
        return response.data;
    }
});

/////////////////
// SLICE ////////
/////////////////
const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        runtimeConfigChanged: (state, action) => {
            state.runtimeConfig = {
                ...state.runtimeConfig,
                ...action.payload,
            };
        },
        editingSurveyDataReceived: (state, action) => {
            const { surveyJson } = action.payload;

            if (surveyJson) {
                state.data = surveyJson;
            }
        },

        // NAVIGATION //
        setNextPage: (state, action) => {
            const pageId = action.payload;
            state.navigation.nextPage = pageId;
        },
        setCurrentPage: (state, action) => {
            const pageId = action.payload;
            state.navigation.currentPage = pageId;
            state.navigation.nextPage = null;
        },
        setCurrentBlock: (state, action) => {
            const blockId = action.payload;
            state.navigation.currentBlock = blockId;
        },
        setBlockPageIndex: (state, action) => {
            const inx = action.payload;
            state.navigation.blockPageIndex = inx;
        },
        addPageToHistory: (state, action) => {
            const pageId = action.payload;

            const historyIndex = state.navigation.historyIndex + 1;
            state.navigation.history.push(pageId);
            state.navigation.historyIndex = historyIndex;

            window.history.pushState({ historyIndex }, '');
        },
        resetHistory: (state) => {
            state.navigation.history = [];
            state.navigation.historyIndex = -1;
            state.navigation.visitedPages = [];

            window.history.replaceState({}, '');
        },
        setHistoryIndex: (state, action) => {
            const index = action.payload;
            state.navigation.historyIndex = index;
        },
        addToVisitedPages: (state, action) => {
            const pageId = action.payload;
            const visited = Array.from(state.navigation.visitedPages);
            visited.push(pageId);
            state.navigation.visitedPages = visited;
        },

        // CURRENT PAGE STATE //
        pageStateChanged: (state, action) => {
            // console.log(action.payload?.source);
            const { property, value } = action.payload;
            state.currentPageState[property] = value;
        },

        // DISPLAY //
        displayChanged: (state, action) => {
            const { property, value } = action.payload;
            state.display[property] = value;
        },
    },
    extraReducers: {
        [fetchSurvey.pending]: (state, action) => {
            state.fetchSurveyStatus = 'loading';
        },
        [fetchSurvey.fulfilled]: (state, action) => {
            state.fetchSurveyStatus = 'succeeded';

            const data = action.payload;
            // console.log( data );
            if (data) {
                state.data = data;
            }
        },
        [fetchSurvey.rejected]: (state, action) => {
            state.fetchSurveyStatus = 'failed';
        },

        //#region MILGO
        [fetchMilgoRef.pending]: (state, action) => {
            state.fetchMilgoRefStatus = 'loading';
        },
        [fetchMilgoRef.fulfilled]: (state, action) => {
            state.fetchMilgoRefStatus = 'succeeded';

            const data = action.payload;
            if (data) {
                state.milgoRefData = data;
            }
        },
        [fetchMilgoRef.rejected]: (state, action) => {
            state.fetchMilgoRefStatus = 'failed';
            state.milgoRefData = action.payload;
        },

        [fetchMilgoSurveyData.pending]: (state, action) => {
            state.fetchSurveyStatus = 'loading';
        },
        [fetchMilgoSurveyData.fulfilled]: (state, action) => {
            state.fetchSurveyStatus = 'succeeded';

            const data = action.payload;
            // console.log(data);
            if (data) {
                state.data = data;
            }
        },
        [fetchMilgoSurveyData.rejected]: (state, action) => {
            state.fetchSurveyStatus = 'failed';
        },
        //#endregion
    },
});

export const {
    runtimeConfigChanged,
    editingSurveyDataReceived,
    editingSurveyDisplayPrefsRecieved,
    setNextPage,
    setCurrentPage,
    setCurrentBlock,
    setBlockPageIndex,
    addPageToHistory,
    resetHistory,
    setHistoryIndex,
    addToVisitedPages,

    pageStateChanged,
    displayChanged,
} = surveySlice.actions;

export default surveySlice.reducer;

export const selectPageData = (state, id) => {
    const pagesData = getNested(state, 'survey', 'data', 'content', 'pagesData');
    if (pagesData && id) {
        const data = pagesData[id];
        if (data && data.type !== pageTypes.BLOCK.key) {
            return data;
        }
    }
};
