const refUrl = process.env.REACT_APP_REF_URL;
const surveyAnswers = process.env.REACT_APP_MILGO_SURVEY_ANSWERS_URL;

const missingEnvErrors = {
    refUrl: `Missing environment variable 'REACT_APP_REF_URL'`,
    surveyAnswers: `Missing environment variable 'REACT_APP_MILGO_SURVEY_ANSWERS_URL'`,
};

if (!refUrl) console.error(missingEnvErrors.refUrl);
if (!surveyAnswers) console.error(missingEnvErrors.surveyAnswers);

export async function FetchRefData(refKey) {
    const response = { error: null, data: null };

    if (!refUrl) {
        response.error = missingEnvErrors.refUrl;
        return response;
    }

    if (!refKey) {
        response.error = `Missing refKey'`;
        return response;
    }

    await fetch(`${refUrl}/${refKey}`, {
        cache: 'no-store',
    })
        .then((res) => res.json())
        .then((json) => {
            response.data = json;
        })
        .catch((err) => {
            response.error = err;
        });

    return response;
}

export async function FetchMilgoSurveyData(surveyJsonUrl) {
    const response = { error: null, data: null };

    if (!surveyJsonUrl) {
        response.error = `Missing surveyJsonUrl'`;
        return response;
    }

    await fetch(surveyJsonUrl, {
        cache: 'no-store',
    })
        .then((res) => res.json())
        .then((json) => {
            response.data = json;
        })
        .catch((err) => {
            response.error = err;
        });

    return response;
}

export async function OpenMilgoRecord(refKey, clientKey, sourceName, instanceId, recordsTargetVarId) {
    const response = { error: null, recordId: null };

    if (!surveyAnswers) {
        response.error = missingEnvErrors.surveyAnswers;
        return response;
    }

    await fetch(`${surveyAnswers}/open-record`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refKey,
            clientKey,
            surveySourceName: sourceName,
            instanceId,
            recordsTargetVarId,
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.status === 'Error' || !json.recordId) {
                response.error = JSON.stringify(json);
            } else {
                response.recordId = json.recordId;
            }
        })
        .catch((err) => {
            response.error = err;
        });

    return response;
}

export async function ModifyMilgoRecord(clientKey, recordId, data) {
    const response = { error: null, status: null };

    if (!surveyAnswers) {
        response.error = missingEnvErrors.surveyAnswers;
        return response;
    }

    await fetch(`${surveyAnswers}/modify-record`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            clientKey,
            recordId,
            data,
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            response.status = json.status;
        })
        .catch((err) => {
            response.error = err;
        });

    return response;
}

export async function CloseMilgoRecord(clientKey, recordId) {
    const response = { error: null, status: null };

    if (!surveyAnswers) {
        response.error = missingEnvErrors.surveyAnswers;
        return response;
    }

    await fetch(`${surveyAnswers}/close-record`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            clientKey,
            recordId,
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            response.status = json.status;
        })
        .catch((err) => {
            response.error = err;
        });

    return response;
}
