import React, { useEffect, useState } from 'react';
import styles from './MobilePageFooter.module.css';
import TextButton from 'components/TextButton';
import { useSelector } from 'react-redux';
import { pageTexts } from 'utils/appTexts';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useOnPageDone } from 'features/survey/page/Page';

const texts = pageTexts;

const hideContinueBtnVarients = {
    hidden: { y: 80, opacity: 0 },
    inValid: { y: 80, opacity: 1 },
    visible: { y: 0, opacity: 1 },
};

export default function MobilePageFooter() {
    const lang = useSelector((state) => state.user.lang);
    const direction = useSelector((state) => state.survey.display.direction);
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);

    const { continueButtonCentered, continueButtonText } = useSelector((state) => {
        const pageData = state.survey.data?.content.pagesData[currentPage];
        return pageData?.settings.general || {};
    });
    const isHidden = useSelector((state) => state.survey.currentPageState.isFooterHidden);
    const isPageValid = useSelector((state) => state.survey.currentPageState.isCurrentPageValid);

    const onDone = useOnPageDone();

    const [label, setLabel] = useState();

    useEffect(() => {
        // Change button label only when the transition is finished:
        setTimeout(() => {
            setLabel(
                continueButtonText && continueButtonText[lang] ? continueButtonText[lang] : texts.continueBtn[lang]
            );
        }, 400);
    }, [continueButtonText, lang]);

    return (
        <div
            className={styles.footer}
            style={{
                visibility: isHidden ? 'hidden' : 'visible',
                pointerEvents: isHidden || !isPageValid ? 'none' : 'all',
            }}
        >
            <motion.div
                initial="inValid"
                animate={isHidden ? 'hidden' : isPageValid ? 'visible' : 'inValid'}
                transition={{ duration: 0.4, ease: easing.outQuart }}
                exit={{ y: 80, transition: { duration: 0 } }}
                variants={hideContinueBtnVarients}
                className={styles.continueBtnWrapper}
            >
                <TextButton
                    label={label}
                    className={styles.continueBtn}
                    style={continueButtonCentered ? { justifyContent: 'center' } : null}
                    iconAfter={
                        continueButtonCentered ? '' : direction === 'rtl' ? 'chevron_left_arrow' : 'chevron_right_arrow'
                    }
                    disabled={!isPageValid}
                    onClick={onDone}
                />
            </motion.div>
        </div>
    );
}
