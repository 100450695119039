import React, { useEffect, useRef, useState } from 'react';
import styles from './DesktopView.module.css';

import Splash from '../Splash';
import DesktopSidePanel from './DesktopSidePanel';
import Page from 'features/survey/page/Page';
import { useSelector } from 'react-redux';
import { surveyModes } from 'features/survey/surveySlice';
import ActivityIndicator from 'components/ActivityIndicator';
import NotFound from '../NotFound';

export default function DesktopView() {
    const [pageMounted, setPageMounted] = useState(false);
    const fetchSurveyStatus = useSelector((state) => state.survey.fetchSurveyStatus);
    const fetchMilgoRefStatus = useSelector((state) => state.survey.fetchMilgoRefStatus);

    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);

    if (fetchSurveyStatus === 'failed' || fetchMilgoRefStatus === 'failed') {
        return (
            <div className={styles.desktopViewCont} aria-busy="true">
                <div className={styles.centeredView}>
                    <NotFound />
                </div>
            </div>
        );
    }

    if (fetchSurveyStatus !== 'succeeded' && surveyMode !== surveyModes.EDITING) {
        return (
            <div className={styles.desktopViewCont} aria-busy="true">
                <div className={styles.centeredView}>
                    <ActivityIndicator size={32} />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.desktopViewCont}>
            {pageMounted && <Content />}
            <Splash onComplete={() => setPageMounted(true)} />
        </div>
    );
}

const Content = () => {
    const pageModuleContRef = useRef();
    const currentPage = useSelector((state) => state.survey.navigation.currentPage);

    // Scroll to top on page change:
    useEffect(() => {
        if (pageModuleContRef.current) {
            pageModuleContRef.current.scrollTop = 0;
        }
    }, [currentPage, pageModuleContRef]);

    return (
        <fieldset id="desktop_root" className={styles.desktopViewCont}>
            <DesktopSidePanel id={currentPage} />
            <div className={styles.contentCont}>
                <div ref={pageModuleContRef} className={styles.pageModuleCont}>
                    <Page id={currentPage} />
                </div>
                <div className={styles.bottomFade} />
            </div>
        </fieldset>
    );
};
