import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Blank } from 'assets/icons/blank.svg';
import { ReactComponent as ChevronLeftArrow } from 'assets/icons/chevron_left_arrow.svg';
import { ReactComponent as ChevronRightArrow } from 'assets/icons/chevron_right_arrow.svg';
import { ReactComponent as ChevronUpArrow } from 'assets/icons/chevron_up_arrow.svg';
import { ReactComponent as ChevronDownArrow } from 'assets/icons/chevron_down_arrow.svg';
import { ReactComponent as DarkMode } from 'assets/icons/dark_mode.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Language } from 'assets/icons/language.svg';
import { ReactComponent as Caption } from 'assets/icons/caption.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as X } from 'assets/icons/x.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as TurnDeviceVertical } from 'assets/icons/turn_device_vertical.svg';
import { ReactComponent as Pointer } from 'assets/icons/pointer.svg';
import { ReactComponent as Desktop } from 'assets/icons/desktop.svg';
import { ReactComponent as Mobile } from 'assets/icons/mobile.svg';
import { ReactComponent as Accessibility } from 'assets/icons/accessibility.svg';
import { ReactComponent as IncreaseTextSize } from 'assets/icons/increase_text_size.svg';
import { ReactComponent as DecreaseTextSize } from 'assets/icons/decrease_text_size.svg';
import { ReactComponent as Animation } from 'assets/icons/animation.svg';

const icons = {
    blank: { comp: Blank, reflectable: true },
    chevron_left_arrow: { comp: ChevronLeftArrow, reflectable: false },
    chevron_right_arrow: { comp: ChevronRightArrow, reflectable: false },
    chevron_up_arrow: { comp: ChevronUpArrow, reflectable: false },
    chevron_down_arrow: { comp: ChevronDownArrow, reflectable: false },
    dark_mode: { comp: DarkMode, reflectable: true },
    info: { comp: Info, reflectable: false },
    language: { comp: Language, reflectable: true },
    caption: { comp: Caption, reflectable: true },
    warning: { comp: Warning, reflectable: true },
    check: { comp: Check, reflectable: false },
    x: { comp: X, reflectable: true },
    search: { comp: Search, reflectable: false },
    turn_device_vertical: { comp: TurnDeviceVertical, reflectable: false },
    pointer: { comp: Pointer, reflectable: false },
    desktop: { comp: Desktop, reflectable: false },
    mobile: { comp: Mobile, reflectable: false },
    accessibility: { comp: Accessibility, reflectable: false },
    increase_text_size: { comp: IncreaseTextSize, reflectable: false },
    decrease_text_size: { comp: DecreaseTextSize, reflectable: false },
    animation: { comp: Animation, reflectable: true },
};

/**
 * Icon component
 * @param {{
 * name {string} // Icon name
 * style {object} // Custom style
 * color {string} // Hex or global css param
 * size {number} // Icon size in pixels
 * }} props
 */
export default function Icon({ name, style, color = 'var( --color-mono-5 )', size = '1.5rem' }) {
    const direction = useSelector((state) => state.survey.display.direction);
    const isLtr = useMemo(() => direction === 'ltr', [direction]);
    const iconStyle = useMemo(
        () => ({
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
            fill: color,
            transitionProperty: 'opacity, fill, color',
            transitionDuration: '0.25s',
            transitionTimingFunction: 'ease-out',
            pointerEvents: 'none',
            transform: !isLtr && icons[name].reflectable ? 'scaleX( -1 )' : '',
            ...style,
        }),
        [color, name, size, style, isLtr]
    );

    try {
        const IconComp = icons[name].comp;
        return <IconComp style={iconStyle} alt={name} tabIndex={-1} aria-hidden="true" />;
    } catch {
        console.warn(`Icon "${name}" does not exists`);
        return null;
    }
}
