import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { LocalStorage } from "utils/storageManager";
import { setClientKey } from "./userSlice";
import { surveyModes } from "features/survey/surveySlice";

export default function UserSetup() {
    const dispatch = useDispatch();
    const surveyMode = useSelector((state) => state.survey.runtimeConfig.mode);
    const clientKey = useSelector((state) => state.user.clientKey);
    const isAnonymouse = useSelector((state) => {
        const data = state.survey.data;
        if (data) {
            return data.settings?.general?.anonymousFilling;
        }
        return "unknown";
    });

    useEffect(() => {
        if (
            surveyMode === surveyModes.LIVE &&
            isAnonymouse !== "unknown" &&
            !clientKey
        ) {
            if (isAnonymouse) {
                // If survey is set to anonymous filling, provide a one-time client key instead of the nominal key:
                const oneTimeKey = uuidv4();
                dispatch(setClientKey(oneTimeKey));
                return;
            }

            // Set nominal client key:
            const existingKey = LocalStorage.Read(LocalStorage.keys.CLIENT_KEY);
            if (existingKey) {
                dispatch(setClientKey(existingKey));
            } else {
                const newKey = uuidv4();
                dispatch(setClientKey(newKey));
                LocalStorage.Save(LocalStorage.keys.CLIENT_KEY, newKey);
            }
        }
    }, [surveyMode, clientKey, isAnonymouse, dispatch]);

    return null;
}
