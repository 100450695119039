import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './FullscreenTextAreaInput.module.css';
import Modal from './Modal';
import IconButton from './IconButton';
import TextButton from './TextButton';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { useSelector } from 'react-redux';
import { pageTexts } from 'utils/appTexts';
import Text from './Text';

/**
 * Text area component (multiline)
 * @param {{
 * isActive
 * onClose
 * placeholder
 * value
 * onChange
 * isValid
 * onNext
 * disabled
 * }} params
 */
export default function FullscreenTextAreaInput({
    isActive,
    onClose,
    placeholder,
    value,
    onChange,
    onFocusOut,
    isValid,
    onNext,
    disabled,
    title,
}) {
    const lang = useSelector((state) => state.user.lang);
    const inputRef = useRef();
    const direction = useSelector((state) => state.survey.display.direction);
    const [viewportH, setViewportH] = useState('100vh');

    const handleChange = useCallback(
        (e) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange]
    );

    useEffect(() => {
        function resizeHandler(e) {
            setViewportH(e.target.height);
        }

        window.visualViewport.addEventListener('resize', resizeHandler);

        return () => {
            window.visualViewport.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        function focusoutHandler(e) {
            if (onFocusOut) onFocusOut();
        }
        const inputNode = inputRef.current;

        if (inputNode) {
            inputNode.addEventListener('focusout', focusoutHandler);

            return () => {
                inputNode.removeEventListener('focusout', focusoutHandler);
            };
        }
    }, [onFocusOut]);

    return (
        <Modal
            isActive={isActive}
            style={{
                backgroundColor: 'var( --color-mono-1 )',
                height: viewportH,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Text tagType="p" className={styles.fullscreenHeader}>
                {title[lang]}
            </Text>
            <textarea
                ref={inputRef}
                className={styles.textAreaInput}
                placeholder={placeholder}
                value={value || ''}
                onChange={handleChange}
                autoFocus={true}
                dir={direction}
                disabled={disabled}
            />
            <div className={styles.fullscreenFooter}>
                <IconButton name="x" onClick={onClose} />
                <motion.div
                    initial={{ y: 60 }}
                    animate={isValid ? { y: 0 } : { y: 60 }}
                    transition={{ duration: 0.4, ease: easing.outQuart }}
                >
                    <TextButton
                        label={pageTexts.continueBtn[lang]}
                        className={styles.continueBtn}
                        iconAfter={direction === 'rtl' ? 'chevron_left_arrow' : 'chevron_right_arrow'}
                        disabled={!isValid}
                        onClick={onNext}
                    />
                </motion.div>
            </div>
        </Modal>
    );
}
