import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lang: 'he',
    clientKey: null,
};

/////////////////
// SLICE ////////
/////////////////
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setClientKey: (state, action) => {
            const clientKey = action.payload;
            state.clientKey = clientKey;
        },
        languageChanged: (state, action) => {
            const lang = action.payload;
            state.lang = lang;
            document.documentElement.setAttribute('lang', lang);
        },
    },
    extraReducers: {},
});

export const { setClientKey, languageChanged } = userSlice.actions;

export default userSlice.reducer;
