import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { surveyEnv, surveyModes } from 'features/survey/surveySlice';

export const analyticEvents = {
    APP_INIT: 'app_init',
    APP_CRASHED: 'app_crashed',
    NOT_FOUND: 'not_found',
    GOT_MILGO_REF_DATA: 'got_milgo_ref_data',
    SURVEY_LOADED: 'survey_loaded',
    PAGE_ENTERED: 'page_entered', // { pageKey, progress }
    BACK_NAVIGATION: 'back_navigation', // { pageKey, progress }
    SURVEY_ENDED: 'survey_ended',
    APP_LOST_FOCUS: 'app_lost_focus', // { pageKey }
    SURVEY_REVISITED: 'survey_revisited', // { hoursSinceLastEntry }
    USED_ACCESSIBILITY: 'used_accessibility', // { textSize: number, colorMode: 'light'|'dark }
    CHANGED_LANGUAGE: 'changed_language', // {lang}
};

const useAnalytics = () => {
    const sourceName = useSelector((state) => state.survey.data?.sourceName);
    const { env, milgoRef, mode } = useSelector((state) => state.survey.runtimeConfig);
    const { analyticsParams } = useSelector((state) => state.survey.milgoRefData || {});

    const sendAnalyticsEvent = useCallback(
        ({ eventKey, params }) => {
            // Only send events if the survey is live.
            if (mode !== surveyModes.LIVE || env === surveyEnv.HORIZON) return;

            if (!mixpanel.config) {
                const token = process.env.REACT_APP_MIXPANEL_TOKEN;
                if (!token) {
                    console.warn(
                        'Missing environment var "REACT_APP_MIXPANEL_TOKEN". Analytics events won\'t be saved. '
                    );
                    return;
                }
                const debug = process.env.NODE_ENV === 'development';
                mixpanel.init(token, { debug });
                const distinctId = env === surveyEnv.MILGO ? 'milgo-client' : '';

                mixpanel.identify(distinctId);
            }
            const eventParams = {
                env,
                refKey: milgoRef,
                sourceName,
                clientApp: 'survey',
                ...analyticsParams,
                ...params,
            };

            mixpanel.track(eventKey, eventParams);
        },
        [mode, env, milgoRef, sourceName, analyticsParams]
    );
    return sendAnalyticsEvent;
};

export default useAnalytics;
