import React, { useCallback, useRef } from 'react';
import styles from './TextAreaInput.module.css';
import { useSelector } from 'react-redux';

/**
 * Text area component (multiline)
 * @param {{
 * className
 * style
 * rows
 * placeholder
 * value
 * onChange
 * disabled
 * }} params 
 */
export default function TextAreaInput ({
    className,
    style,
    rows,
    placeholder, 
    value, 
    onChange, 
    disabled,
    autoFocus
}) {

    const inputRef = useRef();
    const direction = useSelector( state => state.survey.display.direction );

    const handleChange = useCallback( (e) => {

        if( onChange ) {
            onChange( e.target.value )
        }

    }, [onChange])

    return (
        <textarea
            ref={ inputRef }
            className={ `${styles.textArea} ${className}` }
            style={ style }
            rows={ rows }
            placeholder={ placeholder }
            value={ value || "" }
            onChange={ handleChange }
            autoFocus={ autoFocus }
            dir= {direction} 
            disabled={ disabled }
        />
    );
}