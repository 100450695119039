import { Component } from 'react';
import * as Api from 'app/Api';
import mixpanel from 'mixpanel-browser';
import { analyticEvents } from 'features/record/useAnalytics';

class ErrorReporter extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error, info) {
        return { hasError: true };
    }
    async componentDidCatch(error, info) {
        mixpanel.track(analyticEvents.APP_CRASHED);
        await Api.ReportError(`${error}\n${info.componentStack}`, 'Error');
    }

    render() {
        if (this.state.hasError) return <p> Something went wrong 😞 </p>;
        else return this.props.children;
    }
}

export default ErrorReporter;
