import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './PlatformLimitationMessage.module.css';
import Icon from 'components/Icon';
import { general } from 'utils/appTexts';

/**
 * @param {
 * * limitation: 'desktop_only' | 'mobile_only'
 * } params
 */
const PlatformLimitationMessage = ({ limitation }) => {
    const lang = useSelector((state) => state.user.lang);

    const { icon, text } = useMemo(() => {
        if (limitation === 'desktop_only')
            return { icon: 'desktop', text: general.platformLimitationMessage.desktop_only[lang] };
        if (limitation === 'mobile_only')
            return { icon: 'mobile', text: general.platformLimitationMessage.mobile_only[lang] };
        return { icon: '', text: '' };
    }, [limitation, lang]);

    return (
        <div className={styles.limitationView}>
            <Icon name={icon} size={'3rem'} />
            <p className={styles.message}> {text} </p>
        </div>
    );
};

export default PlatformLimitationMessage;
