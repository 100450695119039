import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './NumberPageModule.module.css';
import Slider from 'components/Slider';
import Caption from 'components/Caption';
import TextButton from 'components/TextButton';
import { TextInput } from 'components/TextInput';
import DesktopContinueButton from 'features/survey/views/desktop/DesktopContinueButton';
import Media from 'components/media/Media';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { pageTexts } from 'utils/appTexts';
import { pageStateChanged, surveyModes } from 'features/survey/surveySlice';
import { useOnPageDone } from '../Page';
import { useHasExitTime } from 'utils/PageHelpersHooks';
import { getRawText } from 'components/RichTextRenderer';

const texts = pageTexts.numberPage;

export default function NumberPageModule({ data, lang, onSetAnswer }) {
    const platform = useSelector((state) => state.survey.display.layout.platform);
    const answers = useSelector((state) => state.record.userAnswers);
    const isLocked = useHasExitTime(data);

    const value = useMemo(() => {
        const current = answers[data.key];
        let v = NaN;

        if (current && (current.value || current.value === 0) && !isNaN(current.value)) {
            v = current.value;
        }

        return v;
    }, [data, answers]);

    const InputComp = useMemo(() => {
        switch (data.inputType) {
            case 'slider':
                return SliderInput;
            case 'keyboard':
                return KeyboardInput;
            default:
                return KeyboardInput;
        }
    }, [data.inputType]);

    const handleChange = useCallback(
        (newValue) => {
            onSetAnswer({ value: newValue });
        },
        [onSetAnswer]
    );

    return (
        <div
            key={data.id}
            className={styles.mainCont}
            style={
                data.media
                    ? {
                          paddingTop: 'var(--space-32)',
                          justifyContent: 'flex-start',
                      }
                    : {}
            }
        >
            <Media
                fileId={data.media}
                settings={data.settings.media}
                style={platform === 'desktop' ? { marginRight: 0, marginLeft: 0 } : {}}
                motionProps={{
                    initial: { opacity: 0 },
                    animate: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.5 },
                    },
                }}
            />
            <motion.div
                style={data.media ? { margin: 'auto 0' } : {}}
                initial={{ y: 80, opacity: 0, scale: 0.8 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    ease: easing.inOutQuart,
                    delay: 0.4,
                }}
            >
                <InputComp
                    platform={platform}
                    lang={lang}
                    data={data}
                    value={value}
                    onChange={handleChange}
                    isLocked={isLocked}
                />
            </motion.div>
        </div>
    );
}

const SliderInput = ({ platform, lang, data, value, onChange, isLocked }) => {
    const dispatch = useDispatch();

    // Handle validation:
    useEffect(() => {
        const valid = !isNaN(value);
        dispatch(pageStateChanged({ property: 'isCurrentPageValid', value: valid }));
    }, [value, dispatch]);

    const unit = useMemo(() => {
        if (data.settings.general.unit) {
            return data.settings.general.unit[lang];
        } else {
            return '';
        }
    }, [data, lang]);

    return (
        <>
            <Slider
                min={data.min}
                max={data.max}
                labels={{
                    isCustom: data.settings.general.useCustomRangeLabels,
                    minLabel: data.minLabel[lang],
                    maxLabel: data.maxLabel[lang],
                }}
                initialValue={value}
                unit={unit}
                onChange={onChange}
                disabled={isLocked}
            />
            <div className={styles.inputBottomCont}>
                {isNaN(value) && <Caption state="click" text={texts.caption.slider[platform][lang]} />}
            </div>
            {platform === 'desktop' && <DesktopContinueButton />}
        </>
    );
};

const KeyboardInput = ({ platform, lang, data, value, onChange, isLocked }) => {
    const dispatch = useDispatch();
    const onDone = useOnPageDone();
    const mode = useSelector((state) => state.survey.runtimeConfig.mode);

    const inputRef = useRef();
    const direction = useSelector((state) => state.survey.display.direction);

    const [isFocused, setIsFocused] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [captionState, setCaptionState] = useState('tip');
    const [captionText, setCaptionText] = useState(texts.caption.tip[lang]);

    // Handle validation:
    useEffect(() => {
        const minChars = parseInt(data.minCharacters);
        const isRequiredLength = minChars === 0 || ((value || value === 0) && value.toFixed(0).length >= minChars);

        if (!isNaN(value) && !isRequiredLength) {
            setCaptionState('warning');
            setCaptionText(texts.caption.minCharactersWarning[lang].replace('{c}', data.minCharacters));
        } else {
            setCaptionState('tip');
            setCaptionText(texts.caption.tip[lang]);
        }

        const valid = isRequiredLength;
        setIsValid(valid);
        dispatch(pageStateChanged({ property: 'isCurrentPageValid', value: valid }));

        if (!isNaN(value) && valid) {
            setCaptionState('success');
            setCaptionText('');
        }
    }, [lang, value, data, dispatch]);

    // useEffect(() => {
    //     // Delaying the focus on the input to let accessibility screen readers read the page title first.
    //     setTimeout(() => {
    //         if (parseInt(data.minCharacters) > 0 && mode !== surveyModes.EDITING) inputRef.current?.focus();
    //     }, 300);
    // }, [data]);

    const handleEnter = useCallback(() => {
        if (isValid) {
            onDone();
        } else {
            inputRef.current?.blur();
        }
    }, [isValid, inputRef, onDone]);

    const handleFocus = useCallback(
        (focused) => {
            setIsFocused(focused);
            dispatch(pageStateChanged({ property: 'isFooterHidden', value: focused }));
        },
        [dispatch]
    );

    return (
        <>
            <TextInput
                ref={inputRef}
                type="number"
                dir="ltr"
                placeholder={data.placeholder[lang]}
                value={value}
                onChange={onChange}
                onEnter={handleEnter}
                onFocus={() => handleFocus(true)}
                onBlur={() => handleFocus(false)}
                disabled={isLocked}
                ariaLabel={getRawText(data.title[lang])}
            />
            <div className={styles.inputBottomCont}>
                <Caption state={captionState} text={captionText} />
                {platform === 'mobile' && (
                    <motion.div
                        initial={{ y: 30, opacity: 0 }}
                        animate={isValid && isFocused ? { y: 0, opacity: 1 } : { y: 30, opacity: 0 }}
                        transition={{ duration: 0.4, ease: easing.outQuart }}
                    >
                        <TextButton
                            label={pageTexts.continueBtn[lang]}
                            className={styles.continueBtn}
                            iconAfter={direction === 'rtl' ? 'chevron_left_arrow' : 'chevron_right_arrow'}
                            disabled={!isValid}
                            onClick={onDone}
                        />
                    </motion.div>
                )}
                {platform === 'desktop' && <DesktopContinueButton style={{ paddingTop: 0 }} />}
            </div>
        </>
    );
};
