export const LocalStorage = {

    keys: {
        USER_LANGUAGE: 'user_language',
        CLIENT_KEY: 'client_key',
        SURVEYS: 'surveys'
    },

    Save: ( key, value ) => {
        try {
            localStorage.setItem( key, JSON.stringify( value ) );
        } catch (error) {
            console.warn( "Cannot access localStorage. Details: " + error );
        }
    },

    Read: ( key ) => {
        try {
            return JSON.parse( localStorage.getItem( key ) );
        } catch (error) {
            console.warn( "Cannot access localStorage. Details: " + error );
            return null;
        }
    },

    Delete: ( key ) => {
        try {
            localStorage.removeItem( key );
        } catch (error) {
            console.warn( "Cannot access localStorage. Details: " + error );
        }
    },

    DeleteAll: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.warn( "Cannot access localStorage. Details: " + error );
        }
    }
}

export const SessionStorage = {

    keys: {
    },

    Save: ( key, value ) => {
        try {
            sessionStorage.setItem( key, JSON.stringify( value ) );
        } catch (error) {
            console.warn( "Cannot access sessionStorage. Details: " + error );
        }
    },

    Read: ( key ) => {
        try {
            return JSON.parse( sessionStorage.getItem( key ) );
        } catch (error) {
            console.warn( "Cannot access sessionStorage. Details: " + error );
            return null;
        }
    },

    Delete: ( key ) => {
        try {
            sessionStorage.removeItem( key );
        } catch (error) {
            console.warn( "Cannot access sessionStorage. Details: " + error );
        }
    },

    DeleteAll: () => {
        try {
            sessionStorage.clear();
        } catch (error) {
            console.warn( "Cannot access sessionStorage. Details: " + error );
        }
    }
}