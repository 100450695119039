import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './Media.module.css';
import { Player } from '@lottiefiles/react-lottie-player';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import IconButton from 'components/IconButton';
import Caption from 'components/Caption';
import { componentsTexts } from 'utils/appTexts';
import FocusTrap from 'focus-trap-react';
import { useEscapeKey } from 'utils/keyboardHooks';

const texts = componentsTexts.media;

export default function Media({
    className,
    style,
    fileId,
    pagePadding = 'var(--space-32)',
    settings,
    motionProps, // framer-motion props i.e {initial: ..., animate: ...}
    captionStyle,
}) {
    const surveyMedia = useSelector((state) => state.survey.data.media);
    const lang = useSelector((state) => state.user.lang);
    const [isFullScreenActive, setIsFullScreenActive] = useState(false);

    const file = useMemo(() => {
        return surveyMedia?.files?.find((f) => f.id === fileId);
    }, [fileId, surveyMedia]);

    const mediaStyle = useMemo(() => {
        if (!settings) return {};

        const isFullWidth = settings.width === 'full';
        const marginTRL = isFullWidth ? `calc( -1 * ${pagePadding}` : 0;

        return {
            marginTop: marginTRL,
            marginRight: marginTRL,
            marginLeft: marginTRL,
            marginBottom: pagePadding,
            height: settings.heightLimit,
            objectPosition: settings.framePositioning,
            cursor: settings.allowZoom ? 'zoom-in' : 'auto',
            pointerEvents: settings.allowZoom ? 'auto' : 'none',
            opacity: isFullScreenActive ? 0 : 1,
            ...style,
        };
    }, [settings, style, isFullScreenActive]);

    const handleImageClick = useCallback(() => {
        setIsFullScreenActive(true);
    }, []);

    if (!file) return null;

    const altText = file.alt ? file.alt[lang] : file.name;

    return (
        <>
            {file.type === 'image' && (
                <>
                    <motion.img
                        {...motionProps}
                        className={`${styles.img} ${className}`}
                        style={mediaStyle}
                        src={file.src}
                        alt={file.alt ? file.alt[lang] : file.name}
                        onClick={handleImageClick}
                    />
                    {settings.allowZoom && (
                        <Caption
                            state={'click'}
                            text={texts.clickToExpand[lang]}
                            style={{
                                marginTop: `calc( -0.5 * ${pagePadding})`,
                                marginBottom: pagePadding,
                                ...captionStyle,
                            }}
                        />
                    )}
                </>
            )}
            {file.type === 'lottie' && (
                <motion.div {...motionProps} role="img" alt={altText} aria-label={altText}>
                    <Player
                        key={'player_' + settings?.lottiePlayMode}
                        className={className}
                        style={mediaStyle}
                        autoplay
                        src={file.src}
                        loop={settings?.lottiePlayMode === 'loop'}
                        keepLastFrame={true}
                    />
                </motion.div>
            )}
            <FullscreenImage
                file={file}
                isActive={isFullScreenActive}
                lang={lang}
                onClose={() => setIsFullScreenActive(false)}
            />
        </>
    );
}

const FullscreenImage = ({ isActive, file, lang, onClose }) => {
    useEscapeKey(() => onClose());
    return ReactDOM.createPortal(
        <AnimatePresence initial={false}>
            {isActive && (
                <FocusTrap>
                    <motion.div
                        className={styles.fullscreenCont}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.img
                            className={styles.fullscreenImage}
                            src={file.src}
                            alt={file.alt ? file.alt[lang] : file.name}
                            drag
                            whileDrag={{ scale: 2 }}
                            dragConstraints={{
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                            dragElastic={0.8}
                            dragMomentum={false}
                            dragSnapToOrigin={true}
                            animate={{ scale: 1 }}
                        />
                        <IconButton name="x" theme="strong" className={styles.closeFullScreenBtn} onClick={onClose} />
                    </motion.div>
                </FocusTrap>
            )}
        </AnimatePresence>,

        document.getElementById('root')
    );
};
