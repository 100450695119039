import React, { useMemo, useState } from 'react';
import styles from './ChoiceItem.module.css';
import Button from './shared/Button';
import AnimatedCheckmark from './shared/AnimatedCheckmark';
import ChoiceItemFill from './shared/ChoiceItemFill';
import { motion } from 'framer-motion';
import Text from './Text';
import { getRawText } from './RichTextRenderer';

const theme = {
    normal: {
        background: 'var( --color-mono-2 )',
        border: 'var( --color-mono-3 )',
        overlay: 'var( --color-mono-5 )',
    },
    hovered: {
        background: 'var( --color-mono-3 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-5 )',
    },
    focused: {
        background: 'var( --color-mono-3 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-5 )',
    },
    active: {
        background: 'var( --color-mono-4 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-1 )',
    },
    disabled: {
        background: 'var( --color-mono-2 )',
        border: 'transparent',
        overlay: 'var( --color-mono-3 )',
    },
    selected: {
        background: 'var( --color-mono-2 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-5 )',
    },
};

/**
 *
 * @param {{
 * text,
 * className,
 * style,
 * disabled,
 * isSelected,
 * onSelect,
 * }} params
 * @returns
 */
const MultipleChoiceItem = React.forwardRef(({ text, className, style, disabled, isSelected, onSelect }, ref) => {
    const [buttonState, setButtonState] = useState('normal'); // 'normal' | 'hovered' | 'focused' | 'active'

    const overlayColor = useMemo(() => {
        if (isSelected) {
            return theme.selected.overlay;
        }

        return theme[buttonState].overlay;
    }, [isSelected, buttonState]);

    const buttonStyle = useMemo(() => {
        return {
            borderColor: isSelected ? theme.selected.border : theme[buttonState].border,
            ...style,
        };
    }, [style, isSelected, buttonState]);

    return (
        <Button
            ref={ref}
            name={getRawText(text)}
            className={`${styles.itemCont} ${className}`}
            style={buttonStyle}
            theme={theme}
            disabled={disabled}
            onStateChange={(s) => setButtonState(s)}
            onClick={onSelect}
        >
            <ChoiceItemFill isActive={isSelected} color={'var( --color-mono-3 )'} />
            <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: isSelected ? [1, 1.2, 1] : 1 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className={styles.checkmarkCont}
                style={{
                    backgroundColor: isSelected ? 'var( --color-mono-5 )' : 'var( --color-mono-1 )',
                }}
            >
                <AnimatedCheckmark
                    isChecked={isSelected}
                    color={isSelected ? 'var( --color-mono-1 )' : 'var( --color-mono-5 )'}
                />
            </motion.div>
            <Text
                className={styles.text}
                style={{
                    color: overlayColor,
                }}
            >
                {text}
            </Text>
        </Button>
    );
});

export default MultipleChoiceItem;
