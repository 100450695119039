/**
 * Get item inside a nested object.
 * @param {object} obj 
 * @param  {...any} args // level1, level2, level3 ...
 * @returns the topmost item, or returns undefined if any item on any level doesn't exists.
 */
export function getNested( obj, ...args ) {
    return args.reduce( (obj, level) => obj && obj[ level ], obj )
}

/**
 * Clamp number
 * @param {number} number 
 * @param {number} min 
 * @param {number} max 
 * @returns a number whose value is limited to the given range.
 */
export function clamp( number, min, max ) {
    return Math.min( Math.max( number, min), max );
}

export function validateEmail( mail ) {
    const regex = new RegExp( /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ );
    if (regex.test(mail))
    {
        return (true)
    }
        return (false)
}

