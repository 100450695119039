import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './TextInput.module.css';
import NumberFormat from 'react-number-format'; // https://www.npmjs.com/package/react-number-format
import { mergeRefs } from 'utils/mergeRefs';

const inputTypesConfig = {
    text: {
        fontSize: 32,
    },
    number: {
        fontSize: 40,
    },
    email: {
        fontSize: 24,
    },
};

/**
 * Keyboard input field
 * @param {
 * type, // 'text' | 'number' | 'multiline'
 * dir, input direction
 * placeholder
 * value
 * className // container class name
 * style,
 * padding // { h: <int>, v: <int> }
 * autoFocus,
 * onChange return value
 * onEnter // on Enter KeyDown
 * onFocus
 * onBlur
 * disabled
 * ariaLabel // for connectin desctiption of this field to a static text
 * } params
 */
export const TextInput = React.forwardRef(
    (
        {
            type = 'text',
            dir = 'auto',
            placeholder,
            value,
            className,
            style,
            padding = { v: 24, h: 32 },
            autoFocus = false,
            onChange,
            onEnter,
            onFocus,
            onBlur,
            disabled,
            ariaLabel,
        },
        ref
    ) => {
        const inputRef = useRef();
        const widthTesterRef = useRef();
        const [formattedValue, setFormattedValue] = useState('');
        const [fontSize, setFontSize] = useState(40);

        const maxFontSize = useMemo(() => {
            const size = inputTypesConfig[type].fontSize;
            setFontSize(size);
            return size;
        }, [type]);

        // Handle text overflow - auto resize:
        useEffect(() => {
            if (widthTesterRef.current && inputRef.current) {
                if (
                    fontSize > 18 &&
                    widthTesterRef.current.clientWidth > inputRef.current.clientWidth - padding.h * 2
                ) {
                    setFontSize(Math.max(18, fontSize - 8));
                } else if (
                    fontSize < maxFontSize &&
                    widthTesterRef.current.clientWidth < inputRef.current.clientWidth - padding.h * 6
                ) {
                    setFontSize(Math.min(maxFontSize, fontSize + 8));
                }
            }
        }, [padding, fontSize, maxFontSize, value, inputRef, widthTesterRef]);

        const handleChange = useCallback(
            (e) => {
                if (onChange) {
                    onChange(e.target.value);
                }
            },
            [onChange]
        );

        const handleNumChange = useCallback(
            (data) => {
                if (onChange) {
                    onChange(data.floatValue);
                    setFormattedValue(data.formattedValue);
                }
            },
            [onChange]
        );

        const handleKeyDown = useCallback(
            (e) => {
                if (e.which === 13 && onEnter) onEnter();
            },
            [onEnter]
        );

        return (
            <div className={`${styles.container} ${className}`}>
                {type === 'number' ? (
                    <NumberFormat
                        getInputRef={mergeRefs([inputRef, ref])}
                        inputMode={'decimal'}
                        className={styles.input}
                        dir={dir}
                        style={{
                            fontSize: fontSize,
                            padding: `${padding.h}px ${padding.v}px`,
                            ...style,
                        }}
                        placeholder={placeholder}
                        value={value}
                        thousandSeparator={true}
                        onValueChange={handleNumChange}
                        onKeyDown={handleKeyDown}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        aria-label={ariaLabel}
                    />
                ) : (
                    <input
                        ref={mergeRefs([inputRef, ref])}
                        className={styles.input}
                        dir={dir}
                        style={{
                            fontSize: fontSize,
                            padding: `${padding.h}px ${padding.v}px`,
                            ...style,
                        }}
                        type={type}
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus={autoFocus}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        disabled={disabled}
                        aria-label={ariaLabel}
                    />
                )}

                <div
                    ref={widthTesterRef}
                    className={styles.widthTester}
                    style={{
                        fontSize: fontSize,
                    }}
                >
                    {type === 'number' && formattedValue}
                    {['text', 'email'].includes(type) && value}
                </div>
            </div>
        );
    }
);
