import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import { useArrowKeys } from 'utils/keyboardHooks';

const variants = {
    enter: ({ direction, reducedMotion }) => {
        return {
            y: reducedMotion ? 0 : direction > 0 ? 500 : -500,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        y: 0,
        opacity: 1,
    },
    exit: ({ direction, reducedMotion }) => {
        return {
            zIndex: 0,
            y: reducedMotion ? 0 : direction < 0 ? 500 : -500,
            opacity: 0,
        };
    },
};

// The less distance a user has swiped, the more velocity they need to register as a swipe.
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

/**
 * Swipable pages mechanism
 * @param {
 *  // axis = y, // @TODO: allow both axes
 *  className, // css position of page must be absolute
 *  style,
 *  numberOfPages,
 *  onChange, // returns the index of target page
 *  children
 * } params
 */
export const Pagination = React.forwardRef(
    (
        {
            // axis = y, // @TODO: allow both axes
            className,
            style,
            numberOfPages,
            onChange,
            children,
        },
        ref
    ) => {
        const [[index, direction], setDirection] = useState([0, 0]);
        const shouldReduceMotion = useReducedMotion();
        useArrowKeys({
            upCB: () => paginate(-1),
            downCB: () => paginate(1),
        });
        useEffect(() => {
            if (onChange) onChange(index);
        }, [index, onChange]);

        const paginate = useCallback(
            (newDirection) => {
                if ((index === 0 && newDirection < 0) || (index >= numberOfPages - 1 && newDirection > 0)) return;

                setDirection([index + newDirection, newDirection]);
            },
            [index, numberOfPages]
        );

        const jumpTo = useCallback(
            (i) => {
                if (i < 0 || i > numberOfPages - 1) return;

                const dir = Math.sign(i - index);
                setDirection([i, dir]);
            },
            [index, numberOfPages]
        );

        // Expose functinality through ref to this component:
        useImperativeHandle(ref, () => ({
            paginate,
            jumpTo,
        }));

        const handleDrageEnd = useCallback(
            (e, { offset, velocity }) => {
                const swipe = swipePower(offset.y, velocity.y);
                if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                }
            },
            [paginate]
        );

        const handleWheel = useCallback(
            (event) => {
                const d = event.deltaY;
                if (Math.abs(d) > 10) {
                    paginate(Math.sign(d));
                }
            },
            [paginate]
        );

        return (
            <>
                <AnimatePresence initial={false} custom={{ direction, reducedMotion: shouldReduceMotion }}>
                    <motion.div
                        key={index}
                        className={className}
                        style={style}
                        custom={{ direction, reducedMotion: shouldReduceMotion }}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            y: { type: 'spring', stiffness: 200, damping: 40 },
                            opacity: { duration: 0.3 },
                        }}
                        drag="y"
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={1}
                        onDragEnd={handleDrageEnd}
                        onWheel={handleWheel}
                    >
                        {React.cloneElement(children, { direction })}
                    </motion.div>
                </AnimatePresence>
            </>
        );
    }
);

export const PaginationBullets = ({
    flexDirection = 'column',
    bulletSize = 4,
    className,
    style,
    index = 0,
    numberOfPages,
    hideWhileIdle = true,
}) => {
    const bullets = useMemo(() => {
        const list = [];
        for (let i = 0; i < numberOfPages; i++) {
            list.push({ isCurrent: i === index });
        }
        return list;
    }, [numberOfPages, index]);

    const [opacity, setOpacity] = useState(hideWhileIdle ? 0 : 1);

    useEffect(() => {
        if (hideWhileIdle) {
            setOpacity(1);

            const dimTimeout = setTimeout(() => setOpacity(0), 1500);
            return () => clearTimeout(dimTimeout);
        }
    }, [hideWhileIdle, index /* dimTimeout */]);

    return (
        <div
            className={className}
            style={{
                display: 'flex',
                flexDirection,
                ...style,
            }}
        >
            {bullets &&
                bullets.map((b, i) => {
                    return (
                        <motion.div
                            key={i}
                            style={{
                                width: bulletSize,
                                height: bulletSize,
                                margin: bulletSize,
                                borderRadius: '50%',
                                backgroundColor: 'var( --color-mono-4)',
                            }}
                            initial={{ scale: 1, opacity: opacity * 0.2 }}
                            animate={{
                                scale: b.isCurrent ? 1.8 : 1,
                                opacity: opacity * (b.isCurrent ? 0.8 : 0.2),
                            }}
                            transition={{ duration: 0.4, ease: 'easeInOut' }}
                        />
                    );
                })}
        </div>
    );
};
