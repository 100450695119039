import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Api from 'app/Api';
import { OpenMilgoRecord, ModifyMilgoRecord, CloseMilgoRecord } from 'app/MilgoApi';
import { surveyEnv } from 'features/survey/surveySlice';

const initialState = {
    urlParams: {},
    variables: {},
    userAnswers: {
        ___changed_inputs: {}, // previous user input of pages where repeated input is allowed
    },

    milgoParams: null,

    recordId: null,
    recordState: 'idle', // 'idle' | 'loading' | 'open' | 'closed' | 'error'
    dataForSubmission: {},

    addToRecordStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    closeRecordStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'

    initiatedAnalytics: false,
};

///////////////////
// ASYNC ACTIONS //
///////////////////

export const openRecord = createAsyncThunk(
    'record/openRecord',
    async ({ milgoRef, clientKey, sourceName, env }, thunkApi) => {
        const milgoParams = thunkApi.getState().record.milgoParams;

        const response =
            env === surveyEnv.MILGO
                ? await OpenMilgoRecord(
                      milgoRef,
                      clientKey,
                      sourceName,
                      milgoParams?.instanceId,
                      milgoParams?.recordsTargetVarId
                  )
                : await Api.OpenRecord(clientKey, sourceName);

        if (response.error) {
            console.warn('Error while trying to open a record. Details: ', response.error);
            return thunkApi.rejectWithValue(response.error);
        } else {
            return response.recordId;
        }
    }
);

export const addToRecord = createAsyncThunk(
    'record/addToRecord',
    async ({ clientKey, recordId, data, env }, thunkApi) => {
        const response =
            env === surveyEnv.MILGO
                ? await ModifyMilgoRecord(clientKey, recordId, data)
                : await Api.AddToRecord(clientKey, recordId, data);

        if (response.error) {
            console.warn('Error while trying to add to a record. Details: ', response.error);
            return thunkApi.rejectWithValue(response.error);
        } else {
            return { clientKey, recordId, data };
        }
    }
);

export const closeRecord = createAsyncThunk('record/closeRecord', async ({ clientKey, recordId, env }, thunkApi) => {
    const response =
        env === surveyEnv.MILGO
            ? await CloseMilgoRecord(clientKey, recordId)
            : await Api.CloseRecord(clientKey, recordId);

    if (response.error) {
        console.warn('Error while trying to close record. Details: ', response.error);
        return thunkApi.rejectWithValue(response.error);
    } else {
        return { clientKey, recordId };
    }
});

/////////////////
// UTILS ////////
/////////////////

// async function debugRemoteRecord( clientKey, recordId ) {
//     const result = await Api.GetRecord( clientKey, recordId );
//     console.log( "remote record:", result.record );
// }

/////////////////
// SLICE ////////
/////////////////
const answersSlice = createSlice({
    name: 'record',
    initialState,
    reducers: {
        resetRecord: () => initialState,

        localRecordRestored: (state, action) => {
            const { urlParams, variables, userAnswers, recordId, recordState } = action.payload;

            state.urlParams = { ...urlParams, ...state.urlParams }; // Chronologically this step happens after extracting the url parameters of the current session. The latest params (state.urlParams) should override the old ones.
            state.variables = variables;
            state.userAnswers = userAnswers;
            state.recordId = recordId;
            state.recordState = recordState;
        },

        receivedUrlParams: (state, action) => {
            const params = action.payload;
            state.urlParams = { ...state.urlParams, ...params };
        },

        setVariable: (state, action) => {
            const { varName, value } = action.payload;
            state.variables[varName] = value;
        },

        setUserAnswer: (state, action) => {
            const { key, ansData } = action.payload;
            state.userAnswers[key] = {
                ...state.userAnswers[key],
                ...ansData,
            };
        },
        clearUserAnswer: (state, action) => {
            const { key } = action.payload;
            state.userAnswers[key] = {};
        },

        setChangedInput: (state, action) => {
            const { key, ansData } = action.payload;
            const existingData = state.userAnswers.___changed_inputs;
            if (!existingData[key]) {
                existingData[key] = [];
            }
            existingData[key].push(ansData);
        },

        addDataForSubmission: (state, action) => {
            const { inputType, key } = action.payload;

            const prefix = inputType === 'urlParams' ? 'param___' : inputType === 'variables' ? 'var___' : '';

            const value = state[inputType][key];
            state.dataForSubmission[prefix + key] = value;
        },

        setMilgoParams: (state, action) => {
            state.milgoParams = action.payload;
        },
    },
    extraReducers: {
        [openRecord.pending]: (state) => {
            state.recordState = 'loading';
        },
        [openRecord.fulfilled]: (state, action) => {
            const recordId = action.payload;
            state.recordState = 'open';
            state.recordId = recordId;
        },
        [openRecord.rejected]: (state) => {
            state.recordState = 'error';
        },

        [addToRecord.pending]: (state) => {
            state.addToRecordStatus = 'loading';
        },
        [addToRecord.fulfilled]: (state, action) => {
            state.addToRecordStatus = 'succeeded';
            const {
                // clientKey,
                // recordId,
                data,
            } = action.payload;

            const newData = { ...state.dataForSubmission };
            Object.entries(data).forEach(([key, itemData]) => {
                if (JSON.stringify(itemData) === JSON.stringify(newData[key])) {
                    // Remove item only if it hasn't changed since we submitted:
                    delete newData[key];
                }
            });

            state.dataForSubmission = newData;

            // debugRemoteRecord( clientKey, recordId );
        },
        [addToRecord.rejected]: (state) => {
            state.addToRecordStatus = 'failed';
        },

        [closeRecord.pending]: (state) => {
            state.closeRecordStatus = 'loading';
        },
        [closeRecord.fulfilled]: (state, action) => {
            state.closeRecordStatus = 'succeeded';
            state.recordState = 'closed';

            // const { clientKey, recordId } = action.payload;
            // debugRemoteRecord( clientKey, recordId );
        },
        [closeRecord.rejected]: (state) => {
            state.closeRecordStatus = 'failed';
        },
    },
});

export const {
    resetRecord,
    localRecordRestored,
    receivedUrlParams,
    setVariable,
    setUserAnswer,
    clearUserAnswer,
    setChangedInput,
    addDataForSubmission,
    setMilgoParams,
} = answersSlice.actions;

export default answersSlice.reducer;
