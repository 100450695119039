import React, { useCallback, useRef, useState } from 'react';
import styles from './SearchBar.module.css';
import Icon from './Icon';
import IconButton from './IconButton';
import { componentsTexts } from 'utils/appTexts';
import { useSelector } from 'react-redux';

const texts = componentsTexts.searchBar;

/**
 * Simple input field with search icon
 * @param {{
 * className
 * style,
 * placeholder,
 * query,
 * onChange
 * }} params
 */
export default function SearchBar({ className, style, placeholder, query, onChange }) {
    const lang = useSelector((state) => state.user.lang);
    const inputRef = useRef();
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = useCallback(
        (e) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange]
    );

    return (
        <div
            className={`${styles.barWrapper} ${className}`}
            style={style}
            onClick={() => {
                if (!isFocused) {
                    inputRef.current.focus();
                }
            }}
            data-focused={isFocused.toString()}
        >
            <Icon name="search" color="var(--color-mono-4)" />
            <input
                ref={inputRef}
                role="search"
                className={styles.searchInput}
                placeholder={placeholder || texts.placeholder[lang]}
                type="text"
                value={query}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            {
                <IconButton
                    name="x"
                    style={{
                        minWidth: 38,
                        // opacity: isFocused ? 1 : 0.1,
                    }}
                    size={'1rem'}
                    onClick={() => {
                        onChange('');
                    }}
                    tabIndex={query ? 0 : -1}
                />
            }
        </div>
    );
}
