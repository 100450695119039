import React, { useCallback, useMemo, useState } from 'react';
import styles from './LanguageSelector.module.css';
import TextButton from 'components/TextButton';
import { useDispatch, useSelector } from 'react-redux';
import { general, accessibilityTexts } from 'utils/appTexts';
import { getNested } from 'utils/miscHelpers';
import { languageChanged } from 'features/user/userSlice';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { LocalStorage } from 'utils/storageManager';
import { useEscapeKey, useRoveFocus } from 'utils/keyboardHooks';
import useAnalytics, { analyticEvents } from 'features/record/useAnalytics';

const texts = general.languages;

export default function LanguageSelector({ className, style }) {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.user.lang);
    const surveyLangs = useSelector((state) => {
        return getNested(state, 'survey', 'data', 'settings', 'general', 'supportedLanguages');
    });

    const [isSelectorActive, setIsSelectorActive] = useState(false);
    const sendAnalytics = useAnalytics();
    useEscapeKey(() => setIsSelectorActive(false));

    const options = useMemo(() => {
        if (!surveyLangs) return [];
        const langs = Array.from(surveyLangs);
        const current = langs.indexOf(lang);
        langs.splice(current, 1);
        return langs;
    }, [surveyLangs, lang]);

    const langBtnRefs = useMemo(() => options.map(() => React.createRef()), [options]);
    useRoveFocus(langBtnRefs);

    const selectLang = useCallback(
        (selected) => {
            dispatch(languageChanged(selected));
            LocalStorage.Save(LocalStorage.keys.USER_LANGUAGE, selected);
            setIsSelectorActive(false);
            sendAnalytics({ eventKey: analyticEvents.CHANGED_LANGUAGE, params: { lang: selected } });
        },
        [dispatch, sendAnalytics]
    );

    if (!surveyLangs || surveyLangs.length < 2) return null;

    return (
        <div className={`${className} ${styles.container}`} style={styles} role="listbox">
            <TextButton
                label={texts[lang]}
                theme="plain"
                ariaLabel={accessibilityTexts.language[lang]}
                className={styles.btn}
                style={{ opacity: 0.75 }}
                iconAfter="language"
                onClick={() => setIsSelectorActive(!isSelectorActive)}
            />

            {isSelectorActive && (
                <motion.div
                    className={styles.optionsCont}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10, transition: { duration: 0.1 } }}
                    transition={{
                        duration: 0.3,
                        ease: easing.inOutQuart,
                    }}
                >
                    {options.map((l, i) => (
                        <TextButton
                            ref={langBtnRefs[i]}
                            key={l}
                            role="option"
                            label={texts[l]}
                            theme="plain"
                            className={styles.btn}
                            iconAfter="blank"
                            style={{
                                // width: '100%',
                                borderRadius: 0,
                            }}
                            onClick={() => selectLang(l)}
                        />
                    ))}
                </motion.div>
            )}
        </div>
    );
}
