import React from "react";
import { motion, useMotionValue } from "framer-motion";
import { easing } from "utils/animationEasing";


const tickVariants = {
  checked: { 
      pathLength: 1, 
      scale: [0.8,1.4,1],
      transition: {duration: 0.5, ease: easing.inOutQuart}
    },
  unchecked: { 
      pathLength: 0, 
      scale: [1.1,0.8,0],
      transition: {duration: 0.3, ease: easing.inOutQuart}
    }
};


export default function AnimatedCheckmark ({
    size = 24, 
    color = 'var( --color-mono-5 )',
    isChecked=false
}) {

  const pathLength = useMotionValue( 0 );
  const scale = useMotionValue( 0 );


  return (
    <motion.svg
        initial={ 'unchecked' }
        animate={ isChecked ? 'checked' : 'unchecked' }
        width={ `${size}` }
        height={ `${size}` }
        viewBox={ `0 0 24 24` }
    >
      <motion.path
        d="M5,12l4.5,4.5L19,7"
        fill="transparent"
        strokeWidth="2"
        stroke={ color }
        variants={tickVariants}
        style={{ 
            pathLength, 
            scale, 
            // originX: 0.4,
            // originY: 1.5
        }}
      />
    </motion.svg>
  );
};
