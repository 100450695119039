import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './DescriptionPageModule.module.css';
import Text from 'components/Text';
import Media from 'components/media/Media';
import DesktopContinueButton from 'features/survey/views/desktop/DesktopContinueButton';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { easing } from 'utils/animationEasing';
import { pageStateChanged } from 'features/survey/surveySlice';

const textsTransition = {
    hidden: { opacity: 0, scale: 0.8, y: 24 },
    show: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: 1,
            ease: easing.inOutQuart,
        },
    },
};

export default function DescriptionPageModule({ data, lang }) {
    const dispatch = useDispatch();
    const platform = useSelector((state) => state.survey.display.layout.platform);

    const mediaSettings = data.settings.media;
    const { titleSize } = useMemo(() => data?.settings?.general || {}, [data]);

    const titleTag = useMemo(() => {
        switch (titleSize) {
            case 'normal':
                return 'h3';
            case 'large':
                return 'h2';
            default:
                return 'h3';
        }
    }, [titleSize]);

    const Content = useMemo(() => {
        switch (platform) {
            case 'mobile':
                return Mobile;
            case 'desktop':
                return Desktop;
            default:
                return Mobile;
        }
    }, [platform]);

    // Valid by default:
    useEffect(() => {
        dispatch(pageStateChanged({ property: 'isCurrentPageValid', value: true }));
    }, [dispatch]);

    return <Content data={data} mediaSettings={mediaSettings} titleTag={titleTag} lang={lang} />;
}

const Mobile = ({ data, mediaSettings, titleTag, lang }) => {
    return (
        <motion.div className={styles.descriptionContainer} initial="hidden" animate="show" variants={textsTransition}>
            <Media fileId={data.media} settings={mediaSettings} />
            <div className={styles.contentContainer}>
                {data.title[lang] && <Text tagType={titleTag}>{data.title[lang]}</Text>}

                {data.subtitle[lang] && <Text tagType="p">{data.subtitle[lang]}</Text>}
            </div>
        </motion.div>
    );
};

const Desktop = ({ data, mediaSettings, titleTag, lang }) => {
    const [parent, setParent] = useState();

    // Update 'desktop_root' when window changes layout between mobile & desktop:
    useEffect(() => {
        setParent(document.getElementById('desktop_root'));
    }, []);

    if (!parent) return null;

    // Render this module outside of pageModuleCont, so it's absolute position will take the full width of the window:
    return ReactDOM.createPortal(
        <motion.div className={styles.descriptionContainer} initial="hidden" animate="show" variants={textsTransition}>
            {mediaSettings?.width === 'full' && (
                <Media
                    fileId={data.media}
                    settings={mediaSettings}
                    style={{ width: '100%' }}
                    captionStyle={{
                        alignSelf: 'start',
                        marginInlineStart: 'var(--space-24)',
                    }}
                />
            )}
            <div className={styles.contentContainer}>
                {mediaSettings?.width === 'content' && (
                    <Media fileId={data.media} settings={mediaSettings} style={{ width: '100%' }} />
                )}
                {data.title[lang] && <Text tagType={titleTag}>{data.title[lang]}</Text>}

                {data.subtitle[lang] && <Text tagType="p">{data.subtitle[lang]}</Text>}
                <DesktopContinueButton style={{ justifyContent: 'flex-start' }} />
            </div>
        </motion.div>,
        parent
    );
};
