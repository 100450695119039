import React, { useMemo, useState } from 'react';
import styles from './ScaleValueButton.module.css';
import Button from './shared/Button';
import ChoiceItemFill from './shared/ChoiceItemFill';

const theme = {
    normal: {
        background: 'var( --color-mono-2 )',
        border: 'var( --color-mono-3 )',
        overlay: 'var( --color-mono-5 )',
    },
    hovered: {
        background: 'var( --color-mono-3 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-5 )',
    },
    focused: {
        background: 'var( --color-mono-3 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-5 )',
    },
    active: {
        background: 'var( --color-mono-4 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-1 )',
    },
    disabled: {
        background: 'var( --color-mono-2 )',
        border: 'transparent',
        overlay: 'var( --color-mono-3 )',
    },
    selected: {
        background: 'var( --color-mono-5 )',
        border: 'var( --color-mono-4 )',
        overlay: 'var( --color-mono-1 )',
    },
};

/**
 *
 * @param {{
 * index,
 * text,
 * className,
 * style,
 * disabled,
 * isSelected,
 * onSelect,
 * }} params
 * @returns
 */
const ScaleValueButton = React.forwardRef(({ text, className, style, disabled, isSelected, onSelect }, ref) => {
    const [buttonState, setButtonState] = useState('normal'); // 'normal' | 'hovered' | 'focused' | 'active'

    const overlayColor = useMemo(() => {
        if (isSelected) {
            return theme.selected.overlay;
        }

        return theme[buttonState].overlay;
    }, [isSelected, buttonState]);

    return (
        <Button
            ref={ref}
            name={text}
            className={`${styles.itemCont} ${className}`}
            style={style}
            theme={theme}
            disabled={disabled}
            onStateChange={(s) => setButtonState(s)}
            onClick={onSelect}
            propagate={true}
        >
            <ChoiceItemFill isActive={isSelected} />
            <label
                className={styles.text}
                style={{
                    color: overlayColor,
                }}
            >
                {text}
            </label>
        </Button>
    );
});

export default ScaleValueButton;
